import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class TelephoneInput extends React.Component{
    render() {
        return (
            <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
                <PhoneInput {...this.props} />
                {this.props.helperText !== '' && <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{this.props.helperText}</p>}
            </div>
        )
    }
}


export default TelephoneInput