// import { pivotalService } from '../services';
import { userConstants } from '@constants/index';
// import { projectsActions } from './projects';
// import { priorityBlocksAction } from './priorityBlocks';
// import { usersActions } from './users';
export const userActions = {
    login,
    updateUserInfo,
    logout
};

function login(user) {
    if (user.first_login) {
        localStorage.setItem('semper_first_login', 1);
    }
    localStorage.setItem('user', JSON.stringify({
        loading: false,
        api_key: user.access_token,
        user: user.user,
        loggedIn: true,
    }));
    return { type: userConstants.LOGIN_SUCCESS, user }
}

function updateUserInfo(user) {
    localStorage.setItem('user', JSON.stringify({
        loading: false,
        api_key: user.api_key,
        user: user.user ,
        loggedIn: false,
    }));
    return { type: userConstants.LOGIN_SUCCESS, user }
}

function logout() {
    localStorage.clear();
    return dispatch => {
        dispatch(endLogout());
    };
    function endLogout(){return { type: userConstants.LOGOUT };}
}