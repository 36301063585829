import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Banner from '@semper/Banner';
import { userActions } from '@actions/index';
import { connect } from "react-redux"
import AppBar from '@material-ui/core/AppBar';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import PersonalInformation from './PersonalInformation';
import DietaryRequirements from './DietaryRequirements';
import Password from './Password';
import Contact from './Contact';
import Addresses from './Addresses';
import PaymentsCards from './PaymentsCards';
import Button from '@material-ui/core/Button';
import Info from '@images/info.svg';
import Premium from './Premium'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import './profile.css'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class Profile extends React.Component{


    state =  {
        disalogIsOpen: false,
        openAboutSemper:false,
        title: '',
        key: '',
        wasLoadedByUrl: false
    }
    logoutAction = () => {
        const { dispatch } = this.props;
        dispatch(userActions.logout())
    }

    componentDidMount() {
        const hash = window.location.hash;
        if (hash) {
            this.setState({
                wasLoadedByUrl: true,
                key:  hash,
                disalogIsOpen: true
            })
        }
    }

    handleCloseDialog = () => {
        window.location.hash = '';
        this.setState({
            disalogIsOpen: false,
            key: ''
        })
    }

    aboutSemper = () => {
        this.setState({
            openAboutSemper: true
        })
    }

    openDialog = (e, key, title) => {
        window.location.hash = key;
        this.setState({
            wasLoadedByUrl:false,
            disalogIsOpen: true,
            title: title,
            key: key
        })
    }
    render() {
        const { disalogIsOpen, title, key, wasLoadedByUrl, transition, openAboutSemper } = this.state;
        const transitionComponent = wasLoadedByUrl ? {} : {TransitionComponent: Transition} ;
        return (
            <>
                <Banner />
                <MenuList className="personal-info-menu">
                    <MenuItem onClick={(e) => this.openDialog(e, '#personal_information')}>Personal Information</MenuItem>
                    <MenuItem onClick={(e) => this.openDialog(e, '#credit_cards')} >Credit cards</MenuItem>
                    {/*<MenuItem onClick={(e) => this.openDialog(e, '#subscription_pricing')}>Subscription</MenuItem>*/}
                    <MenuItem onClick={(e) => this.openDialog(e, '#password')}>Password</MenuItem>
                    <MenuItem onClick={(e) => this.openDialog(e, '#address_book')}>Address book</MenuItem>
                    <MenuItem onClick={(e) => this.openDialog(e, '#dietary_requirements')}>Dietary requirements</MenuItem>
                    <MenuItem onClick={this.logoutAction}>Log out</MenuItem>
                    <MenuItem style={{color: '#797979'}} onClick={(e) => this.props.openWelcome()} >
                        About Semper
                        <img style={{marginLeft: 5}} src={Info} alt=""/>
                    </MenuItem>
                </MenuList>
                <div style={{display: 'flex',justifyContent: "center", marginTop: 20}}>
                    <Button onClick={(e) => this.openDialog(e, '#contact')} className="blackButton">Contact Us</Button>
                </div>
                <Dialog className="profile-dialog" {...transitionComponent} fullScreen open={disalogIsOpen} onClose={this.handleCloseDialog}>
                    <AppBar className="appBarBgWhite">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleCloseDialog} aria-label="close">
                                <KeyboardArrowLeftIcon />
                            </IconButton>
                            <span className="text">{key.substring(1).split('_').join(' ')}</span>
                        </Toolbar>
                    </AppBar>
                    <br/><br/><br/>
                    <div className="mainPadding">
                        {key === '#personal_information' && <PersonalInformation auth={this.props.auth} />}
                        {key === '#dietary_requirements' && <DietaryRequirements firstBooking={false} auth={this.props.auth} />}
                        {/*{key === '#subscription_pricing' && <Premium auth={this.props.auth} />}*/}
                        {key === '#password' && <Password auth={this.props.auth} />}
                        {key === '#contact' && <Contact auth={this.props.auth} />}
                        {key === '#address_book' && <Addresses auth={this.props.auth} />}
                        {key === '#credit_cards' && <PaymentsCards auth={this.props.auth} />}
                    </div>
                </Dialog>

            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.authentication,
    };
}

export default connect(mapStateToProps)(Profile);