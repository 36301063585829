
import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { apiRequest } from '@services/Request';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { helperValidator } from '@helpers/validator';


class AddressForm extends React.Component{
    state = {
        error: '',
        isCompany: false,
        success: '',
        form: {
            firstname: '',
            lastname: '',
            country_id: '',
            zipcode: '',
            city: '',
            street_1: '',
        },
        formValidation: {
            firstname: 'required',
            lastname: 'required',
            zipcode: 'required',
            city: 'required',
            street_1: 'required',
        },
        formErrors:{},
    }

    handleClose = () => {
        this.setState({
            error: '',
            success: ''
        })
    }

    onSave = () => {
        const { api_key } = this.props.auth;
        if (this.props.isNew) {
            let errors = helperValidator.validate(this.state.form, this.state.formValidation);
            this.setState({
                formErrors: {}
            })
            if (Object.keys(errors).length) {
                this.setState({
                    formErrors: errors
                })
                return false;
            }
            apiRequest('user/address-save/0', 'POST', this.state.form, api_key).then(response =>{
                if (response.status === 200) {
                    this.props.updateAddresses();
                    this.setState({
                        success: 'Address has been added',
                        form: {}
                    })
                }
                else if (response.status === 422) {
                    response.json().then(data => {
                        this.setState({
                            error: data.messages.join("\n")
                        })
                    })
                }
            })

        }
    }

    onChange = (e) => {
        var form = { ...this.state.form };
        form[e.target.name] = e.target.value;
        this.setState({
            form
        })
    }

    onChangeCompany = (e) => {
        var form = { ...this.state.form };
        if (!e.target.checked) {
            form['company'] = '';
            form['tax_number'] = '';
        }
        this.setState({
            isCompany: e.target.checked,
            form: form
        })
    }

    render() {
        const { form, error, success, isCompany, formErrors } = this.state;
        const { countries } = this.props;
        return (<>
            <br/>
            <label htmlFor="firstname">Firstname</label>
            <TextField
                className="mt-8 mb-16"
                id="firstname"
                name="firstname"
                onChange={this.onChange}
                value={form.firstname}
                type="text"
                variant="outlined"
                fullWidth
                error={formErrors['firstname'] !== undefined}
                helperText={formErrors['firstname'] !== undefined ? formErrors['firstname'] : ''}
            />
            <label htmlFor="lastname">Lastname</label>
            <TextField
                className="mt-8 mb-16"
                id="lastname"
                name="lastname"
                onChange={this.onChange}
                value={form.lastname}
                type="text"
                variant="outlined"
                fullWidth
                error={formErrors['lastname'] !== undefined}
                helperText={formErrors['lastname'] !== undefined ? formErrors['lastname'] : ''}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isCompany}
                        onChange={this.onChangeCompany}
                        name="is_company"
                    />
                }
                label="It’s a company address ?"
            />

            {isCompany && <>
                <label htmlFor="company">Company</label>
                <TextField
                    className="mt-8 mb-16"
                    id="company"
                    name="company"
                    onChange={this.onChange}
                    value={form.company}
                    type="text"
                    variant="outlined"
                    fullWidth
                />
                <label htmlFor="tax_number">Tax number</label>
                <TextField
                    className="mt-8 mb-16"
                    id="tax_number"
                    name="tax_number"
                    onChange={this.onChange}
                    value={form.tax_number}
                    type="text"
                    variant="outlined"
                    fullWidth
                />
            </>}
            <label htmlFor="street_1">Street line 1</label>
            <TextField
                className="mt-8 mb-16"
                id="street_1"
                name="street_1"
                onChange={this.onChange}
                value={form.street_1}
                type="text"
                variant="outlined"
                fullWidth
                error={formErrors['street_1'] !== undefined}
                helperText={formErrors['street_1'] !== undefined ? formErrors['street_1'] : ''}
            />
            <label htmlFor="street_2">Street line 2</label>
            <TextField
                className="mt-8 mb-16"
                id="street_2"
                name="street_2"
                onChange={this.onChange}
                value={form.street_2}
                type="text"
                variant="outlined"
                fullWidth
            />
            <label htmlFor="zipcode">Zipcode</label>
            <TextField
                className="mt-8 mb-16"
                id="zipcode"
                name="zipcode"
                onChange={this.onChange}
                value={form.zipcode}
                type="text"
                variant="outlined"
                fullWidth
                error={formErrors['zipcode'] !== undefined}
                helperText={formErrors['zipcode'] !== undefined ? formErrors['zipcode'] : ''}
            />
            <label htmlFor="city">City</label>
            <TextField
                className="mt-8 mb-16"
                id="city"
                name="city"
                onChange={this.onChange}
                value={form.city}
                type="text"
                variant="outlined"
                fullWidth
                error={formErrors['city'] !== undefined}
                helperText={formErrors['city'] !== undefined ? formErrors['city'] : ''}
            />
            <label htmlFor="country_id">Country</label>
            <FormControl variant="outlined" style={{width: '100%', marginBottom: 15}}>
                <Select
                    labelId="country_id"
                    id="country_id"
                    value={form.country_id}
                    required
                    name="country_id"
                    onChange={this.onChange}
                    fullWidth
                >
                    {countries.map(country => {
                        return (
                            <MenuItem fullWidth value={country.id}>{country.name}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>

            <br/><br/>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button onClick={this.onSave} variant="contained" className="blackButton">{(this.props.isNew) ? 'Add address' : 'Save'}</Button>
            </div>
            <br/><br/>
            {/*<FormControl variant="outlined" style={{width: '100%', marginBottom: 15}}>*/}
                {/*<InputLabel style={{background:"white", padding: "0 5px", marginLeft: "-5px"}} id="supplier_id">Country</InputLabel>*/}
                {/*<Select*/}
                    {/*labelId="country_id"*/}
                    {/*id="country_id"*/}
                    {/*value={form.country_id}*/}
                    {/*name="country_id"*/}
                    {/*onChange={this.onChange}*/}
                    {/*fullWidth*/}
                {/*>*/}
                    {/*{countires.map(country => {*/}
                        {/*return (*/}
                            {/*<MenuItem value={country.id}>{country.title}</MenuItem>*/}

                        {/*)*/}
                    {/*})}*/}
                {/*</Select>*/}
            {/*</FormControl>*/}
            <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                <Alert severity="error">
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar open={!!success}  autoHideDuration={6000} onClose={this.handleClose}>
                <Alert severity="success">
                    {success}
                </Alert>
            </Snackbar>
        </>)
    }
}

export default AddressForm;