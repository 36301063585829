import React from 'react';
import BlackIcon from '@images/back_arrow_black.svg';
import WhiteIcon from '@images/back_arrow_white.svg';
import { withRouter } from 'react-router-dom';


class Topbar extends React.Component{
    render() {
        return (
            <div className={this.props.className} style={{display: "flex", alignItems: "center", justifyContent: "center", position: 'relative', height: 50, background: (this.props.white)? '#F7F7F7':'#2D3340'}}>
                <img onClick={this.props.history.goBack} style={{position: "absolute", left: 10}} src={(this.props.white) ? BlackIcon: WhiteIcon} />
                <span style={{fontSize: 18, fontWeight: 600, color: (this.props.white)? '#2D3340':'#F7F7F7' }}>{this.props.title}</span>
            </div>
        )
    }
}

export default withRouter(Topbar);