import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SwipeableViews from 'react-swipeable-views';
import Button from '@material-ui/core/Button';
import InvitedBooking from './invitedBooking';
import { apiRequest } from '@services/Request';
import Money from '@images/money.svg'
import RangeTime from '@images/range_time.svg'
import Guest from '@images/guest.svg'
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SupplierCalendar from '@semper/Calendar/SupplierCalendar';
import {Link, withRouter} from 'react-router-dom'
import { formatTime } from '@semper/FormatTime';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import LogoWhite from '@images/logo_white_with_label.svg';
import './supplier.css'
const styles = {};

class Supplier extends React.Component{

    state = {
        openBookingPopup: false,
        sliderIndex: 0,
        selectedOption: 0,
        currency:'CHF',
        selectedVariant: 0,
        selectedVairantObject: {},
        selectedGuests: 0,
        selectedHours: null,
        loading: false,
        openInvite: false,
        error: false,
        hourRange: [],
        originalOptions: [],
        supplier: {
            name: '',
            images: ['data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=', 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs='],
            address: '',
            direct_phone: '',
            booking_conditions: '',
            general_conditions: '',
            city: 1,
            category_id: 1,
            options: [],
        }
    }

    componentDidMount() {
        // this.getSupplierData();
        // window.addEventListener('popstate',  event => {
        //    this.getSupplierData()
        // });
    }

    getSupplierData = () => {
        if (!this.props.loading) { //TODO this work twice
            this.setState({
                loading:true
            })
            apiRequest('anonymous/search-supplier-view', 'POST', {supplier_id: this.props.match.params.id, hash: this.props.match.params.key, date: this.props.match.params.date}).then(responce => {
                if (responce.status === 200) {
                    this.prepareSupplierOption(responce);
                }
                else{
                    this.setState({
                        error: true,
                        loading:false,
                    })
                }
            })
        }
    }



    goToLink = (href) => {
        this.props.history.push(href);
        this.getSupplierData();
        this.setState({
            openCalendar: false
        })
    }

    prepareSupplierOption = (responce) => {
        responce.json().then(data => {
            let newSupplier =  data.data;
            let options = newSupplier.options;
            let originalOptions = [...newSupplier.options];
            let relationOptionNames = {};
            let newOptionArrayByOptions = [];
            options.sort((a, b) => {
                if (a.option_name > b.option_name) return 1;
                if (b.option_name > a.option_name) return -1;
                return 0;
            });
            options.map(option => {
                if (typeof relationOptionNames[option.option_name] == "undefined") {
                    relationOptionNames[option.option_name] = Object.keys(relationOptionNames).length;
                }

                if (typeof newOptionArrayByOptions[relationOptionNames[option.option_name]] == "undefined") {
                    newOptionArrayByOptions[relationOptionNames[option.option_name]] = {name: option.option_name, options: []};
                }
                newOptionArrayByOptions[relationOptionNames[option.option_name]]['options'].push(option);
            });
            newSupplier['options'] = newOptionArrayByOptions;

            this.setState({
                originalOptions: originalOptions,
                supplier: newSupplier,
                currency: data.data.currency.toUpperCase(),
                loading:false,
            })
        })
    }

    invite = () => {
        this.setState({
            openInvite: true,
        })
    }


    inviteForOption = (optionId, variantId, variant) => {
        this.setState({
            selectedVairantObject: variant,
            openInvite: true
        })
    }

    handleCloseInvite = () => {
        this.setState({
            openInvite: false,
            selectedVairantObject: {}
        })
    }

    openBookingPopup = (optionId, selectedVariant, variant) => {
        let hourOptions = [];
        for (let i = variant.start_hour ; i <= variant.end_hour; i += .5) {
            hourOptions.push(i);
        }
        this.setState({
            openBookingPopup: true,
            selectedOption: optionId,
            selectedHours: hourOptions[0],
            hourRange: hourOptions,
            selectedVariant: selectedVariant
        })
    }

    changeVariant = (e) => {
        this.setState({
            selectedVariant: e.target.value,
            selectedGuests: 0
        });
    }

    changeHours = (e) => {
        this.setState({
            selectedHours: e.target.value
        });
    }

    changeNumberGuests = (e) => {
        this.setState({
            selectedGuests: e.target.value
        });
    }
    changeService = (e) => {
        let option = this.state.originalOptions.find(option => {return option.option_id == e.target.value}).options_variants[0];
        let hourOptions = [];
        for (let i = option.start_hour ; i <= option.end_hour; i += .5) {
            hourOptions.push(i);
        }
        this.setState({
            selectedOption: e.target.value,
            selectedGuests: 0,
            selectedHours: hourOptions[0],
            hourRange: hourOptions,
            selectedVariant: option.id
        });
    }

    handleCloseBookingDialog = () => {
        this.setState({
            openBookingPopup: false,
            selectedVariant: 0
        })
    }

    closeCalendar = () => {
        this.setState({
            openCalendar: false
        })
    }
    toHomepage = (e) => {
        if (e.target.value == 20) {
            this.setState({
                openCalendar: true,
                openBookingPopup: false
            })
        }
    }

    changeTopSlide = (index) => {
        this.setState({
            sliderIndex: index
        })
    }

    render() {
        const { supplier, currency, openCalendar, openBookingPopup, selectedOption, selectedVariant, loading, selectedGuests, hourRange, selectedHours, originalOptions, error, sliderIndex } = this.state;
        return <div>Something went wrong</div>
        if (error) {
            return <div className="bg-main-color" style={{height: '100vh'}}>
                <Grid className="fullscreen" justify="center" container alignItems="center" spacing={1}>
                    <Grid item xs={11} spacing={1}>
                        <Grid justify="center" container alignItems="center" spacing={1}>
                            <Grid item xs={12} align="center" spacing={1}>
                                <img src={LogoWhite} alt="Semper"/>
                            </Grid>
                            <Grid style={{textAlign: "center"}} item xs={12} spacing={1}>
                                <b>Sorry, your invention has expired.</b><br/><br/>
                                <span>
                                    <Link to="/register">Create an account</Link> to have access to Semper.
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        }

        if (loading) {
            return <div style={{display: 'flex',alignItems: "center", justifyContent: "center", height: "100%"}}><CircularProgress /></div>
        }
        if (!loading && (!supplier.name || supplier.options.length === 0)) {
            return <div style={{    display: 'flex',justifyContent: 'center', flexWrap: 'wrap'}}><br/><p style={{textAlign: 'center'}}>This supplier not avilable for this date</p><Button onClick={(e) => window.history.back()} className="blackButton">Back</Button></div>
        }
        return(<>
            <SwipeableViews enableMouseEvents onChangeIndex={this.changeTopSlide} axis="x" className="box-shadow-images">
                {supplier.images.map(image => {
                    return (<><img src={'/' + image.path} alt=""/></>)
                })}
            </SwipeableViews>
            {supplier.images.length > 1 && <div className="sliderDots">
                {supplier.images.map((image, index) => {
                    if (index === sliderIndex) {
                        return (<div className="active"></div>)
                    }
                    else return (<div></div>)

                })}
            </div>}
            <div onClick={e => this.props.history.push('/invite_token/' + this.props.match.params.key)} className="back-to-search" style={styles.back}>
                <ArrowBackIosIcon />
            </div>
            <p style={{padding:'0 15px', marginBottom: 0, fontSize: 18, fontWeight: 500}}>{supplier.name}</p>
            <span style={{padding:'0 15px', fontSize: 14, color: '#838788'}}>{supplier.city} ({supplier.country})</span>
            <hr style={{height: '2px',background: '#2D3340',border: 0}} />
            <div className="changeDateWrapper">
                <span>Options and prices for :</span>
                <FormControl variant="outlined">
                    <Select
                        className="selectOnSupplier"
                        onChange={this.toHomepage}
                        value={0}
                    >
                        <MenuItem value={0}>{this.props.match.params.date.replace(/-/g,"/")}</MenuItem>
                        <MenuItem value={20}>Modify</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <SupplierCalendar
                openCalendar={openCalendar}
                auth={this.props.auth}
                goToLink={this.goToLink}
                hash={this.props.match.params.key}
                closeCalendar={this.closeCalendar}
                supplierName={supplier.name}
                supplierId={supplier.supplier_id}
                fromSupplierPage={true}
                isAnonymous={true}
            />
            {!!supplier.options.length && <div style={{padding: '15px 15px 0'}}>
                {/*<p style={{fontSize:22, fontWeight:600}}>Available Services</p>*/}
                {supplier.options.map(generalOption => {
                    return (<><div className="optionBlock">
                        <p className="title">{generalOption.name}</p>
                        {generalOption.options.map(option => {
                            return (option.options_variants.map(variant => {
                                        return (<>
                                            <div className="line">
                                                <span><img src={RangeTime}/><span>{formatTime(variant.start_hour)}<br/>{formatTime(variant.end_hour)}</span></span>
                                                <span><img src={Money}/> <span>{variant.price.replace('.00', '')} {currency} /<br/>guest</span></span>
                                                <span><img src={Guest}/> <span>min<br/>{variant.nb_users_min}&nbsp;guests</span></span>
                                                <span><Button onClick={(e) => this.openBookingPopup(option.option_id, variant.id, variant)} className="blackButton small">Book</Button></span>
                                            </div>
                                        </>)
                                    })
                            )
                        })}


                    </div><br/></>)
                })}
                <div className="extraSupplierInfo">
                    {supplier.booking_conditions.replace(/<[^>]*>?/gm, '') && <>
                        <p className="title">Supplier conditions</p>
                        <div dangerouslySetInnerHTML={{__html: supplier.booking_conditions}}></div>
                    </>}
                    {supplier.general_conditions.replace(/<[^>]*>?/gm, '') && <>
                        <p className="title">Description</p>
                        <div dangerouslySetInnerHTML={{__html: supplier.general_conditions}}></div>
                    </>}
                    {supplier.address.replace(/<[^>]*>?/gm, '') && <>
                        <p className="title">Address</p>
                        <div dangerouslySetInnerHTML={{__html: supplier.address}}></div>
                    </>}
                </div>
                <br/><br/><br/>
                {<InvitedBooking toHomepage={this.toHomepage}
                          {...this.props}
                          currency={currency}
                          date={this.props.match.params.date.replace(/-/g,"/")}
                          booking_conditions={supplier.booking_conditions}
                          changeNumberGuests={this.changeNumberGuests}
                          selectedGuests={selectedGuests}
                          selectedHours={selectedHours}
                          changeHours={this.changeHours}
                          changeVariant={this.changeVariant}
                          selectedVariant={selectedVariant}
                          changeService={this.changeService}
                          options={originalOptions}
                          hourRange={hourRange}
                          selectedOption={selectedOption}
                          supplier={supplier}
                          openBookingPopup={openBookingPopup}
                          handleCloseBookingDialog={this.handleCloseBookingDialog} />}
            </div>}
        </>)
    }
}

export default withRouter(Supplier)
