import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";
import { apiRequest } from '@services/Request';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { userActions } from '@actions/index';
import { getUser } from '@reducers/authentication'
import { helperValidator } from '@helpers/validator';

class Password extends React.Component{


    state = {
        isLoading: false,
        success: '',
        error: '',
        form: {
            old_password: '',
            password: '',
            password_confirmation: '',
        },
        formValidation: {
            old_password: 'min:5|required',
            password: 'min:5|required',
            password_confirmation: 'min:5|required|password_confirmation',
        },
        formErrors:{},
    }

    onChange = (e) => {
        var form = { ...this.state.form };
        form[e.target.name] = e.target.value;
        this.setState({
            form
        })
    }

    handleClose = () => {
        this.setState({
            success: '',
            error: '',
        })
    }

    onSave = () => {
        const { api_key } = this.props.auth;
        const { dispatch } = this.props;

        let errors = helperValidator.validate(this.state.form, this.state.formValidation);
        this.setState({
            formErrors: {}
        })
        if (Object.keys(errors).length) {
            this.setState({
                formErrors: errors
            })
            return false;
        }

        this.setState({
            isLoading: true
        });
        apiRequest('user/update-password', 'POST', this.state.form, api_key).then(request => {
            if (request.status === 200) {
                request.json().then(data => {
                    var auth = {...this.props.auth};
                    auth.user.user.firstname = this.state.form.firstname;
                    auth.user.user.lastname = this.state.form.lastname;
                    auth.user.user.phone_number = this.state.form.phone_number;
                    auth.access_token = auth.api_key;
                    this.setState({
                        success: 'Your password has been updated',
                        isLoading: false
                    });
                })

            }
            else{
                if (request.status === 401) {
                    // this.setState({error: 'Incorrect email or password'})
                }
                else if (request.status === 422) {
                    request.json().then(data => {
                        this.setState({
                            error: data.messages.join("\n")
                        })
                    })
                }
            }

            this.setState({
                isLoading:false
            })

        });
    }

    render() {
        const { form, isLoading, success, error, formErrors } = this.state;
        return(
            <>
                <br/>
                <br/>
                <Grid className="fullscreen" justify="center" container alignItems="center" spacing={1}>
                    <Grid item xs={11} spacing={1}>
                        <Grid justify="center" container alignItems="center" spacing={1}>
                            <Grid item xs={12} spacing={1}>
                                <label htmlFor="old_password">Old password</label>
                                <TextField
                                    name="old_password"
                                    id="old_password"
                                    onChange={this.onChange}
                                    value={form.old_password}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    error={formErrors['old_password'] !== undefined}
                                    helperText={formErrors['old_password'] !== undefined ? formErrors['old_password'] : ''}
                                />
                                <label htmlFor="password">Password</label>
                                <TextField
                                    name="password"
                                    id="password"
                                    onChange={this.onChange}
                                    value={form.password}
                                    variant="outlined"
                                    fullWidth
                                    error={formErrors['password'] !== undefined}
                                    helperText={formErrors['password'] !== undefined ? formErrors['password'] : ''}
                                />
                                <label htmlFor="password_confirmation">Password confirmation</label>
                                <TextField
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    onChange={this.onChange}
                                    value={form.password_confirmation}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    error={formErrors['password_confirmation'] !== undefined}
                                    helperText={formErrors['password_confirmation'] !== undefined ? formErrors['password_confirmation'] : ''}
                                />
                                <br/><br/>
                                <div style={{textAlign: "center"}}><Button className="blackButton" disabled={isLoading} onClick={this.onSave} variant="contained">
                                    {!isLoading && <>Save</>}
                                    {isLoading && <CircularProgress size={29}  />}
                                </Button></div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar open={!!success}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="success">
                        {success}
                    </Alert>
                </Snackbar>
                <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </>
        )
    }
}


function mapStateToProps(state) {
    return {
        user: getUser(state),
    };
}

export default connect(mapStateToProps)(Password);
// export default PersonalInformation;
