import React from 'react';
import './page.css'
import { apiRequest } from '@services/Request';
import CircularProgress from '@material-ui/core/CircularProgress';
import Logo from '@images/logo_white.svg';
import {Link} from "react-router-dom";

class Page extends React.Component{

    state = {
        content: '',
        title: '',
        error: false,
        loading: true
    }
    componentDidMount() {
        let urlKey = window.location.pathname.replace('/page/', '');
        apiRequest('page/' + urlKey, 'GET', null).then(responce => {
            if (responce.status === 200) {
                responce.json().then(data => {
                    if (data.status) {
                        this.setState({
                            content: data.record.content,
                            title: data.record.title,
                            loading: false
                        })
                    }
                    else{
                        this.setState({
                            error: true,
                            loading:false
                        })
                    }

                })
            }
            else{
                this.setState({
                    error: true,
                    loading:false
                })
            }
        })
    }

    redirectToHomepage = () => {
        if (document.body.classList.length >= 1) {
            if (!document.body.classList.contains('need_update_app')) {
                window.location.href = '/'
            }
        }
    }

    render() {
        const { content, title, error, loading } = this.state;
        const disableLink = document.body.classList.contains('need_update_app');
        if (loading) {
            return <div style={{background: 'white', display: 'flex',alignItems: "center", justifyContent: "center", height: "100%"}}><CircularProgress /></div>
        }
        return(
            <>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", position: 'relative', height: 74, background: '#2D3340'}}>
                    <img onClick={this.redirectToHomepage} src={Logo} />
                </div>
                <div style={{minHeight: '100vh',background: 'white', padding: 10}}>

                    {!error && <>
                        <h2>{title}</h2>
                        <div dangerouslySetInnerHTML={{__html: content }}></div>
                    </>}
                    {!!error && <>
                        <h4 style={{textAlign: 'center', paddingTop: 40}}>Page not fund</h4>
                    </>}
                </div>
            </>
        )
    }
}


export default Page;