import { chatConstants } from '@constants/index';

const initialState = {
    counterUnreadMessages: 0,
    loading: true,
    channels: {

    }
}

const calcTotalUnreadMessages = (channels) => {
    let newCountUnreadMessages = 0;
    Object.keys(channels).forEach(hash => {
        newCountUnreadMessages += channels[hash].countUnreadMesages
    })
    return newCountUnreadMessages;
}

export function chat(state = initialState, action) {
    let { channels } = state;
    if (action.hash !== undefined && Object.keys(channels).length !== 0) {
        Object.keys(channels).map(hash => {
            if (hash === action.hash) {
                channels[hash].countUnreadMesages = channels[hash].countUnreadMesages + (action.type === chatConstants.NEW_MESSAGE ? action.count : -action.count);
            }
        });
    }
    else if(action.type === chatConstants.NEW_MESSAGE) {
        return {
            counterUnreadMessages: state.counterUnreadMessages + action.count,
            channels: {},
            loading: false
        }
    }

    switch (action.type) {
        case chatConstants.NEW_MESSAGE:
            return {
                counterUnreadMessages: calcTotalUnreadMessages(channels),
                loading: state.loading,
                channels: {...channels}
            };
        case chatConstants.READ_MESSAGE:
            return {
                channels: {...channels},
                loading: state.loading,
                counterUnreadMessages: calcTotalUnreadMessages(channels)
            };
        case chatConstants.LOAD_TOTAL_COUNT:
            return {
                channels: {...channels},
                loading: state.loading,
                counterUnreadMessages: action.count
            };
        case chatConstants.SET_CHANNELS:
            return {
                loading: false,
                counterUnreadMessages: state.counterUnreadMessages,
                channels: {...action.channels},
            };
        case chatConstants.KITTY_ERROR:
            return {
                loading: false,
                counterUnreadMessages: 0,
                channels: {},
            };
        default:
            return state
    }
}

export function getCountUnreadMessages(state) {
    return state.chat.counterUnreadMessages;
}


export function getChannels(state) {
    return state.chat.channels;
}

export function isChannelLoading(state) {
    return state.chat.loading;
}

