import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    withRouter,
    Link
} from "react-router-dom";

class AuthRedirect extends React.Component{
    componentDidMount() {
        this.props.history.push('/login')
    }
    render() {

        return(
            <p>loading...</p>
        )
    }
}

export default withRouter(AuthRedirect);