import React from 'react';
import './attachments.css';
import CircularProgress from '@material-ui/core/CircularProgress';

class Attachments extends React.Component{

    render() {
        const { attachments } = this.props;
        if (attachments.length === 0) {
            return <div />
        }
        return (
            <div className="chat-attachments">
                {attachments.map(attachment => {
                    return (
                        <div className="attachment">
                            <span onClick={this.props.removeAttachment} className="remove-attachment">x</span>
                            {attachment.type === 'video' && <div>
                                <video src={attachment.preview}></video>
                            </div>}
                            {attachment.type !== 'video' && <div>
                                <img src={'data:image/png;base64,'+attachment.preview} />
                            </div>}
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default Attachments;