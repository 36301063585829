import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";
import { apiRequest } from '@services/Request';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { userActions } from '@actions/index';
import Checkbox from '@material-ui/core/Checkbox';
import { getUser } from '@reducers/authentication'
import FormControlLabel from '@material-ui/core/FormControlLabel';

class DietaryRequirements extends React.Component{
    state = {
        isLoading: true,
        isLoadingForm: false,
        success: '',
        options: [],
        dietary_ids: [],
        dietary_other: "",
        error: '',
    }

    componentDidMount() {
        const { api_key } = this.props.auth;
        apiRequest('user/get-dietary-requirements', 'GET', null, api_key).then(responce => {
            if (responce.status === 200) {
                responce.json().then(data => {
                    console.log(data);
                    this.setState({
                        dietary_ids: data.dietary_ids,
                        dietary_other: data.dietary_other,
                        options: data.dietary_requirements,
                    })
                })
            }
            else{
                this.setState({
                    error: "Something went wrong"
                })
            }
            this.setState({
                isLoading: false
            })
        })
    }

    onChange = (e, optionId) => {
        let { dietary_ids } = this.state;
        if (e.target.checked) {
            dietary_ids.push(optionId);
        }
        else{
            dietary_ids.splice(dietary_ids.findIndex(element => {
                return element === optionId
            }), 1)
        }
        this.setState({
            dietary_ids: dietary_ids
        })
    }

    onSave = () => {
        const { api_key } = this.props.auth;
        const { dietary_ids, dietary_other } = this.state;
        this.setState({
            isLoadingForm: true
        })
        apiRequest('user/update-dietary', 'POST', {
            dietary_ids: dietary_ids,
            dietary_other: dietary_other,
        }, api_key).then(responce => {
            if (responce.status === 200) {
                responce.json().then(data => {
                    this.setState({
                        success: 'Your account has been updated',
                        isLoadingForm: false
                    });
                    if (this.props.firstBooking) {
                        this.props.handleCloseDiet()
                    }
                })

            }
            else{
                if (responce.status === 401) {
                    // this.setState({error: 'Incorrect email or password'})
                }
                else if (responce.status === 422) {
                    responce.json().then(data => {
                        this.setState({
                            error: data.messages.join("\n")
                        })
                    })
                }
            }

            this.setState({
                isLoading:false
            })
        })
    }

    changeOther = (e) => {
        let { dietary_ids } = this.state;
        if (dietary_ids.indexOf(100) >= 0) {
            this.setState({
                dietary_other: e.target.value
            })
        }
    }

    render() {
        const { form, isLoading, success, options, error, dietary_other, dietary_ids, isLoadingForm } = this.state;
        if (isLoading) {
            return <div style={{display: "flex", justifyContent: "center"}}><CircularProgress size={29}  /></div>
        }
        return (
            <div>
                <br/>
                <br/>
                <Grid justify="center" container alignItems="center" spacing={1}>
                    <Grid item xs={11} spacing={1}>
                        <Grid justify="center" container alignItems="center" spacing={1}>
                            <Grid item xs={12} spacing={1}>
                                <b>Any dietary requirements? Please select all that apply below</b>
                                <p style={{fontSize: 13, color:'#797979'}}>We will notify the restaurant of your preferences</p>
                                {options.map(option => {
                                    if (option.name === 'Other') {
                                        return <div style={{display:'flex', alignItems: 'center'}}>
                                            <FormControlLabel
                                                style={{marginBottom: 0}}
                                                control={
                                                    <Checkbox
                                                        checked={(dietary_ids.indexOf(option.id) >= 0)}
                                                        onChange={
                                                            (e) => this.onChange(e, option.id)
                                                        }
                                                    />
                                                }
                                                label={option.name + ":"}
                                            />
                                            <TextField
                                                onChange={this.changeOther}
                                                value={dietary_other}
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </div>
                                    }
                                    return <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(dietary_ids.indexOf(option.id) >= 0)}
                                                    onChange={
                                                        (e) => this.onChange(e, option.id)
                                                    }
                                                />
                                            }
                                            label={option.name}
                                        />
                                    </div>
                                })}

                                <br/>
                                <br/>
                                <div style={{textAlign: "center", minWidth: 200}}><Button className="blackButton" disabled={isLoadingForm} onClick={this.onSave} variant="contained">
                                    {!isLoadingForm && <>Save</>}
                                    {isLoadingForm && <CircularProgress size={29}  />}
                                </Button></div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar open={!!success}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="success">
                        {success}
                    </Alert>
                </Snackbar>
                <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default DietaryRequirements;