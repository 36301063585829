import { chatConstants } from '@constants/index';
import { apiRequest } from '@services/Request'
import { chatHelper } from '@helpers/chat';

export const chatActions = {
    newMessage,
    readMessages,
    setChannels,
    loadTotalCount,
    errorChatKitty,
}

function newMessage(count, hash){
    window.localStorage.setItem('new_chat_message', 1);
    return { type: chatConstants.NEW_MESSAGE, count, hash }
}


function readMessages(count, hash){
    return { type: chatConstants.READ_MESSAGE, count, hash }
}

function setChannels(channels){
    return { type: chatConstants.SET_CHANNELS, channels }
}

function loadTotalCount(count){
    return { type: chatConstants.LOAD_TOTAL_COUNT, count }
}

function errorChatKitty(){
    return { type: chatConstants.KITTY_ERROR }
}