import React from 'react';
import Money from '@images/money.svg'
import Time from '@images/time.svg'
import Guest from '@images/guest.svg'
import Button from '@material-ui/core/Button';
import './styles.css'
import { formatTime } from '@semper/FormatTime';
//supplier/bookings
import { apiRequest } from '@services/Request';
import CircularProgress from '@material-ui/core/CircularProgress';
import { generateBookingConditions } from '@services/Date';
import {
    Link,
} from "react-router-dom";

import TextsmsIcon from '@material-ui/icons/Textsms';
import Chat from '../../chat/index';
import Portal from '@material-ui/core/Portal';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {connect} from "react-redux";
import { chatHelper } from '@helpers/chat'
import {getChannels} from "@reducers/chat";
import { withRouter } from "react-router-dom";
import {isChannelLoading} from "@reducers/chat";
import SuggestNewTime from '../suggestNewTime';
import { chatActions } from '@actions/chat';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconForRejectButton from '@images/back_arrow_white.svg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class Bookings extends React.Component{
    state = {
        bookings: [],
        countNewBookings: '',
        filteredBookings: [],
        currentOption: 1,
        today: [],
        options: [],
        channels: {},
        tomorrow: [],
        isLoading: true,
        newMessages: {},
        bookingInPopupMenu: {},
        openChat: false,
        bookingHash: 0,
        bookingIdForChat: 0,
        openSuggestionPopup: false,
        booking_id: false,
        userIdForChat: 0,
        bookingName: '',
        bookingDate: '',
        chatkitty_channel_id: 0
    }

    constructor(props) {
        super(props)
        this.scrollTarget = React.createRef()
        this.scrollToTargetButton = React.createRef()
    }

    mergeByOption = async (bookings, day) => {
        let results = [];
        bookings.forEach(async (booking,i) => {
            results.push({
                name: booking.name,
                variants: [booking]
            })
        })

        if (day === 'today') {
            this.setState({
                today: results
            })
        }
        else{
            this.setState({
                tomorrow: results
            })
        }
    }


    componentDidMount() {
        const { kitty } = this.props;
        const { newMessages } = this.state;
        this.updateList();
        document.getElementsByClassName('fullscreen')[0].addEventListener('scroll', this.scrollView);
    }

    scrollView = () => {
        if (document.getElementsByClassName('scrollTarget')[0] !== undefined) {
            let scrollTop = document.getElementsByClassName('fullscreen')[0].scrollTop;
            if (
                scrollTop < document.getElementsByClassName('scrollTarget')[0].offsetTop - window.innerHeight
            ) {
                this.scrollToTargetButton.classList.add('show')
                this.scrollToTargetButton.classList.add('to-down')
                this.scrollToTargetButton.classList.remove('to-top')
            }
            else if (scrollTop > document.getElementsByClassName('scrollTarget')[0].offsetTop + document.getElementsByClassName('scrollTarget')[0].offsetHeight) {
                this.scrollToTargetButton.classList.add('show')
                this.scrollToTargetButton.classList.add('to-top')
                this.scrollToTargetButton.classList.remove('to-down')
            }
            else{
                this.scrollToTargetButton.classList.remove('show')
            }
        }
    }


    handleCloseChat = () => {
        this.setState({
            openChat: false,
            bookingIdForChat: 0,
            userIdForChat: 0,
            bookingHash: '',
            bookingName: '',
            chatkitty_channel_id: 0,
            bookingDate: ''
        })
    }

    openChat = (booking) => {
        const { channels, dispatch } = this.props;
        this.setState({
            openChat: true,
            bookingHash: booking.hash,
            bookingIdForChat: booking.id,
            userIdForChat: booking.user.id,
            bookingName: booking.firstname + " " + booking.lastname,
            bookingDate: booking.date,
            chatkitty_channel_id: booking.chatkitty_channel_id
        })

        if (channels[booking.hash] !== undefined) {
            dispatch(chatActions.readMessages(channels[booking.hash].countUnreadMesages, booking.hash));
        }
    }

    updateList = () => {
        this.setState({
            isLoading: true
        })
        const { auth, dispatch, kitty, chatSession } = this.props;
        apiRequest('supplier/bookings', 'POST', null, auth.api_key).then(responce => {
            if (responce.status === 200) {
                responce.json().then( async data => {
                    this.setState({
                        options: data.extra_data.options,
                        bookings: data.bookings
                    })
                    this.filterBookings(this.state.currentOption)
                    if (chatSession.succeeded) {
                        let bookingsForChat = []
                        Object.keys(data.bookings).map(day => {
                            data.bookings[day].map(booking => {
                                bookingsForChat.push(booking)
                            })
                        });
                        chatHelper.prepareChatChannels(auth, dispatch, kitty, bookingsForChat, 'guest');
                    }
                    else{
                        dispatch(chatActions.errorChatKitty());
                    }
                })
            }
            else{
                alert('Something went wrong');
                this.setState({
                    isLoading: false
                })
            }
        })
    }
    reject = (id, skip, newHour) => {
        const { auth } = this.props;
        if (skip !== undefined || window.confirm('Are you sure you want reject this booking?')) {
            apiRequest('supplier/reject-booking', 'POST', {id: id, time: newHour}, auth.api_key).then(responce => {
                if (responce.status === 200) {
                    this.updateList();
                }
                else{
                    alert('Something went wrong')
                }
            })
        }
    }

    aprove = (id) => {
        const { auth } = this.props;
        if (window.confirm('Are you sure you want accept booking?')) {
            apiRequest('supplier/accept-booking', 'POST', {id: id}, auth.api_key).then(responce => {
                if (responce.status === 200) {
                    this.updateList();
                }
                else{
                    alert('Something went wrong')
                }
            })
        }
    }

    suggestNewHours = async (newHour, bookingId) => {
        const { channels } = this.props;
        const { bookingInPopupMenu } = this.state;

        if (channels[bookingInPopupMenu.hash] !== undefined) {
            const resultChatSession = await this.props.kitty.startChatSession({
                channel: channels[bookingInPopupMenu.hash].channel,
            });

            if (resultChatSession.succeeded) {
                const result = await this.props.kitty.sendMessage({
                    channel: channels[bookingInPopupMenu.hash].channel,
                    direction: "right",
                    body: 'Hello! Booking for time ' + bookingInPopupMenu.hour + ' is not possible. But it is possible at ' + newHour + '. Please create a new booking and select this new time. \n Best regards'
                });

                if (result.failed) {
                    const error = result.error; // Handle error
                    console.log(error)
                }
            }
            else{
                console.log(resultChatSession);
            }

        }
        else{
            console.log('Channel doesn\'t exist');
        }

        this.reject(bookingId, true, newHour);
        this.setState({
            openSuggestionPopup: false,
            bookingInPopupMenu: {},
        });
    }

    openSuggestionPopup = () => {
        this.setState({
            openSuggestionPopup: true,
            showRejectedMenu: false
        })
    }

    handleCloseSuggestionPopup = () => {
        this.updateList();
        this.setState({
            openSuggestionPopup: false
        })
    }

    handleCloseRejectedMenu = () => {
        this.setState({
            showRejectedMenu: false,
        })
    }

    filterBookings = (currentOption) => {
        const { bookings } = this.state
        let filteredBookings = {};
        let countNewBookings = 0;
        Object.keys(bookings).map(day => {
            let _filteredBookings = [];
            bookings[day].map(booking => {
                if (
                    !booking.checked_status &&
                    booking.status !== 10 &&
                    booking.status !== 20 &&
                    booking.status !== 40
                ) {
                    countNewBookings++;
                }
                if (currentOption === 0) {
                    if (
                        !booking.checked_status &&
                        booking.status !== 10 &&
                        booking.status !== 20 &&
                        booking.status !== 40
                    ) {
                        _filteredBookings.push(booking)
                    }
                }
                else{
                    _filteredBookings.push(booking)
                }
            })
            if (_filteredBookings.length) {
                filteredBookings[day] = _filteredBookings;
            }
            else{
                filteredBookings[day] = [];
            }
        });
        this.setState({
            filteredBookings: filteredBookings,
            countNewBookings: countNewBookings,
        });
    }



    openRejectMenu = (event, booking) => {
        if (event) {
            this.setState({
                showRejectedMenu: event.currentTarget,
                bookingInPopupMenu: booking
            })
        }

    }

    banGuest = (id) => {
        if (window.confirm('Are you sure ?')) {
            const { auth } = this.props;
            apiRequest('supplier/ban-user', 'POST', {booking_id: id}, auth.api_key).then(responce => {
                if (responce.status === 200) {
                    this.updateList();
                }
                else{
                    alert('Something went wrong')
                }
            })
        }
    }

    markAsCheckedBooking = (id) => {
        if (window.confirm('Please confirm that booking is well saved in your side.')) {
            const { auth } = this.props;
            apiRequest('supplier/check-booking', 'POST', {checked: true, booking_id: id}, auth.api_key).then(responce => {
                if (responce.status === 200) {
                    this.updateList();
                }
                else{
                    alert('Something went wrong')
                }
            })
        }
    }

    isTomorrow = (date) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        if (tomorrow.toDateString() === date.toDateString()) {
            return true;
        }

        return false;
    }

    changeOption = (option) => {
        this.setState({
            currentOption: option
        })
        this.filterBookings(option)
    }

    scrollToTarget = (ref) => {
        if (ref !== null) {
            let element = this.scrollTarget=ref;
            if (element.classList.contains('scrollTarget')) {
                element.scrollIntoView(true);
            }
            return element
        }
    }

    render() {
        const { countNewBookings, options, currentOption, filteredBookings,  bookingInPopupMenu, showRejectedMenu, openSuggestionPopup, booking_id, userIdForChat, tomorrow, chatkitty_channel_id, bookingDate, openChat, bookingHash, bookingName, bookingIdForChat } = this.state;
        const {  channels, loading} = this.props;
        let flagTodayTomorrow = 0;
        let scrollBlockWasDetect = false;
        if (loading) {
            return <div style={{display: 'flex',alignItems: "center", justifyContent: "center", height: "100%"}}><CircularProgress /></div>
        }
        let lengthResults = 0;
        Object.keys(filteredBookings).map((day, i) => {
            lengthResults += filteredBookings[day].length;
        });
        return (<div className="supplier-interface homepage-supplier" style={{padding: 15}}>
            <div className="sup-header child-by-center">
                <span>Bookings</span>
            </div>

            <div className="switcher-option">
                <div className={currentOption === 0 ? 'active': ''} onClick={e => this.changeOption(0)}>
                    New {countNewBookings !== '' ? '(' + countNewBookings + ')' : ''}
                </div>
                <div className={currentOption === 1 ? 'active': ''} onClick={e => this.changeOption(1)}>
                    All
                </div>
                <div className="background-switcher-option-animation">
                    <div className={currentOption > 0 ? 'active right-side' : 'active'}></div>
                </div>
            </div>

            {lengthResults === 0 && currentOption === 0 && <p>No pending booking to check, see <u onClick={e => this.changeOption(1)}><b>all upcoming bookings</b></u></p>}
            {lengthResults !== 0 && Object.keys(filteredBookings).map((day, i) => {
                let date = new Date(day).setHours(0, 0, 0, 0);
                if (date === new Date().setHours(0,0,0,0)) {
                    date = 'Today'
                    flagTodayTomorrow = 1;
                }
                else{
                    date = new Date(day);
                    if (this.isTomorrow(date)) {
                        date = 'Tomorrow'
                        if (flagTodayTomorrow !== 1) {
                            flagTodayTomorrow = 2;
                        }
                    }
                    else{
                        date = date.getDate() + ' ' + date.toLocaleString('default', { month: 'long' }) + " " + date.getFullYear()
                        if (
                            flagTodayTomorrow === 0 &&
                            new Date(date).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
                        ) {
                            flagTodayTomorrow = 3;
                        }
                    }
                }

                let extraClass = 'day-block-wrapper'
                if (!scrollBlockWasDetect && flagTodayTomorrow) {
                    scrollBlockWasDetect = true;
                    extraClass += ' scrollTarget'
                }
                if (filteredBookings[day].length === 0) {
                    return <></>
                }
                return (
                    <div key={i} className={extraClass}>
                        <div className="day-block">
                            <div>
                                <div className="sub-title">
                                    {date}
                                </div>
                            </div>
                        </div>
                        {filteredBookings[day].map((variant, j) => {
                            return (
                                <div key={j} className="optionBlock">
                                    <div key={j}>
                                        {variant.can_check === true && variant.status !== 15 && <>
                                            <div className="booking-status-checkbox">
                                                <div className="label">New !</div>
                                                <div className="checkbox">
                                                    <FormControlLabel
                                                        color="white"
                                                        labelPlacement="start"
                                                        control={
                                                            <Checkbox
                                                                name="booking_status"
                                                                onChange={() => this.markAsCheckedBooking(variant.id)}
                                                            />
                                                        }
                                                        label="Save in my internal system"
                                                    />
                                                </div>
                                            </div>
                                        </>}
                                        {variant.status === 15 && <>
                                            <div className="booking-status-checkbox">
                                                <div className="label">Last minute booking !</div>
                                                <div style={{position: 'relative'}}>
                                                    <Button
                                                        aria-owns={showRejectedMenu ? 'simple-menu' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={(e) => this.openRejectMenu(e, variant)} className="blackButton blue  small-btn">
                                                        Reject
                                                        <img style={{width:8, transform:'rotate( -90deg)', marginLeft: 10}} src={IconForRejectButton} alt=""/>
                                                    </Button>
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={showRejectedMenu}
                                                        open={Boolean(showRejectedMenu)}
                                                        onClose={this.handleCloseRejectedMenu}
                                                    >
                                                        <MenuItem onClick={(e) => this.reject(bookingInPopupMenu.id)}>Reject this booking</MenuItem>
                                                        <MenuItem onClick={(e) => this.openSuggestionPopup()}>Reject and suggest another time</MenuItem>
                                                    </Menu>
                                                </div>
                                                <Button onClick={(e) => this.aprove(variant.id)} className="blackButton accept-btn blue small-btn">Accept</Button>
                                            </div>
                                        </>}
                                        <div className="justify-between">
                                            <p className="title">{variant.name}</p>
                                            {variant.status === 20 && <Button className="blackButton gray small-btn">Rejected</Button>}
                                            {variant.status === 40 && <Button className="blackButton gray small-btn">Cancelled</Button>}
                                            {variant.status === 35 && <Button className="blackButton gray small-btn">Waiting for guest approval</Button>}
                                            {variant.status === 50 && <Button className="blackButton gray small-btn">No show</Button>}
                                        </div>
                                        <div className="line">
                                            <span><img src={Time}/><span>{variant.hour}</span></span>
                                            <span><img src={Money}/> <span>{variant.price_per_guest} {variant.currency} / guest</span></span>
                                            <span><img src={Guest}/> <span>{variant.nb_guests}&nbsp;guests</span></span>
                                        </div>
                                        <div className="by" style={{justifyContent: 'space-between', position: 'relative'}}>
                                            <div>
                                                Book by : <span> {variant.firstname} {variant.lastname}  -  <a href={'tel:'+variant.phone_number}>{variant.phone_number}</a></span>
                                            </div>
                                            <div>
                                                {variant.guest_banned !== 1 && <Button onClick={() => this.banGuest(variant.id)} className="blackButton small-btn">Ban guest</Button>}
                                                {variant.guest_banned === 1 && <Button className="blackButton gray small-btn">Guest banned</Button>}
                                            </div>
                                            <div onClick={(e) => this.openChat(variant)}>
                                                {(channels[variant.hash] !== undefined && variant.hash) && <TextsmsIcon />}
                                                { (channels[variant.hash] !== undefined && channels[variant.hash].countUnreadMesages !== 0 && channels[variant.hash].countUnreadMesages !== undefined) && <span className="newMessageCounter">{channels[variant.hash].countUnreadMesages}</span>}
                                            </div>
                                        </div>
                                        {variant.dietary_names && <div className="diet">
                                            Diet : {variant.dietary_names}
                                        </div>}
                                    </div>
                                </div>
                            )
                        })}
                        {/*{results[day].length === 0 && <p>No bookings {day}. <Link to="/calendar">Check calendar</Link></p>}*/}
                    </div>
                )
            })}
            <div onClick={() => document.getElementsByClassName('scrollTarget')[0].scrollIntoView({block: "start", behavior: "smooth"}) } className="scrollToTargetButton" ref={(ref) => this.scrollToTargetButton = ref}>
                <img src={IconForRejectButton} alt=""/>
            </div>
            {Object.keys(bookingInPopupMenu).length > 0 && <SuggestNewTime
                openPopup={openSuggestionPopup}
                oldTime={bookingInPopupMenu.hour}
                price={bookingInPopupMenu.price_per_guest}
                currency={bookingInPopupMenu.currency}
                options={options}
                option_id={bookingInPopupMenu.option_id}
                option_name={bookingInPopupMenu.name}
                handleCloseSuggestionPopup={this.handleCloseSuggestionPopup}
                suggestNewHours={this.suggestNewHours}
                booking_id={bookingInPopupMenu.id}
                auth={this.props.auth}
            />}
            {openChat && bookingHash && <Portal>
                <Dialog TransitionComponent={Transition} keepMounted fullScreen open={openChat} onClose={this.handleCloseChat}>
                    <div className="searchHeader">
                        <ArrowBackIosIcon onClick={this.handleCloseChat} />
                        <span style={{position: 'absolute',left: 0, right: 0, textAlign: 'center', width: '80%',margin: 'auto'}}>Messages</span>
                        <div></div>
                    </div>
                    <div style={{height: '100%'}}>
                        {(bookingHash && bookingName && bookingDate) && <Chat fromGuest={false} kitty={this.props.kitty} chatSession={this.props.chatSession} userId={userIdForChat} channel={channels[bookingHash]} bookingId={bookingIdForChat} chatkitty_channel_id={chatkitty_channel_id} auth={this.props.auth} hash={bookingHash} bookingName={bookingName} bookingDate={bookingDate} />}
                    </div>
                </Dialog>
            </Portal>}

        </div>)
    }
}

function mapStateToProps(state) {
    return {
        channels: getChannels(state),
        loading: isChannelLoading(state),
    }
}

export default connect(mapStateToProps)(withRouter(Bookings));
