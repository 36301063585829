import React from 'react';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DietaryRequirements from '../profile/DietaryRequirements'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class DietPopup extends React.Component{

    state = {
        openDietDialog: true
    }
    handleCloseDiet = () => {
        this.setState({
            openDietDialog: false
        });
        window.localStorage.setItem('first_booking', 0)
    }

    render() {
        const { openDietDialog } = this.state;
        return (
            <>
                {window.localStorage.getItem('first_booking') === '1' && openDietDialog && <div>
                    <Dialog
                        style={{inset: "20% 0 0",borderRadius: "3% 3% 0 0", overflow: "hidden"}}
                        TransitionComponent={Transition} keepMounted fullScreen open={(window.localStorage.getItem('first_booking') === '1' && openDietDialog)} onClose={this.handleCloseDiet}>
                        <div className="searchHeader">
                            <span onClick={this.handleCloseDiet} style={{position: "absolute", right: 20,textAlign: "center", margin: "auto",top: 10,textDecoration: "underline",fontWeight: 500}}>skip</span>
                        </div>
                        <div>
                            <DietaryRequirements firstBooking={true} onClose={this.handleCloseDiet} auth={this.props.auth} />
                            <p style={{fontSize:14, color:"#797979", textAlign:'center'}}>You can change your requirements at any time in your profile</p>
                        </div>
                    </Dialog>
                </div>}
            </>
        );
    }
}


export default DietPopup;