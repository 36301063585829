import React from 'react';
import Logo from '@images/logo_white.svg';


class Banner extends React.Component{
    render() {
        const { bunnerText } = this.props;
        return (
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", position: 'relative', height: 74, background: '#2D3340'}}>
                {!bunnerText && <img src={Logo} />}
                {!!bunnerText && <span style={{color: 'white', fontSize:20, fontWeight:600}}>{bunnerText}</span>}
            </div>
        )
    }
}

export default Banner;