import React from 'react';

import { apiRequest } from '@services/Request';
import Loader from '@semper/Loader';
import { userActions } from '@actions/index';
import { connect } from 'react-redux';
import Logo from '@images/logo_white_with_label.svg';
import {Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
import './index.css'


class ConfirmAccount extends React.Component{

    state = {
        confirmed: false,
        loading: true,
    }

    componentDidMount() {
        const {user_id, hash} = this.props.match.params;
        const { dispatch } = this.props;
        if (user_id && hash) {
            apiRequest('confirmation/' + user_id + '/' + hash, 'POST').then(response => {
                if (response.status === 200) {
                    response.json().then(data => {

                        if (data.already_confirmed) {
                            this.setState({
                                confirmed: true,
                                loading: false,
                            })
                        }
                        else{
                            dispatch(userActions.login({
                                access_token: data.access_token,
                                user: { user: data.user }
                            }));

                            if (data.referral_code) {
                                window.localStorage.setItem('referral_code', data.referral_code);
                                window.localStorage.setItem('just_confirm_email', 1);
                            }
                            window.location.href = '/';
                        }
                    })

                }

                this.setState({
                    loading: false
                })
            })
        }

    }
    render(){
        const { loading, confirmed } = this.state;
        if (loading) {
            return(
                <Loader color={'#F7F7F7'} />
            )
        }
        const isAlreadyLoggined = window.localStorage.getItem('user') ? true : false

        if (confirmed)  {
            return (
                <div className="wrapper-confirm-email-already-confirm-message bg-main-color">
                    <img src={Logo} alt="Semper"/>
                    <p>Your email is already confirmed</p>
                    <Button onClick={() => {
                        if (isAlreadyLoggined) {
                            window.location.href = '/'
                        }
                        else{
                            this.props.history.push('/login');
                        }

                    }} >{isAlreadyLoggined ? 'Go to app' : 'Login'}</Button>
                </div>
            )
        }

        return <div className="bg-main-color text-center">Something went wrong, try later</div>
    }

}


function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(ConfirmAccount);