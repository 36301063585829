import React from 'react';
import {connect} from "react-redux";
import {getChannels} from "@reducers/chat";
import {isChannelLoading} from "@reducers/chat";
import { getCountUnreadMessages } from '@reducers/chat';
import Chat from '@images/chat.svg';
import Cross from '@images/cross.svg';
import Bell from '@images/bell_in_notifications.svg';
import './notifications.css'
import {
    withRouter,
    Link
} from "react-router-dom";


class BottomNotifications extends React.Component{
    state = {
        counterUnreadMessages: 0,
        showSuggestNotification: true
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (window.localStorage.getItem('new_chat_message')) {
            this.setState({
                counterUnreadMessages: nextProps.counterUnreadMessages
            })
        }

    }

    constructor(props){
        super(props);
        this.state.counterUnreadMessages = props.counterUnreadMessages
    }

    closeMessage = () => {
        this.setState({
            counterUnreadMessages: 0
        });
        window.localStorage.removeItem('new_chat_message')
    }

    closeSuggestNotification = () => {
        this.setState({
            showSuggestNotification: false
        })
    }

    render() {
        const { auth } = this.props;
        const { counterUnreadMessages, showSuggestNotification } = this.state;
        if (window.location.pathname === '/booking-list') {
            return false
        }
        return(
            <div className="global-notification-block">
                {counterUnreadMessages > 0 && <>
                    <div className="notification message">
                        <div className="notification-content">
                            <img src={Chat} alt=""/>
                            <div className="text">
                                You have new {counterUnreadMessages} {counterUnreadMessages === 1 ? 'message' : 'messages'}. <Link onClick={this.closeMessage} to="/booking-list">See message</Link>
                            </div>
                        </div>
                        <div onClick={this.closeMessage} className="close">
                            <img src={Cross} alt=""/>
                        </div>
                    </div>
                </>}

                {showSuggestNotification && auth.user.user.bookings_ids_with_suggest.length > 0 && <div className="notification booking-suggest">
                    <div className="notification-content">
                        <img src={Bell} alt=""/>
                        <div className="text">
                            New time proposition. <Link onClick={this.closeSuggestNotification} to="/booking-list">Please check it</Link>
                        </div>
                    </div>
                    <div className="close" onClick={this.closeSuggestNotification}>
                        <img src={Cross} alt=""/>
                    </div>
                </div>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        counterUnreadMessages: getCountUnreadMessages(state),
    }
}
export default connect(mapStateToProps)(withRouter(BottomNotifications));