const validatorRules = {
    email: (value) => {
        var emailPattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return emailPattern.test(value);
    },
    required: (value) => {
        return !!value
    },
    max: (value, max) => {
        return value.length < max;
    },
    min: (value, min) => {
        return value.length > min;
    },
    password_confirmation: (password, password_confirmation) => {
        return password === password_confirmation
    }
}
const validationMessages = {
    email: 'Email is not correct',
    required: 'This is required field',
    password_confirmation: 'Passwords not same',
    max: 'Maximum symbols is ',
    min: 'Minimum symbols is ',
}

const validateField = (field, value, rules) => {
    let error = '';
    rules.split('|').forEach(rule => {
        if (rule.indexOf(':') + 1) {
            if (!validatorRules[rule.split(':')[0]](value, rule.split(':')[1])) {
                error = validationMessages[rule.split(':')[0]] + rule.split(':')[1];
            }
        }
        else{
            if (!validatorRules[rule](value)) {
                error = validationMessages[rule];
            }
        }
    });
    if (error) {
        return {
            error: true,
            helperText: error
        }
    }
    else{
        return {}
    }
}

const validate = (form, formRules) => {
    let errors = {};
    Object.keys(form).map(field => {
        if (formRules[field] !== undefined) {
            let rules = formRules[field].split("|");
            rules.forEach(rule => {
                if (rule.indexOf(':') + 1) {
                    if (!validatorRules[rule.split(':')[0]](form[field], rule.split(':')[1])) {
                        errors[field] = validationMessages[rule.split(':')[0]] + rule.split(':')[1];
                        return false
                    }
                }
                else{
                    if (rule === 'password_confirmation') {
                        if (!validatorRules[rule](form['password'], form[field])) {
                            errors[field] = validationMessages[rule];
                            return false
                        }
                    }
                    else{
                        if (!validatorRules[rule](form[field])) {
                            errors[field] = validationMessages[rule];
                            return false
                        }
                    }

                }
            });
        }
    })

    return errors;
}


export const helperValidator = {
    validate,
    validateField
}