import React from 'react';
import TextField from '@material-ui/core/TextField';
import Mark from '@images/white_mark.svg';
import { apiRequest } from '@services/Request';


class ReferralCodes extends React.Component{

    state = {
        code: '',
        success: false
    }

    submitReferralCode = (dirrectCode) => {
        let { code } = this.state;
        const { auth } = this.props;
        if (dirrectCode !== undefined) {
            code = dirrectCode
        }
        if (code) {
            this.setState({
                success: false
            })
            this.props.changeLoadingReferralCode(true);
            apiRequest('referral-code/validate', 'POST', {code: code}, auth.api_key).then(response => {
                if (response.status === 200) {
                    response.json().then(data => {
                        if (data.validated) {
                            window.localStorage.setItem('referral_code', code);
                            this.props.acceptReferral(code, data.discount_entrance_fees);
                            this.setState({
                                success: true
                            })
                        }
                        else{
                            this.props.cancelReferral();
                            // this.props.showNotification('Invalid referral code');
                        }
                        this.props.changeLoadingReferralCode(false);
                    })

                }
                else{
                    this.props.showNotification('Something went wrong, please try later');
                }
            })
        }
    }


    changeCode = (e) => {
        this.submitReferralCode(e.target.value);
        this.setState({
            code: e.target.value
        })
    }

    componentDidMount() {
        this.submitReferralCode();
    }

    constructor(props) {
        super(props);
        if (window.localStorage.getItem('referral_code')) {
            this.state = { code: window.localStorage.getItem('referral_code'),  success: false};
        }

    }

    render() {
        const { code, success } = this.state;
        const { referral_was_accepted } = this.props;
        return <>
            <div className="wrapper-referral-codes">
                <div className="price-line">
                    <div className="label">
                        Entrance fee
                    </div>
                    <div className="price-wrapper">
                        {referral_was_accepted && <div className="old-price">
                            {this.props.entrance_fees} €
                        </div>}
                        <div className="price">
                            {referral_was_accepted === true && <>
                                {this.props.discount_entrance_fees === 0 && <>Free</>}
                                {this.props.discount_entrance_fees !== 0 && <>{ (this.props.discount_entrance_fees).toFixed(2).replace('.00','')} €</>}
                            </>}
                            {referral_was_accepted === false && <>
                                {this.props.entrance_fees} €
                            </>}
                        </div>
                    </div>
                </div>
                <div className="referral-code-form">
                    <div className="label">If you have, write your referrer name to get his offer</div>
                    <div className="wrapper-input">
                        <TextField
                            name="referal-code"
                            value={code}
                            onChange={this.changeCode}
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>
                {success && <div className="success-message">You have been invited by {this.props.code}</div>}
            </div>
        </>
    }
}

export default ReferralCodes;