import React, { Component } from 'react';
import Money from '@images/money.svg'
import Time from '@images/time.svg'
import Guest from '@images/guest.svg'
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Card from '@images/card.svg'
import TextsmsIcon from '@material-ui/icons/Textsms';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiRequest } from '@services/Request';
import { generateBookingConditions } from '@services/Date';

class BookingInfo extends React.Component{

    state = {
        loading: true,
        supplierConditionsShowed: false,
        canceledConditionsShowed: false,
        variables: {}
    }
    componentDidMount() {
        apiRequest('anonymous/settings/list', 'GET', null).then(responce => {
            if (responce.status === 200) {
                responce.json().then(data => {
                    this.setState({
                        loading: false,
                        variables: generateBookingConditions(this.props.booking.date + ' ' + this.props.booking.hours, data.data)
                    })
                })
            }
        })
    }


    accordionEvent = (name) => {
        let state = {...this.state};
        state[name] = !state[name];
        this.setState(state)
    }

    render() {
        const { booking, supplier, inBookingPage } = this.props;
        const { loading, variables, canceledConditionsShowed, supplierConditionsShowed } = this.state;
        if (loading) {
            return <div style={{display: 'flex',alignItems: "center", justifyContent: "center", height: "100%"}}><CircularProgress /></div>
        }
        return (
            <div className="wrapperBooking booking-on-supplier-page">

                <div className="generalInfo">
                    <div className="line general">
                        <div>{booking.serviceName}</div>
                        <div>{booking.date}</div>
                    </div>
                    <div className="line subtitle">
                        <div><img src={Guest} alt=""/><span>{booking.guests} guests</span></div>
                        <div><img src={Time} alt=""/><span>{booking.hours} </span></div>
                    </div>
                </div>

                <div className="blockInfo">
                    <div className="line">
                       <div>
                           <div className="title">Minimum spending</div>
                           <div className="subtitle">To pay at the restaurant</div>
                       </div>
                        <div>
                            <span className="small">({booking.guests} x {booking.price} {this.props.currency})</span>  {((booking.price * booking.guests).toFixed(2).replace('.00',''))} <span className="small">{this.props.currency}</span>
                        </div>
                    </div>

                </div>
                <div className="blockInfo">
                    <div className="line">
                        <div>
                            <div className="title">Booking fees</div>
                            <div className="subtitle">Charged by Semper </div>
                        </div>
                        <div>
                            <div>
                                <span className="small">({booking.guests} x {this.props.fees_amount / booking.guests} {this.props.currency})</span> {this.props.fees_amount + " "} <span className="small">{this.props.currency}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="blockInfo canceling-condition-block">
                    <div className="line">
                        <div>
                            <div className="title">Cancellation conditions</div>
                        </div>
                        <div></div>
                    </div>
                    <div className="justify-between gray-text">
                        <span>Before {variables.no_fees}</span>
                        <span>no charge</span>
                    </div>
                    <div className="justify-between gray-text">
                        <span>Before {variables.fees50}</span>
                        <span>50% ({(((booking.price * booking.guests)) / 2).toFixed(2).replace('.00','')} {this.props.currency} )</span>
                    </div>
                    <div className="justify-between gray-text">
                        <span>After {variables.fees50} or in case of no show</span>
                        <span>100% ({((booking.price * booking.guests).toFixed(2).replace('.00',''))} {this.props.currency} )</span>
                    </div>
                </div>

            </div>
        )
    }
}

export default BookingInfo;
