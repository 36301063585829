import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";
import { apiRequest } from '@services/Request';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { formatTime } from '@semper/FormatTime';
import Dialog from "../supplier";

class InviteForm extends React.Component{
    state = {
        isLoading: false,
        success: '',
        error: '',
        form: {
            from: '',
            to: '',
            message: '',
        }
    }

    onChange = (e) => {
        var form = { ...this.state.form };
        form[e.target.name] = e.target.value;
        this.setState({
            form
        })
    }

    handleClose = () => {
        this.setState({
            success: '',
            error: '',
        })
    }

    onSave = () => {
        const { api_key } = this.props.auth;
        const { variant, date } = this.props;
        let type = (this.props.auth.user.user.type === 'advisor') ? 'advisor' : 'user';
        this.setState({
            isLoading: true
        });
        // /api/
        if (typeof variant != "undefined") {
            apiRequest('advisor/send-invitation-booking', 'POST', {price: variant.price, id:variant.id, date: date, email: this.state.form.to, message: this.state.form.message}, api_key).then(request => {
                if (request.status === 200) {
                    request.json().then(data => {
                        this.setState({
                            isLoading: false
                        });
                        this.props.inviteCallback('Invitation link has been sent');
                    })
                }
                else{
                    if (request.status === 401) {
                        // this.setState({error: 'Incorrect email or password'})
                    }
                    else if (request.status === 422) {
                        request.json().then(data => {
                            this.setState({
                                error: data.messages.join("\n")
                            })
                        })
                    }
                }

                this.setState({
                    isLoading:false
                })

            });
        }
        else{
            apiRequest(type + '/invite', 'POST', {email: this.state.form.to, message: this.state.form.message}, api_key).then(request => {
                if (request.status === 200) {
                    request.json().then(data => {
                        this.setState({
                            success: 'Invitation link has been sent',
                            isLoading: false
                        });
                    })

                }
                else{
                    if (request.status === 401) {
                        // this.setState({error: 'Incorrect email or password'})
                    }
                    else if (request.status === 422) {
                        request.json().then(data => {
                            this.setState({
                                error: data.messages.join("\n")
                            })
                        })
                    }
                }

                this.setState({
                    isLoading:false
                })

            });
        }

    }
    render() {
        const { form, isLoading, success, error } = this.state;
        const { variant } = this.props;
        console.log(variant);
        return (
            <div>
                <div style={{ padding: 10, textAlign: 'center', boxSizing: 'border-box',minHeight: 71,display: 'flex', alignItems: 'center',justifyContent: 'center',fontSize:20,color: '#f7f7f7', background: 'rgb(45, 51, 64)'}}>
                    Invite someone to create an account on Semper
                </div>
                <div style={{padding: 10}}>
                    {typeof variant != "undefined" && typeof variant.id != "undefined" && <div style={{background: '#EDE6DD', padding: 10, borderRadius: 15, textAlign: 'center'}}>
                        <b>Book details</b>
                        <p style={{margin: 0}}>{this.props.name}</p>
                        <p style={{margin: 0}}>{variant.option_name} {formatTime(variant.start_hour)} to {formatTime(variant.end_hour)} </p>
                    </div>}
                    <br/>
                    <br/>
                    <Grid className="fullscreen" justify="center" container alignItems="center" spacing={1}>
                        <Grid item xs={12} spacing={1}>
                            <Grid justify="center" container alignItems="center" spacing={1}>
                                <Grid item xs={12} spacing={1}>
                                    <TextField
                                        label="From"
                                        name="from"
                                        className="auto-height"
                                        value={this.props.auth.user.user.email}
                                        variant="outlined"
                                        disabled={true}
                                        readonly
                                        fullWidth
                                    />
                                    <br/><br/>
                                    <TextField
                                        label="To"
                                        name="to"
                                        className="auto-height"
                                        onChange={this.onChange}
                                        value={form.to}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <br/><br/>
                                    <TextField
                                        label="Message"
                                        name="message"
                                        className="multiline-input"
                                        onChange={this.onChange}
                                        value={form.message}
                                        variant="outlined"
                                        multiline
                                        rows={10}
                                        fullWidth
                                    />
                                    <br/><br/>
                                    <div style={{textAlign: "center"}}><Button className="blackButton" disabled={isLoading} onClick={this.onSave} variant="contained">
                                        {!isLoading && <>Invite</>}
                                        {isLoading && <CircularProgress size={29}  />}
                                    </Button></div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Snackbar open={!!success}  autoHideDuration={6000} onClose={this.handleClose}>
                        <Alert severity="success">
                            {success}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                        <Alert severity="error">
                            {error}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        )
    }
}

export default InviteForm;
