import React from 'react';
import ReferralCodes from './referal'
import EntrancePayment from './payment'
import ConfirmEntrance from './confirm'
import Logo from '@images/logo_white.svg';
import Button from '@material-ui/core/Button';
import { apiRequest } from '@services/Request';
import Loader from '@semper/Loader';
import './pay_entrance_fees.css'
import CircularProgress from '@material-ui/core/CircularProgress';

class PayEntranceFees extends React.Component{


    state = {
        loading: true,
        referralLoading: false,
        currentStep: 0,
        entrance_fees: '',
        discount_entrance_fees: null,
        last_hours_duration_for_fees: '',
        free_expiration_date: '',
        premium_member_monthly_price: '',
        referral_was_accepted: false,
        referral_code: '',
        showNotification: false,
        textNotification: ''
    }

    constructor(props){
        super(props);
        if (props.auth.user.user.status === 17) {
            this.state['currentStep'] = 2;
            this.state['referral_was_accepted'] = true;
            this.state['loading'] = false;
            this.state['discount_entrance_fees'] = 0;
        }
    }

    componentWillMount() {
        const { auth } = this.props;
        if (auth.user.user.status !== 17) {
            apiRequest('user/get-premium', 'GET', null, auth.api_key).then(response =>{
                if (response.status === 200) {
                    response.json().then(data => {
                        this.setState({
                            loading: false,
                            last_hours_duration_for_fees: data.last_hours_duration_for_fees,
                            free_expiration_date: data.free_expiration_date,
                            entrance_fees: data.entrance_fees,
                            premium_member_monthly_price: data.premium_member_monthly_price,
                        })
                    })
                }
            })
        }

    }


    acceptReferral = (code, discount_entrance_fees) => {
        this.setState({
            referral_was_accepted: true,
            discount_entrance_fees: discount_entrance_fees,
            referral_code: code
        })
    }

    cancelReferral = () => {
        this.setState({
            referral_was_accepted: false,
            discount_entrance_fees: null,
            referral_code: ''
        })
    }

    nextStep = () => {
        const { discount_entrance_fees, referralLoading, referral_code } = this.state;
        const { auth } = this.props;
        if (referralLoading) {
            return false;
        }
        if (discount_entrance_fees === 0) {
            this.setState({
                loading: true
            })
            apiRequest('payment-entrance/set-free', 'POST', {code: referral_code }, auth.api_key).then(response =>{
                if (response.status === 200) {
                    response.json().then(data => {
                        if (data.validated && data.status) {
                            this.setState({
                                loading: false,
                                currentStep: 2,
                            })
                        }
                        else{
                            this.showNotification('Something went wrong, please try later');
                        }
                    });

                }
                else{
                    this.showNotification('Something went wrong, please try later');
                }
            });

        }
        else{
            this.setState({
                currentStep: this.state.currentStep + 1
            })
        }

    }

    checkJustConfirmEmail = () => {
        if (window.localStorage.getItem('just_confirm_email')) {
            this.showNotification('Your address is confirmed');
            window.localStorage.removeItem('just_confirm_email');
        }
    }
    componentDidMount() {
        this.checkJustConfirmEmail();
    }

    showNotification = (message) => {
        this.setState({
            showNotification: true,
            textNotification: message,
        })
        setTimeout(() => {
            this.setState({
                showNotification: false,
                textNotification: '',
            })
        }, 6000)
    }

    changeLoadingReferralCode = (loaderFlag) => {
        this.setState({
            referralLoading: loaderFlag
        })
    }

    render() {
        const { currentStep, code, showNotification, referral_code, textNotification,
            entrance_fees, discount_entrance_fees, premium_member_monthly_price,
            last_hours_duration_for_fees, free_expiration_date, loading,
            price, percents_of_discount, referral_was_accepted, referralLoading } = this.state;
        const className = currentStep === 1 ? 'payment-step' : '';
        if (loading) {
            return <Loader color={'#F7F7F7'} />
        }
        return (
            <div className={'bg-main-color wrapper-pay-entrance-fees ' + className}>
                {showNotification && <div className="register-notifications">
                    { textNotification }
                </div>}
               <div className="top-block">
                   <img src={Logo} alt=""/>
                   <div className="title">
                       <div>Member registration</div>
                   </div>
               </div>
                <div className="content">
                    {currentStep === 0 && <ReferralCodes changeLoadingReferralCode={this.changeLoadingReferralCode} premium_member_monthly_price={premium_member_monthly_price} discount_entrance_fees={discount_entrance_fees} entrance_fees={entrance_fees} code={referral_code} cancelReferral={this.cancelReferral} auth={this.props.auth} referral_was_accepted={referral_was_accepted} showNotification={this.showNotification} price={price} percents_of_discount={percents_of_discount} acceptReferral={this.acceptReferral} />}
                    {currentStep === 1 && <EntrancePayment total={referral_was_accepted ? discount_entrance_fees : entrance_fees} nextStep={this.nextStep} code={referral_code} referral_code={referral_code} premium_member_monthly_price={premium_member_monthly_price} discount_entrance_fees={discount_entrance_fees} entrance_fees={entrance_fees}  auth={this.props.auth} referral_was_accepted={referral_was_accepted} showNotification={this.showNotification} price={price} percents_of_discount={percents_of_discount} />}
                    {currentStep === 2 && <ConfirmEntrance discount_entrance_fees={discount_entrance_fees} last_hours_duration_for_fees={last_hours_duration_for_fees} free_expiration_date={free_expiration_date} code={code} referral_code={referral_code} total={referral_was_accepted ? price - price * ( percents_of_discount / 100 ) : price} auth={this.props.auth} referral_was_accepted={referral_was_accepted} showNotification={this.showNotification} price={price} percents_of_discount={percents_of_discount} />}
                </div>
                <div className="action">
                    {currentStep === 0 && <Button disabled={referralLoading} onClick={this.nextStep} variant="contained">
                        {referralLoading && <CircularProgress color="secondary" size={29}  />}
                        {referralLoading === false && <>Continue</>}
                    </Button>}
                </div>
            </div>
        );
    }
}


export default PayEntranceFees;