export function formatTime(time){
    let _time = "" + time;
    if (_time.indexOf('.5') >= 0) {
        _time = _time.replace('.5','.30')
    }
    else{
        _time = _time + ":00";
    }

    return _time.replace('.',':');
}