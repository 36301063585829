import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import BookingInfo from './BookingInfo';
import Payment from './payment';
import Conditions from '@images/conditions.svg';
import { apiRequest } from '@services/Request';
import CircularProgress from '@material-ui/core/CircularProgress';
import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import Time from '@images/time.svg';
import Alert from '@material-ui/lab/Alert';
import { formatTime } from '@semper/FormatTime';
import PaymentSubscription from '@semper/PaymentSubscription'
import Subscriptions from '@semper/Subscriptions'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{padding:'5px'}} p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

class Booking extends React.Component{
    state = {
        currentStep: 0,
        fees_amount: 0,
        billing_address_id: 0,
        last_hours_duration_for_fees: 8,
        fees_required: false,
        options: [],
        selectedHours: null,
        hours_options: [],
        price: null,
        must_be_approved: false,
        booking_is_ready: false,
        loadingBook: false,
        loadingConfirmBooking: false,
        isOpenSubscribePaymentDialog: false,
        bookingId: '',
        error: '',
        commission: null,
        hour: null,
        can_book: false,
        titles: ['Booking','Booking', 'Payment Settings'],
        isPremium: false,
        isLoading: true,
        selected_premium_product: 0,
        selected_premium_cities: [],
        isOpenPaymentDialog: false,
        generalFees: 15,
        unsubscribe: false,
        pricePremium: 0,
        cities: [],
        premium_products: [],
        next_billing_date: 0,
        cards: []
    }

    nextStep = () => {
        this.setState({
            currentStep: this.state.currentStep + 1
        })
    }

    switchToPremium = (productId) => {
        this.setState({
            isPremium: true,
            selected_premium_product: productId,
            isOpenSubscribePaymentDialog:false
        })
    }

    componentDidMount() {
        const { auth, selectedVariantObject } = this.props;
        if (selectedVariantObject !== undefined && this.state.selectedHours === null) {
            this.setState({
                price: selectedVariantObject.price
            })
        }

        apiRequest('user/get-premium', 'GET', null, auth.api_key).then(response =>{
            if (response.status === 200) {
                response.json().then(data => {
                    this.setState({
                        isPremium: data.is_premium,
                        last_hours_duration_for_fees: data.last_hours_duration_for_fees,
                        generalFees: data.fees,
                        cards: data.credit_cards,
                        pricePremium: data.price_per_month,
                    })
                })
            }
        })
    }

    subscribe = () => {
        const { auth } = this.props;
        const { isPremium } = this.state;
        if (!isPremium) {
            apiRequest('user/get-premium', 'GET', null, auth.api_key).then(responce => {
                if (responce.status === 200) {
                    responce.json().then((data) => {
                        this.setState({
                            isOpenSubscribePaymentDialog: true,
                            generalFees: data.fees,
                            last_hours_duration_for_fees: data.last_hours_duration_for_fees,
                            next_billing_date: data.next_billing_date,
                            cities: data.cities,
                            premium_products: data.premium_products,
                            selected_premium_cities: data.selected_premium_cities,
                            selected_premium_product: data.selected_premium_product,
                            pricePremium: data.price_per_month,
                            isLoading: false,
                        })
                    })

                }
            });
        }


    }

    closeSubscribePaymentDialog = () => {
        this.setState({
            isOpenSubscribePaymentDialog: false
        })
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.hourRange.length) {
            setTimeout(e => {
                if (this.state.selectedHours === null) {
                    this.book(formatTime(this.props.selectedHours));
                }
                else{
                    this.book(this.state.selectedHours);
                }

                if (window.localStorage.getItem('selected_booking_id') && window.localStorage.getItem('selected_booking_variant_id')) {
                    this.subscribe();
                    window.localStorage.removeItem('selected_booking_id');
                    window.localStorage.removeItem('selected_booking_variant_id');

                }
            }, 200)

        }
    }

    stepBack = () => {
        this.setState({
            currentStep: this.state.currentStep - 1
        })
    }
    handleChangeIndex = (index) => {
        this.setState({
            currentStep: index
        })
    }
    book = (selectedHours) => {
        const { auth } = this.props;
        let minGuests = 1;
        let selectedVariantObject;
        this.setState({loadingBook: true});
        if (this.props.selectedVariant) {
            selectedVariantObject = [].concat.apply([], this.props.options.map(option => {return option.options_variants})).find(variant => { return variant.id == this.props.selectedVariant});
            minGuests = selectedVariantObject.nb_users_min;
            
        }
        else{
            selectedVariantObject = this.props.options.find(option => { return option.option_id === this.props.selectedOption}).options_variants[0];
            minGuests = selectedVariantObject.nb_users_min;
           
        }
        const currentSelectedGuests = (this.props.selectedGuests) ? this.props.selectedGuests : minGuests;
        if (this.props.selectedVariant) {
            apiRequest('booking/book', 'POST', {hour: selectedHours, id: this.props.selectedVariant, date: this.props.date.replace(/\//g,"-"), nb_guests: currentSelectedGuests}, auth.api_key).then(responce => {
                if (responce.status === 200) {
                    responce.json().then((data) => {
                        this.setState({
                            fees_required: data.fees_required,
                            must_be_approved: data.must_be_approved,
                            booking_is_ready: data.booking_is_ready,
                            can_book: data.can_book,
                            fees_amount: data.data.fees_amount,
                            hours_options: data.hours_options,
                            commission: data.commission,
                            price: data.data.price + '',
                            loadingBook: false
                        });

                        if (!this.state.selectedHours || data.hours_options.find(option => option.hour === selectedHours) === undefined) {
                            this.setState({
                                selectedHours: data.hours_options[0].hour,
                            });
                        }
                    })
                }
                else{
                    responce.json().then((data) => {
                        this.setState({
                            loadingBook: false,
                            can_book: false,
                            error: data.message
                        })
                    });

                }
            })
        }
    }

    handleClose = () => {
        this.setState({
            success: '',
            error: '',
        })
    }

    confirmBooking = () => {
        const { auth } = this.props;
        const { booking_is_ready, isPremium } = this.state;
        let minGuests = 1;
        let selectedVariantObject;
        this.setState({
            loadingConfirmBooking: true
        })
        if (this.props.selectedVariant) {
            selectedVariantObject = [].concat.apply([], this.props.options.map(option => {return option.options_variants})).find(variant => { return variant.id == this.props.selectedVariant});
            minGuests = selectedVariantObject.nb_users_min;
        }
        else{
            selectedVariantObject = this.props.options.find(option => { return option.option_id === this.props.selectedOption}).options_variants[0];
            minGuests = selectedVariantObject.nb_users_min;
        }
        const currentSelectedGuests = (this.props.selectedGuests) ? this.props.selectedGuests : minGuests;
        apiRequest('booking/confirm-book', 'POST', {hour: this.state.selectedHours, id: this.props.selectedVariant, date: this.props.date.replace(/\//g,"-"), nb_guests: currentSelectedGuests}, auth.api_key).then(responce => {
            if (responce.status === 200) {
                responce.json().then((data) => {
                    this.setState({
                        cards: data.extra_data.credit_cards,
                        billing_address_id: data.billing_address_id,
                        bookingId: data.booking_id,
                        loadingConfirmBooking: false,
                        currentStep: this.state.currentStep + 1
                    })
                })
            }
            else{
                responce.json().then((data) => {
                    this.setState({
                        loadingConfirmBooking: false,
                        error: data.message
                    })
                })
            }

        })
    }

    handleCloseBookingDialog = () => {
        this.setState({
            currentStep: 0
        });
        this.props.handleCloseBookingDialog();
    }

    calcPayment = (total, commission) => {
        const { fees_required, fees_amount } = this.state;
        let calcTotal = total + fees_amount;
        return calcTotal.toFixed(2).replace('.00','');
    }

    calcFees = (total, commission) => {
        let fees = 0;
        if (commission) {
            fees = (total * (commission / 100));
        }

        return fees.toFixed(2).replace('.00','');
    }


    changeHours = (e) => {
        this.setState({
            selectedHours: e.target.value
        });

        this.book(e.target.value);
    }

    render() {
        const { openBookingPopup, currency } = this.props;
        const { currentStep, fees_required,
            titles, commission, fees_amount,
            cards, bookingId, loadingBook,
            loadingConfirmBooking, error,
            must_be_approved, can_book,
            billing_address_id, isOpenSubscribePaymentDialog,
            selected_premium_product, selected_premium_cities, cities, premium_products,
            price, hours_options, selectedHours, booking_is_ready } = this.state;
        if (!this.props.selectedOption || price === null || hours_options.length === 0) {
            return <></>
        }

        let minGuests = 1;
        let selectedVariantObject;
        if (this.props.selectedVariant) {
            selectedVariantObject = [].concat.apply([], this.props.options.map(option => {return option.options_variants})).find(variant => { return variant.id == this.props.selectedVariant});
            minGuests = selectedVariantObject.nb_users_min;
        }
        else{
            selectedVariantObject = this.props.options.find(option => { return option.option_id === this.props.selectedOption}).options_variants[0];
            minGuests = selectedVariantObject.nb_users_min;
        }
        const guestNumberList = Array.from({length: 7}, (_, i) => i +  minGuests);
        const currentSelectedGuests = (this.props.selectedGuests) ? this.props.selectedGuests : minGuests;
        const totalForPayment = fees_amount  + " " + currency+' + Pledge '+((price * currentSelectedGuests).toFixed(2).replace('.00',''))+' ' + currency
        const fees = fees_required ? this.calcFees(price * currentSelectedGuests, commission) : 0;
        const dialogStyles = currentStep === 0 ? {inset: "calc(100% - 377px) 0px 0px",borderRadius: "3% 3% 0 0", overflow: "hidden"} : {inset: "0 0 0",borderRadius: "0", overflow: "hidden"} ;
        const dialogClassName = currentStep === 0 ? 'first-step-booking-dialog-wrapper no-padding' : '';
        return(<>
            <Dialog keepMounted
                    fullScreen
                    open={openBookingPopup}
                    style={dialogStyles}
                    className={dialogClassName}
                    onClose={this.props.handleCloseBookingDialog}
            >
                {currentStep !== 0 && <><div className="searchHeader">
                    {!!currentStep && <ArrowBackIosIcon onClick={this.stepBack} />}
                    {!currentStep && <div> </div>}
                    <span style={{    position: 'absolute',left: 0, right: 0, textAlign: 'center', width: '80%',margin: 'auto'}}>{titles[currentStep]}</span>
                    <CloseIcon onClick={this.handleCloseBookingDialog} />
                </div>
                <div className="topbar-booking-popup">
                    { (currentStep <= 1) ? this.props.supplier.name : (booking_is_ready ? totalForPayment : ('Pay ' + fees_amount + " " + currency + " (fees)"))}
                </div></>}
                <div style={{padding: 5}}>
                    <SwipeableViews
                        axis='x'
                        index={currentStep}
                        disabled={true}
                    >
                        <TabPanel value={currentStep} index={0}>
                            <div className="booking-first-step-popup">
                                <FormControl variant="outlined" style={{width: '100%', marginBottom: 15}}>
                                    <label htmlFor="hours">Choose your booking time</label>
                                    <Select
                                        id="hours"
                                        value={selectedHours}
                                        required
                                        name="hour"
                                        onChange={this.changeHours}
                                        fullWidth
                                    >
                                        {hours_options.map(hour => {
                                            return (
                                                <MenuItem fullWidth value={hour.hour}>{(hour.hour)} ({hour.price} {currency})</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" style={{width: '100%', marginBottom: 15}}>
                                    <label htmlFor="guests">Indicate number of guests</label>
                                    <Select
                                        labelId="guests"
                                        id="guests"
                                        value={currentSelectedGuests}
                                        required
                                        name="guests"
                                        onChange={this.props.changeNumberGuests}
                                        fullWidth
                                    >
                                        {guestNumberList.map(number => {
                                            return (
                                                <MenuItem fullWidth value={number}>{number} guests</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                <div className="child-by-center">
                                    <Button disabled={(loadingBook || !can_book)} onClick={this.nextStep} className="blackButton book-btn">
                                        {!loadingBook && <span>Confirm</span>}
                                        {!!loadingBook && <CircularProgress size={29} style={{color:'white'}} />}
                                    </Button>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={currentStep} index={1}>
                            {must_be_approved && <span style={{fontSize:12, color: '#797979',marginBottom: 10,display: 'block',textAlign: 'center'}}>Short time before event, be aware that supplier can reject this booking</span>}
                            <BookingInfo fees_amount={fees_amount} booking_is_ready={booking_is_ready}
                                         currency={currency} commission={fees_required ? commission : undefined}
                                         auth={this.props.auth}
                                         booking={{hours: selectedHours,serviceName: this.props.options[this.props.options.findIndex(option => {return option.option_id == this.props.selectedOption})].option_name, date: this.props.date, guests: currentSelectedGuests, price: price}} />
                            <div className="child-by-center confirm-booking-button">
                                <Button disabled={loadingConfirmBooking} onClick={this.confirmBooking} className="blackButton book-btn">
                                    {!loadingConfirmBooking && <span>
                                        Confirm and pay {fees_amount} {currency}
                                    </span>}
                                    {!!loadingConfirmBooking && <CircularProgress size={29} style={{color:'white'}} />}
                                </Button>
                            </div>
                        </TabPanel>
                        <TabPanel className="payment-form-on-supplier-page" value={currentStep} index={2}>
                            <Payment
                                total={totalForPayment}
                                billing_address_id={billing_address_id}
                                bookingId={bookingId}
                                totalWithoutFees={((price * currentSelectedGuests).toFixed(2).replace('.00',''))}
                                fees={fees_amount}
                                auth={this.props.auth}
                                booking_is_ready={booking_is_ready}
                                currency={currency}
                                cards={cards} />
                        </TabPanel>
                    </SwipeableViews>
                </div>
            </Dialog>
            <Portal>
                <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>
                {isOpenSubscribePaymentDialog &&
                <Dialog
                    style={{inset: "20% 0 0",borderRadius: "3% 3% 0 0", overflow: "hidden"}}
                    TransitionComponent={Transition}
                    keepMounted
                    fullScreen
                    className="subscribe-payment-dialog-wrapper"
                    open={isOpenSubscribePaymentDialog}
                    onClose={this.closeSubscribePaymentDialog}>
                    <div className="searchHeader">
                        <div></div>
                        <CloseIcon onClick={this.closeSubscribePaymentDialog} />
                    </div>
                    <div className="plans-from-profile">
                        <div className="subscription-payment">
                            <h2 style={{fontSize: 25, fontWeight: 600, marginTop:0}}>Book commission-free by being a Premium Member of Semper</h2>
                            <Subscriptions
                                switchToPremium={this.switchToPremium}
                                access_token={this.props.auth.api_key}
                                cities={cities}
                                cards={cards}
                                autocomplite_city_id={this.props.city_id}
                                premium_products={premium_products}
                                selected_premium_cities={selected_premium_cities}
                                selected_premium_product={selected_premium_product}
                            />
                        </div>
                    </div>


                </Dialog>
                }
            </Portal>
        </>)
    }
}

export default Booking;
