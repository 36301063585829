import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Portal from '@material-ui/core/Portal';
import IconButton from '@material-ui/core/IconButton';
import { apiRequest } from '@services/Request';
import CloseIcon from '@material-ui/icons/Close';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class TermsAndConditions extends React.Component{


    state = {
        content: '',
        title: 'Terms and Conditions',
        loading: true,
    }

    componentDidMount() {
        apiRequest('page/terms-and-conditions', 'GET', null).then(responce => {
            if (responce.status === 200) {
                responce.json().then(data => {
                    if (data.status) {
                        this.setState({
                            content: data.record.content,
                            title: data.record.title,
                            loading: false
                        })
                    }
                    else{
                        this.setState({
                            error: true,
                            loading:false
                        })
                    }

                })
            }
            else{
                this.setState({
                    error: true,
                    loading:false
                })
            }
        })
    }

    render() {
        const { content, title } = this.state;
        return <Portal>
            <Dialog
                TransitionComponent={Transition}
                keepMounted
                fullScreen
                open={this.props.open}
                onClose={this.props.closeTermsAndConditions}>
                <div className="searchHeader">
                    <div> </div>
                    <p>{title}</p>
                    <IconButton edge="start" color="inherit" onClick={this.props.closeTermsAndConditions} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </div>

                <div style={{padding: 10}} className="terms-and-conditions-content">
                    <div dangerouslySetInnerHTML={{__html: content}}></div>
                </div>
            </Dialog>
        </Portal>
    }
}

export default TermsAndConditions;