import React, { Component } from 'react';
import Money from '@images/money.svg'
import Time from '@images/time.svg'
import Guest from '@images/guest.svg'
import Button from '@material-ui/core/Button';
import Card from '@images/card.svg'
import TextsmsIcon from '@material-ui/icons/Textsms';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiRequest } from '@services/Request';
import { generateBookingConditions } from '@services/Date';

class BookingInfo extends React.Component{

    state = {
        loading: true,
        variables: {}
    }
    componentDidMount() {
        apiRequest('anonymous/settings/list', 'GET', null).then(responce => {
            if (responce.status === 200) {
                responce.json().then(data => {
                    this.setState({
                        loading: false,
                        variables: generateBookingConditions(this.props.booking.date + ' ' + this.props.booking.hours, data.data)
                    })
                })
            }
        })
    }

    render() {
        const { booking, supplier, inBookingPage } = this.props;
        const { loading, variables } = this.state;
        if (loading) {
            return <div style={{display: 'flex',alignItems: "center", justifyContent: "center", height: "100%"}}><CircularProgress /></div>
        }
        return (
            <div className="wrapperBooking">
                {inBookingPage && <div>
                    <p style={{marginBottom:0, marginTop:10, fontSize: 20, fontWeight: 600, textAlign: 'center'}}>{supplier.name}</p>
                    <div style={{display:'flex',alignItems:'center',justifyContent: 'space-around'}}>
                        <span style={{fontSize:14, fontWeight:600, textAlign:'center', margin:'5px 0 0 0'}}>
                            Contact :
                        </span>
                        <a style={{color: '#2D3340', textDecoration: 'underline'}} href={'tel:'+supplier.direct_phone}>{supplier.direct_phone}</a>
                        <span style={{color: '#2D3340', textDecoration: 'underline'}}>
                            {this.props.inBookingPage && this.props.showChatIcon &&
                            <p style={{display: 'flex', position: 'relative'}} onClick={this.props.openChat}>
                                By message
                                <TextsmsIcon style={{marginLeft: 5}} />
                                {(this.props.unreadMessagesCount !== 0 && this.props.unreadMessagesCount !== undefined) && <span style={{right: -10, top: -5}} className="newMessageCounter">{this.props.unreadMessagesCount ?  this.props.unreadMessagesCount : ''}</span>}
                            </p>}
                        </span>
                    </div>
                    <br/>
                </div>}
                <div className="generalInfo">
                    <div className="line general">
                        <div>{booking.serviceName}</div>
                        <div>{booking.date}</div>
                    </div>
                    <div className="line subtitle">
                        <div><img src={Guest} alt=""/><span>{booking.guests} guests</span></div>
                        <div><img src={Time} alt=""/><span>{booking.hours} </span></div>
                    </div>
                </div>

                <div className="blockInfo">
                    <div className="title">Minimum spending</div>
                    <div className="flex-by-center">
                        <div className="blockWithImage gray-text">
                            <img src={Money} alt=""/>
                            <span>You agree to spend a minimum bill of {((booking.price * booking.guests).toFixed(2).replace('.00',''))} {this.props.currency} </span>
                        </div>
                    </div>
                </div>
                <div className="blockInfo">
                    <div className="title">Fees</div>
                    <div className="flex-by-center">
                        <div className="blockWithImage gray-text">
                            <img src={Card} alt=""/>
                            {typeof this.props.commission != "undefined" && <span>charged non-refundable service fee of {((booking.price * booking.guests) * (this.props.commission / 100)).toFixed(2).replace('.00','')} {this.props.currency} </span>}
                            {typeof this.props.commission == "undefined" && <span>charged non-refundable service fee of {this.props.fees_amount} {this.props.currency} </span>}
                        </div>
                    </div>
                </div>
                <div className="blockInfo">
                    <div className="title">Cancellation conditions</div>
                    <div className="justify-between gray-text">
                        <span>Before {variables.no_fees}</span>
                        <span>no charge</span>
                    </div>
                    <div className="justify-between gray-text">
                        <span>Before {variables.fees50}</span>
                        <span>50% ({(((booking.price * booking.guests)) / 2).toFixed(2).replace('.00','')} {this.props.currency} )</span>
                    </div>
                    <div className="justify-between gray-text">
                        <span>After {variables.fees50} or in case of no show</span>
                        <span>100% ({((booking.price * booking.guests).toFixed(2).replace('.00',''))} {this.props.currency} )</span>
                    </div>
                </div>

                {!!this.props.cancelBooking && <div className="child-by-center">
                    <Button onClick={this.props.cancelBooking} className="blackButton" style={{minWidth: 180}}>Cancel</Button>
                    <br/><br/>
                </div>}

            </div>
        )
    }
}

export default BookingInfo;
