import Visa from '@images/visa.svg';
import Amex from '@images/ae.svg';
import Mastercard from '@images/mc.svg';
const detectCardType = (number) => {
    if (number.length >= 5) {
        let numberWihoutSpaces = number.replace(/ /g,'');
        if ((16 - number.length) > 0) {
            numberWihoutSpaces += [...Array((16 - numberWihoutSpaces.length)).keys()].map(() => '0').join('')
        }
        var re = {
            // electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
            // maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
            // dankort: /^(5019)\d+$/,
            // interpayment: /^(636)\d+$/,
            // unionpay: /^(62|88)\d+$/,
            visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
            mastercard: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
            amex: /^3[47][0-9]{13}$/,
            // diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
            // discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
            // jcb: /^(?:2131|1800|35\d{3})\d{11}$/
        }

        for(var key in re) {
            if(re[key].test(numberWihoutSpaces)) {
                return key
            }
        }
    }
    return 'none'
}

const allIcons = {
    // electron: Visa,
    // maestro: Visa,
    // dankort: Visa,
    // interpayment: Visa,
    // unionpay: Visa,
    visa: Visa,
    mastercard: Mastercard,
    amex: Amex,
    // diners: Visa,
    // discover: Visa,
    // jcb: Visa,
    none: ''
};


export const paymentHelper = {
    detectCardType,
    allIcons
}