import { toast } from 'react-toastify'
import { iosAppVersion, androidVersion } from './ApplicationVersions';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
    if (response.status === 204 || response.status === 205) {
        return null;
    }
    return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
// function checkStatus(response) {
//     if (response.status >= 200 && response.status < 300) {
//         return response;
//     }
//     if (response.status === 401) {
//         window.localStorage.removeItem('user');
//         window.location = '/login';
//     }
//     const error = new Error(response.statusText);
//     error.response = response;
//     throw response;
// }

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
    return fetch(url, options)
        .then(parseJSON);
}

export const serverUrl = '';

export function apiRequest(url, method, bodyParams = null, token = null, file = false) {
    let body = null;
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    if (token !== null) {
        headers.Authorization = 'Bearer ' + token;
    }
    if (bodyParams !== null) {
        body = JSON.stringify(bodyParams);
    }
    headers['platform'] = (window.cordova === undefined) ? "web" : window.cordova.platformId;
    if (window.cordova !== undefined) {
        headers['appversion'] = (window.cordova.platformId === 'ios') ? iosAppVersion : androidVersion;
    }

    if (file) {
        delete headers['Content-Type'];
        body = bodyParams;
    }
    return new Promise((resolve, reject) => {
        try {
            const response = fetch(serverUrl + '/api/' + url, {
                method,
                headers,
                body,
            });
            response.then(data => {
                if (
                  data.status === 500 ||
                  data.status === 404
                ) {
                    toast.error(serverUrl + '/api/' + url + ": return " + data.status + " with data " + JSON.stringify(bodyParams));
                }
                if (data.status === 401) {
                    window.localStorage.removeItem('user');
                    if (window.location.pathname.indexOf('/login') !== 0) {
                        window.location.href = '/login'
                    }
                }
                resolve(data);
            }).catch(error => {
                toast.error(error + " " + serverUrl + '/api/' + url + " with data " + JSON.stringify(bodyParams));
                reject(error)
            })
        }
        catch (e) {
            toast.error(e + " " + serverUrl + '/api/' + url + " with data " + JSON.stringify(bodyParams));
            reject();
        }
    })
}
