import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    withRouter,
    Route,
    Link
} from "react-router-dom";
import {apiRequest} from "@services/Request";
import BookingList from './list'
import BookingView from './booking'
import CircularProgress from '@material-ui/core/CircularProgress';
import { chatHelper } from '@helpers/chat';
import Chat from '../chat/index';
import {connect} from "react-redux";
import {getChannels} from "@reducers/chat";
import {isChannelLoading} from "@reducers/chat";
import { chatActions } from '@actions/chat';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DietPopup from './dietPopup'
import Portal from '@material-ui/core/Portal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class BookingsHistory extends React.Component{

    state = {
        future: [],
        previous: [],
        isLoading: true,
        openChat: false,
        bookingHash: '',
        bookingName: '',
        bookingDate: '',
        supplier_avatar: '',
        chatkitty_channel_id: 0,
        x_minutes_to_approve: 5,
        newMessages: {},
        bookingIdForChat: 0,
        supplierIdForChat: 0,
    }

    getBookingHistory = () => {
        const { auth, kitty, dispatch, chatSession } = this.props;
        apiRequest('booking/bookings', 'GET' , null, auth.api_key).then(request => {
            if (request.status === 200) {
                request.json().then(async data => {
                    this.setState({
                        future: data.records.future,
                        previous: data.records.previous,
                        x_minutes_to_approve: data.x_minutes_to_approve,

                        isLoading: false
                    });
                    if(chatSession.succeeded){
                        chatHelper.prepareChatChannels(auth, dispatch, kitty, data.records.future.concat(data.records.previous), 'supplier');
                    }
                    else{
                        dispatch(chatActions.errorChatKitty());
                    }

                })

            }
        })
    }

    componentDidMount() {
        this.getBookingHistory();
    }

    handleCloseChat = () => {
        this.setState({
            openChat: false,
            bookingIdForChat: 0,
            supplierIdForChat: 0,
            bookingHash: '',
            supplier_avatar: '',
            bookingName: '',
            chatkitty_channel_id: 0,
            bookingDate: ''
        })
    }

    openChat = (booking) => {
        const { dispatch, chatsChannels } = this.props;
        this.setState({
            openChat: true,
            bookingHash: booking.hash,
            supplier_avatar: '/' + booking.supplier.image,
            bookingIdForChat: booking.id,
            supplierIdForChat: booking.supplier.id,
            bookingName: booking.supplier.name,
            bookingDate: booking.date,
            chatkitty_channel_id: booking.chatkitty_channel_id
        })

        if (chatsChannels[booking.hash] !== undefined) {
            dispatch(chatActions.readMessages(chatsChannels[booking.hash].countUnreadMesages, booking.hash));
        }

    }



    render() {
        const { isLoading, future, previous, supplier_avatar,
                supplierIdForChat, bookingIdForChat, bookingHash, x_minutes_to_approve,
                bookingName, chatkitty_channel_id, bookingDate, openChat } = this.state;
        const { loadingChats, chatsChannels, match } = this.props;
        if (isLoading) {
            return <div className="child-by-center">
                <CircularProgress />
            </div>
        }
        if (loadingChats) {
            return <div className="child-by-center">
                <CircularProgress />
            </div>
        }
        return (
            <>
                <div className="child-by-center" style={{position: 'relative',height: 50, fontSize:18, fontWeight: 600}}>
                    {match.params.id !== undefined && <>
                            <ArrowBackIosIcon style={{position:'absolute', left:"20px"}} onClick={() => this.props.history.push('/booking-list')} />
                            <>Booking</>
                        </>}
                    {match.params.id === undefined && <>Upcoming</>}
                </div>
                <Switch>
                    <Route exact path="/booking-list" component={() => <BookingList x_minutes_to_approve={x_minutes_to_approve} getBookingHistory={this.getBookingHistory} openChat={this.openChat} future={future} previous={previous} chatsChannels={chatsChannels} {...this.props}  />} />
                    <Route exact path="/booking-list/:id" component={() => <BookingView openChat={this.openChat} allBookings={future.concat(previous)} {...this.props} />} />
                </Switch>
                <DietPopup auth={this.props.auth} />
                {openChat && bookingHash && <Portal>
                    <Dialog TransitionComponent={Transition} keepMounted fullScreen open={openChat} onClose={this.handleCloseChat}>
                        <div className="searchHeader">
                            <ArrowBackIosIcon onClick={this.handleCloseChat} />
                            <span style={{position: 'absolute',left: 0, right: 0, textAlign: 'center', width: '80%',margin: 'auto'}}>Messages</span>
                            <div></div>
                        </div>
                        <div style={{height: '100%'}}>
                            {(bookingHash && bookingName && bookingDate) && <Chat channel={chatsChannels[bookingHash]} fromGuest={true} kitty={this.props.kitty} chatSession={this.props.chatSession} userId={supplierIdForChat} bookingId={bookingIdForChat} chatkitty_channel_id={chatkitty_channel_id} auth={this.props.auth} supplier_avatar={supplier_avatar} hash={bookingHash} bookingName={bookingName} bookingDate={bookingDate} />}
                        </div>
                    </Dialog>
                </Portal>}
            </>


        )
    }
}

function mapStateToProps(state) {
    return {
        chatsChannels: getChannels(state),
        loadingChats: isChannelLoading(state),
    }
}

export default connect(mapStateToProps)(withRouter(BookingsHistory));