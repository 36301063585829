import { userConstants } from '@constants/index';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loading: true, api_key: user.api_key, loggedIn: false, user: {} } :  { loading: true, api_key: '', loggedIn: false, user: {} };

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
        case userConstants.LOGIN_SUCCESS:
            return {...{
                loading: false,
                loggedIn: true,
                api_key: action.user.access_token,
                user: {...action.user.user}
            }};
        case userConstants.LOGIN_FAILURE:
            return {
                error: action.error
            };
        case userConstants.LOGOUT:
            return {};
        default:
            return state
    }
}

export function getAuthData(state) {
    return state.authentication;
}

export function getUser(state) {
    return state.authentication.user;
}