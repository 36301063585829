import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Csv from '@images/csv.svg';
import Mc from '@images/mc.svg';
import Ae from '@images/ae.svg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import TermsAndConditions from '@semper/TermsAndConditions';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiRequest } from '@services/Request';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Portal from '@material-ui/core/Portal';
import { paymentHelper } from '@helpers/payment';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class EntrancePayment extends React.Component{
    state = {
        openTooltip: false,
        terms: false,
        iframeLoaded:false,
        loadingPayment:false,
        countries: [],
        open3dsecureDialog: false,
        isCompany: false,
        linkFor3Dsecure: '',
        openTermsAndConditions: false,
        paymentCard: {
            credit_card_number: '',
            credit_card_name: '',
            credit_card_expiration_month: '',
            credit_card_expiration_year: '',
            credit_card_expiration_date: '',
            save_my_card: 0,
            credit_card_id: "0",
            credit_card_cvv: '',
            credit_card_nickname: '',
        }
    }


    componentDidMount() {
        const { auth } = this.props;
        const handlerIframeEvent = event => {
            if (event.data.type === 'loaded') {
                this.setState({
                    iframeLoaded: true
                })
            }
            if (event.data.type === 'payment-done') {
                window.localStorage.removeItem('dataWasSendedToFrame');
                this.props.nextStep();
            }
            else if(event.data.type === 'payment-error'){
                window.localStorage.removeItem('dataWasSendedToFrame');
                this.setState({
                    loadingPayment: false,
                })

                let frame = window.frames.iframe_3d;
                frame.postMessage(
                    {
                        error_text: event.data.error_text,
                        type: 'payment-error-update-message'
                    },
                    '*'
                )
            }
            else if(event.data.type === 'payment-transfer-data-request'){
                if (window.localStorage.getItem('dataWasSendedToFrame') === null) {
                    let frame = window.frames.iframe_3d;
                    window.localStorage.setItem('dataWasSendedToFrame', 1);
                    frame.postMessage(
                        {
                            user: null,
                            user_id: null,
                            city_ids: this.props.cities,
                            product_id: this.props.product_id,
                            api_key: auth.api_key,
                            type: 'payment-transfer-data-response'
                        },
                        '*'
                    )
                }

            }

        }

        apiRequest('user/address-details/0', 'GET', null, auth.api_key).then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    this.setState({
                        countries: data.extra_data.countries
                    })
                })
            }
        })

        window.addEventListener("message", handlerIframeEvent);
    }

    onChange = (e) => {
        let paymentCard = { ...this.state.paymentCard };
        paymentCard[e.target.name] = e.target.value;
        this.setState({
            paymentCard
        })
    }

    onChangeCartNumber = (e) => {
        let paymentCard = { ...this.state.paymentCard };
        let value = e.target.value.replace(/ /g,'');
        let last = e.target.value[e.target.value.length - 1];
        if (last !== undefined && !isNaN(last)) {
            let resultArray = value.match(/.{1,4}/g);
            if (resultArray.length <= 4) {
                paymentCard['credit_card_number'] = resultArray.join(' ');
                this.setState({
                    paymentCard
                })
            }
        }
        if (last === undefined) {
            paymentCard['credit_card_number'] = '';
            this.setState({
                paymentCard
            })
        }

    }

    onChangeDate = (event) => {
        var paymentCard = { ...this.state.paymentCard };
        let newString = event.target.value;
        if (newString.split('/').length && typeof newString.split('/')[1] != "undefined" && newString.split('/')[1].length === 4) {
            paymentCard.credit_card_expiration_date = newString.split('/')[0] + '/' + newString.split('/')[1].split('')[2] + newString.split('/')[1].split('')[3];
        }
        else{
            let lastChar = newString.split('')[newString.split('').length - 1];
            if (event.target.value.length < paymentCard.credit_card_expiration_date.length) {
                if (lastChar === '/') {
                    let arrayText = newString.split('');
                    arrayText.splice(-1, 1);
                    paymentCard.credit_card_expiration_date = arrayText.join('');
                }
                else{
                    paymentCard.credit_card_expiration_date = newString;
                }

            }
            else{
                if (paymentCard.credit_card_expiration_date.length < 5 && !isNaN(parseInt(lastChar))) {
                    if (paymentCard.credit_card_expiration_date.length === 0 && ['0','1'].indexOf(lastChar) >= 0) {
                        paymentCard.credit_card_expiration_date += lastChar;
                    }
                    else if (paymentCard.credit_card_expiration_date.length === 1 &&  paymentCard.credit_card_expiration_date.split('')[0] === '1' && ['0', '1', '2'].indexOf(lastChar) >= 0) {
                        paymentCard.credit_card_expiration_date += lastChar + '/';
                    }
                    else if (paymentCard.credit_card_expiration_date.length === 1 && paymentCard.credit_card_expiration_date.split('')[0] === '0' && Array.from(Array(10).keys()).indexOf(parseInt(lastChar)) >= 0) {
                        paymentCard.credit_card_expiration_date += lastChar + '/';
                    }
                    else if (paymentCard.credit_card_expiration_date.length === 2  && ['0','1', '2', '3'].indexOf(lastChar) >= 0 ) {
                        paymentCard.credit_card_expiration_date +=  '/' + lastChar;
                    }
                    else if (paymentCard.credit_card_expiration_date.length === 3  && ['0','1', '2', '3'].indexOf(lastChar) >= 0 ) {
                        paymentCard.credit_card_expiration_date += lastChar;
                    }
                    else if (paymentCard.credit_card_expiration_date.length === 4 && Array.from(Array(10).keys()).indexOf(parseInt(lastChar)) >= 0 ) {
                        paymentCard.credit_card_expiration_date += lastChar;
                    }
                }
            }
        }

        this.setState({
            paymentCard
        })
    }

    closeTermsAndConditions = () => {
        this.setState({
            openTermsAndConditions: false
        })
    }

    openTermAndConditions = (e) => {
        this.setState({
            openTermsAndConditions: true,
            terms: !this.state.terms,
        })
    }

    close3dsecureDialog = () => {
        window.localStorage.removeItem('dataWasSendedToFrame');
        this.setState({
            open3dsecureDialog: false,
            iframeLoaded: false,
            linkFor3Dsecure: ''
        })
    }

    handleTooltipClose = () => {
        this.setState({openTooltip:false})
    }

    showTooltip = () => {
        this.setState({openTooltip:true})
    }


    changeTerms = (e) => {
        this.setState({
            terms: e.target.checked
        })
    }

    onChangeCompany = (e) => {
        var paymentCard = { ...this.state.paymentCard };
        if (!e.target.checked) {
            paymentCard['company'] = '';
            paymentCard['tax_number'] = '';
        }
        this.setState({
            isCompany: e.target.checked,
            paymentCard: paymentCard
        })
    }

    pay = () => {
        var paymentCard = {...this.state.paymentCard};
        const { auth } = this.props;
        this.setState({
            loadingPayment: true
        })
        paymentCard['credit_card_expiration_month'] = parseInt(paymentCard.credit_card_expiration_date.split('/')[0]);
        paymentCard['credit_card_expiration_year'] = '20' + parseInt(paymentCard.credit_card_expiration_date.split('/')[1]);
        paymentCard['code'] = this.props.code;
        let toSend = {...paymentCard}
        toSend['code'] = this.props.code;
        toSend['credit_card_number'] = toSend['credit_card_number'].replace(/ /g,'');
        // if (paymentCard.credit_card_id === '0') {
        toSend['save_my_card'] = 1;
        // }
        // else {
        //     delete paymentCard.credit_card_expiration_month;
        //     delete paymentCard.credit_card_expiration_year;
        //     delete paymentCard.credit_card_cvv;
        // }

        apiRequest('payment-entrance/pay', 'POST', toSend, auth.api_key).then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    if (data.show_popup) {
                        this.setState({
                            open3dsecureDialog: true,
                            linkFor3Dsecure: data.redirect_url,
                        })
                    }
                    else{
                        if (data.data.create_card_message != null) {
                            this.setState({
                                error: data.data.create_card_message,
                                loadingPayment: false
                            })
                        }
                        else if (data.data.payment_status_fees === false && data.data.payment_status_guest === false) {
                            this.setState({
                                error: data.data.payment_message_guest,
                                loadingPayment: false
                            })
                        }
                        else if (data.data.payment_status_fees === false && data.data.payment_status_guest === true) {
                            this.setState({
                                error: data.data.payment_message_fees,
                                loadingPayment: false
                            })
                        }
                        else if (data.data.create_card_message == null) {

                            this.setState({
                                loadingPayment: false,
                                open3dsecureDialog: false,
                                linkFor3Dsecure: '',
                            });
                            // this.props.closePaymentDialog();
                            // this.props.switchToPremium(this.props.product_id);
                        }
                    }
                })
            }
            else{
                if (response.status === 401) {
                    // this.setState({error: 'Incorrect email or password'})
                }
                else if (response.status === 422) {
                    response.json().then(data => {
                        if (typeof data.messages != "undefined") {
                            this.setState({
                                error: data.messages.join("\n"),
                                loadingPayment: false
                            })
                        }
                        else if(typeof data.message != "undefined"){
                            this.setState({
                                error: data.message,
                                loadingPayment: false
                            })
                        }
                        else if (data.data.payment_message_guest) {
                            this.setState({
                                error: data.data.payment_message_guest,
                                loadingPayment: false
                            })
                        }
                        else if(data.data.create_card_message != null) {
                            this.setState({
                                error: data.data.create_card_message,
                                loadingPayment: false
                            })
                        }
                        else {
                            this.setState({
                                error: 'Something went wrong, please try later',
                                loadingPayment: false
                            })
                        }
                    })
                }
            }
        })
    }

    validatePay = () => {
        const { paymentCard, terms, addNewCard, loadingPayment } = this.state;
        return !(terms && paymentCard.credit_card_cvv && paymentCard.credit_card_number && paymentCard.credit_card_name && paymentCard.credit_card_expiration_date ) || loadingPayment;
    }

    render() {
        const { paymentCard, isCompany, countries, loadingPayment, openTooltip,
            openTermsAndConditions, terms, linkFor3Dsecure,
            open3dsecureDialog, iframeLoaded, error } = this.state;
        return (
            <div className="wrapper-pay-entrance-fees-form">
                <div className="title">
                    Payment Settings
                </div>
                <div className="payment-wrapper-entrance-fees">
                    <div className="price-line">
                        <div className="label">
                            Entrance fee
                        </div>
                        <div className="price-wrapper">
                            {this.props.referral_was_accepted && <div className="old-price">
                                {this.props.entrance_fees} €
                            </div>}
                            <div className="price">
                                {this.props.referral_was_accepted === true && <>
                                    {this.props.discount_entrance_fees === 0 && <>Free</>}
                                    {this.props.discount_entrance_fees !== 0 && <>{ (this.props.discount_entrance_fees).toFixed(2).replace('.00','')} €</>}
                                </>}
                                {this.props.referral_was_accepted === false && <>
                                    {this.props.entrance_fees} €
                                </>}
                            </div>
                        </div>
                    </div>
                    <Grid spacing={0}>
                        <Grid item xs={12} spacing={0}>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xs={12} spacing={1}>
                                    <OutlinedInput
                                        name="credit_card_name"
                                        id="credit_card_name"
                                        placeholder="Name"
                                        onChange={this.onChange}
                                        value={paymentCard.credit_card_name}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} spacing={1}>
                                    <OutlinedInput
                                        name="credit_card_number"
                                        id="credit_card_number"
                                        placeholder="Card number"
                                        inputProps={{inputmode: "numeric"}}
                                        onChange={this.onChangeCartNumber}
                                        value={paymentCard.credit_card_number}
                                        variant="outlined"
                                        fullWidth
                                        endAdornment={
                                            <>
                                                {paymentHelper.allIcons[paymentHelper.detectCardType(paymentCard.credit_card_number)] ? <img src={paymentHelper.allIcons[paymentHelper.detectCardType(paymentCard.credit_card_number)]} alt=""/> : ''}
                                            </>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6} spacing={1}>
                                    <OutlinedInput
                                        name="credit_card_expiration_date"
                                        id="credit_card_expiration_date"
                                        placeholder="Expiration date"
                                        onChange={this.onChangeDate}
                                        inputProps={{inputmode: "numeric"}}
                                        value={paymentCard.credit_card_expiration_date}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} spacing={1}>
                                    <OutlinedInput
                                        name="credit_card_cvv"
                                        id="credit_card_cvv"
                                        placeholder="CVV code"
                                        onChange={this.onChange}
                                        inputProps={{inputmode: "numeric"}}
                                        value={paymentCard.credit_card_cvv}
                                        variant="outlined"
                                        endAdornment={
                                            <ClickAwayListener onClickAway={this.handleTooltipClose}>
                                                <div>
                                                    <Tooltip
                                                        PopperProps={{
                                                            disablePortal: true,
                                                        }}
                                                        onClose={this.handleTooltipClose}
                                                        open={openTooltip}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        arrow
                                                        title={
                                                            <span className="child-by-center">
                                                            <img src={Csv}/>
                                                            <span>You can find your code at the back of your credit card above your signature, or on the front above your account number</span>
                                                        </span>
                                                        }
                                                        placement="top-start"
                                                    >
                                                        <InfoIcon onClick={this.showTooltip} />
                                                    </Tooltip>
                                                </div>
                                            </ClickAwayListener>


                                        }
                                        fullWidth
                                    />
                                </Grid>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={terms}
                                            onChange={this.changeTerms}
                                            name="terms_and_condition"
                                        />
                                    }
                                    label={<span>I accept <a style={{textDecoration: 'underline'}} onClick={this.openTermAndConditions}>conditions</a> of the partner and of application</span>}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <TermsAndConditions closeTermsAndConditions={this.closeTermsAndConditions} open={openTermsAndConditions} />
                {linkFor3Dsecure && <>
                    <Dialog TransitionComponent={Transition} keepMounted fullScreen open={open3dsecureDialog} onClose={this.close3dsecureDialog}>
                        <div className="searchHeader">
                            <span style={{position: 'absolute',left: 0, right: 0, textAlign: 'center', width: '80%',margin: 'auto'}}>3D secure</span>
                            <CloseIcon onClick={this.close3dsecureDialog} />
                        </div>
                        {!iframeLoaded && <div className="child-by-center"><CircularProgress size={29} /></div>}
                        <iframe name="iframe_3d" src={linkFor3Dsecure} style={{width: '100%', height: '100%'}} frameborder="0">
                        </iframe>
                    </Dialog>
                </>}
                <div className="flex-by-center">
                    <Button variant="contained" onClick={this.pay} disabled={this.validatePay()}>
                        {!loadingPayment && <>Confirm and pay</>}
                        {!!loadingPayment && <CircularProgress size={29} style={{color:'white'}} />}
                    </Button>
                </div>
                <Portal>
                    <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                        <Alert severity="error">
                            {error}
                        </Alert>
                    </Snackbar>
                </Portal>
            </div>
        );
    }
}

export default EntrancePayment;