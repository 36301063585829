import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
class Loader extends React.Component{
    render() {
        return (
            <CircularProgress style={{top: "0",right: 0, bottom: 0, left: 0, position: "absolute", margin: "auto", color: (this.props.color) ? this.props.color : '#2D3340'}} />
        )
    }

}
export default Loader;