export function generateBookingConditions(dateString, condition) {
    console.log(dateString);
    console.log(condition);
    const no_fees = new Date(dateString).setHours(new Date(dateString).getHours() - condition.min_hours_cancel_no_fees);
    const fees50 = new Date(dateString).setHours(new Date(dateString).getHours() - condition.min_hours_cancel_50percent_fees);

    const yeno_fees = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(no_fees);
    const mono_fees = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(no_fees);
    const dano_fees = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(no_fees);
    const hoursno_fees = new Intl.DateTimeFormat('ca', { hour: '2-digit', minute: '2-digit'}).format(no_fees);

    const yefees50 = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(fees50);
    const mofees50 = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(fees50);
    const dafees50 = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(fees50);
    const timefees50 = new Intl.DateTimeFormat('ca', { hour: '2-digit', minute: '2-digit'}).format(fees50);

    return {
        no_fees: `${yeno_fees}/${mono_fees}/${dano_fees} ${hoursno_fees}`,
        fees50: `${yefees50}/${mofees50}/${dafees50} ${timefees50}`,
    };
}