import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import { apiRequest } from '@services/Request';
import Info from '@images/info.svg';
import Cross from '@images/cross.svg';
import Slide from '@material-ui/core/Slide';
import moment from 'moment'
import './calendar.css'
import Portal from '@material-ui/core/Portal';
import CalendarArrow from '@images/CalendarArrow.svg';
const localizer = momentLocalizer(moment);


const calendarOption = [
    'lunch',
    'dinner'
]
let disable_click_flag = false;
let swipetimer = 0;;


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const DateCellWrapper = ({dates, onSelectSlot, children, value}) => {
    let extraStyles = 'white';
    let textColor = 'rgba(0, 0, 0, 0.87)';
    let border =  '0.5px solid white';
    let hasAvailableDate = 0;
    let boxShadow = 'none';
    let element = dates.find((e) => {
        return new Date(e.date).setHours(0)  === value.getTime();
    });
    if(typeof element != "undefined" && (element.lunch !== null || element.dinner !== null)) {
        let today = new Date();
        extraStyles = '#F7F7F7';
        hasAvailableDate = 1;
        border =  '0.5px solid #F7F7F7';
        if (
            element.date.getFullYear() === today.getFullYear() &&
            element.date.getMonth() === today.getMonth() &&
            element.date.getDate() === today.getDate()
        ) {
            extraStyles = '#E6E6E6';
            border =  '0.5px solid black'
        }
    }

    if (new Date().setHours(0,0,0,0) > value.getTime()) {
        textColor = 'rgba(0, 0, 0, 0.40)';
    }
    return React.cloneElement(React.Children.only(children), {
        onTouchEnd: (event) => onSelectSlot(value, event, hasAvailableDate),
        'data-lunch': (typeof element != "undefined" && element.lunch !== null) ? element.lunch.replace('.00','') + " " + element.currency : '',
        'data-dinner': (typeof element != "undefined" && element.dinner !== null) ? element.dinner.replace('.00','') + " " + element.currency : '',
        style: {
            ...children.style,
            background: extraStyles,
            borderRadius: 11,
            color: textColor,
            boxShadow: boxShadow,
            zIndex: 11,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            border: border
        },
    },value.getDate());

};

class SupplierCalendar extends React.Component{
    state = {
        loadingCalendar: false,
        supplierCurrency: 'EUR',
        supplierId: 0,
        currentOption: 0,
        currentMonth: [],
        nextMonth: [],
        days: [],
        loading: true,
        openPriceInfo: false,
        dataFromApi: [],
        selectedDay: new Date(),
    }

    constructor(props){
        super(props);
        if (window.localStorage.getItem('calendar_option') !== null) {
            this.state['currentOption'] = parseInt(window.localStorage.getItem('calendar_option'))
        }
    }
    slotInfo = (date, event, hasAvailableDate) => {
        const { days } = this.state;
        let selectedTime = null;
        if (typeof date.start != "undefined") {
            selectedTime = date.start;
        }
        else{
            selectedTime = date;
        }
        if (hasAvailableDate === undefined) {
            days.forEach(day => {
                if (
                    day.date.getFullYear() === selectedTime.getFullYear() &&
                    day.date.getMonth() === selectedTime.getMonth() &&
                    day.date.getDate() === selectedTime.getDate()
                ) {
                    if (day.lunch !== null || day.dinner !== null) {
                        hasAvailableDate = 1;
                    }

                }
            })
        }

        let isPastDate = true;
        if (new Date().setHours(0, 0, 0, 0) <= selectedTime.getTime()) {
            isPastDate = false;
        }
        if (
            !disable_click_flag &&
            hasAvailableDate &&
            !isPastDate
        ) {
            const { supplierId } = this.props;


            if (typeof selectedTime != "undefined") {
                if (typeof date.start != "undefined") {
                    let month = ('0' + (date.start.getMonth() + 1)).slice(-2);
                    this.props.goToLink('/booking/' + supplierId + '/' + date.start.getFullYear() + "-" + month + '-' + ('0' + date.start.getDate()).slice(-2));
                }
                else{
                    let month = ('0' + (date.getMonth() + 1)).slice(-2);
                    this.props.goToLink('/booking/' + supplierId + '/' + date.getFullYear() + "-" + month + '-' + ('0' + date.getDate()).slice(-2));
                }
            }
        }
    }

    changeOption = (option) => {
        window.localStorage.setItem('calendar_option', option);
        this.setState({
            currentOption: option
        })
    }

    fillCalendar = (data) => {
        const { dataFromApi } = this.state;
        let days = [];
        data.forEach(day => {
            days.push({
                date: new Date(day.start),
                currency: day.currency,
                dinner: day.prices.dinner,
                lunch: day.prices.lunch,
            })
        })

        this.setState({
            days: days,
            // dataFromApi: data/**/FromApi.concat(data)
        })

        if (days.length) {
            this.setState({
                supplierCurrency: days[days.length - 1].currency
            })
        }

    }

    loadDataByMonth = async (date) => {
        const {supplierId, auth, hash, isAnonymous} = this.props;
        return apiRequest('search-supplier/calendar', 'POST', {
            supplier_id: supplierId,
            year: date.getFullYear(),
            month: (date.getMonth() + 1)
        }, auth.api_key);
    }

    componentDidMount = async () => {
        const { supplierId, auth, hash, isAnonymous} = this.props;
        if (
            (supplierId && auth && auth.api_key) ||
            (supplierId && hash && isAnonymous)
        ) {
            let newArray = [];
            let currentDate = new Date();
            let loadForMonthMore = new Date(currentDate);
            this.setState({
                loading:true
            })
            let promLoad = Promise.all([
                this.loadDataByMonth(currentDate),
            ]);
            promLoad.then(async responces => {
                let neededResponces = [];
                responces.forEach(
                    async (response, i) => {
                        if (response.status === 200) {
                            neededResponces.push(response.json());
                        }
                        else{
                            this.setState({
                                error:true
                            })
                        }
                        let jsons = await Promise.all(neededResponces);
                        jsons.forEach(json => {
                            newArray = newArray.concat(json.data);
                        });
                        this.setState({
                            loading:false,
                        });
                        this.fillCalendar(newArray);

                    }
                );
            })
        }

        setTimeout(e => {
            document.getElementsByClassName('MuiDialog-paperScrollPaper')[0].addEventListener('scroll', function(e) {
                disable_click_flag = true;
                if (swipetimer) {
                    clearTimeout(swipetimer);
                }
                swipetimer = setTimeout(() => {
                    disable_click_flag = false;
                }, 200)
            });
        }, 100) //todo fix it

    }



    changeMonth = async (isBack) => {
        const { selectedDay } = this.state;
        let newArray = [];
        let newSelectedDate;
        if (isBack) {
            newSelectedDate = new Date(selectedDay.getFullYear(), selectedDay.getMonth()-1, 1);
            document.querySelector("div.rbc-calendar > div.rbc-toolbar > span:nth-child(1) > button:nth-child(2)").click(); //TODO fix it
        }
        else{
            newSelectedDate = new Date(selectedDay.getFullYear(), selectedDay.getMonth()+1, 1);
            document.querySelector("div.rbc-calendar > div.rbc-toolbar > span:nth-child(1) > button:nth-child(3)").click(); //TODO fix it
        }
        let promLoad = Promise.all([
            this.loadDataByMonth(newSelectedDate),
        ]);
        this.setState({
            loading:true,
            selectedDay: newSelectedDate
        })
        promLoad.then(async responces => {
            let neededResponces = [];
            responces.forEach(
                async (response, i) => {
                    if (response.status === 200) {
                        neededResponces.push(response.json());
                    }
                    else{
                        this.setState({
                            error:true
                        })
                    }
                    let jsons = await Promise.all(neededResponces);
                    jsons.forEach(json => {
                        newArray = newArray.concat(json.data);
                    });

                    this.setState({
                        loading:false,
                    });
                    this.fillCalendar(newArray);

                }
            );
        })
    }

    handleClosePriceInfo = () => {
        this.setState({
            openPriceInfo: false
        })
    }

    handleOpenPriceInfo = () => {
        this.setState({
            openPriceInfo: true
        })
    }

    render() {
        let currentDate = new Date();
        const { loadingCalendar, selectedDay, supplierCurrency, openPriceInfo, currentOption, days } = this.state;
        const { openCalendar, supplierName } = this.props;
        let lastDayOfMonth = new Date(selectedDay.getFullYear(), selectedDay.getMonth()+1, 0);

        return (<>
            <Dialog TransitionComponent={Transition} keepMounted fullScreen open={openCalendar} onClose={this.props.closeCalendar}>
                <div className="searchHeader">
                    <div></div>
                    <span style={{fontSize: 16, fontWeight: 600, position: 'absolute',left: 0, right: 0, textAlign: 'center', width: '80%',margin: 'auto'}}>Calendar</span>
                    <CloseIcon onClick={this.props.closeCalendar} />
                </div>
                <div className="supplier-name-on-calendar">{supplierName}</div>
                <div className="wrapper-calendar-flex-box">
                    <div className="switcher-option">
                        <div className={currentOption === 0 ? 'active': ''} onClick={e => this.changeOption(0)}>
                            Lunch
                        </div>
                        <div className={currentOption === 1 ? 'active': ''} onClick={e => this.changeOption(1)}>
                            Dinner
                        </div>
                        <div className="background-switcher-option-animation">
                            <div className={currentOption > 0 ? 'active right-side' : 'active'}></div>
                        </div>
                    </div>
                    <div className={'wrapper-calendar ' + calendarOption[currentOption]}>
                        {loadingCalendar && <div style={{textAlign: 'center'}}>
                            <CircularProgress size={29} />
                        </div>}
                        {!loadingCalendar && <>
                            <Calendar
                                localizer={localizer}
                                events={days}
                                startAccessor="start"
                                selectable
                                resizable
                                showMultiDayTimes
                                endAccessor="end"
                                onSelectSlot={this.slotInfo}
                                components={{
                                    dateCellWrapper: (props) => (
                                        <DateCellWrapper onSelectSlot={this.slotInfo} dates={days} {...props} />
                                    ),
                                }}
                                style={{ height:455 }}
                                views={['month']}
                            />
                            <div className="wrapper_calendar">
                                <div className="actions">
                                    <div className="back" onClick={(e) => this.changeMonth(true)}>
                                        {'<'}
                                    </div>

                                    <div className="current-date">
                                        <div>{selectedDay.getFullYear()}/{selectedDay.getMonth() + 1}/{selectedDay.getDate()}</div>
                                        <div className="arrow"><img src={CalendarArrow} /></div>
                                        <div>{selectedDay.getFullYear()}/{selectedDay.getMonth() + 1}/{lastDayOfMonth.getDate()}</div>
                                    </div>
                                    <div className="next" onClick={(e) => this.changeMonth(false)}>
                                        {'>'}
                                    </div>
                                </div>
                            </div>
                            <div onClick={this.handleOpenPriceInfo} className="prices-info-on-the-buttom">
                                <u>Prices details</u>
                                <img src={Info} alt=""/>
                            </div>
                        </>}
                    </div>
                </div>
            </Dialog>
            <Portal>
                <Dialog keepMounted
                        fullScreen
                        open={openPriceInfo}
                        style={{inset: "calc(100% - 377px) 0px 0px",borderRadius: "3% 3% 0 0", overflow: "hidden"}}
                        onClose={this.handleClosePriceInfo}
                >
                    <div className="price-info-wrapper">
                        <img className="close-price-info-button" onClick={this.handleClosePriceInfo} src={Cross} alt=""/>
                        <div className="title">Prices details : What will you pay ?</div>
                        <div className="content">
                            <p>Displayed prices are minimum per person that you guarantee to spend and pay directly at the restaurant.</p>
                            <p>Semper charges additionnal 10 {supplierCurrency} per person to pay during the booking.</p>
                            <p>Restaurant prices are different depending on restaurant type, dinner/lunch variations and booking date. The closer you get to the date of the reservation, the higher the price will be.</p>
                        </div>
                    </div>
                </Dialog>
            </Portal>
        </>)
    }
}

export default SupplierCalendar;