import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import { apiRequest } from '@services/Request';
import Slide from '@material-ui/core/Slide';
import moment from 'moment'
const localizer = momentLocalizer(moment);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const DateCellWrapper = ({dates, onSelectSlot, children, value}) => {
    let extraStyles = 'white';
    let boxShadow = 'none';
    let element = dates.find((e) => {
        return new Date(e.start).setHours(0)  === value.getTime();
    });
    if(typeof element != "undefined"){
        extraStyles = '#EDE6DD';
        boxShadow = '0px 4px 4px rgba(0, 0, 0, 0.15)'
    }
    return React.cloneElement(React.Children.only(children), {
        onTouchEnd: () => onSelectSlot(value),
        style: {
            ...children.style,
            background: extraStyles,
            borderRadius: 11,
            boxShadow: boxShadow,
            zIndex: 11,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold'
        },
    },value.getDate());

};

class SupplierCalendar extends React.Component{
    state = {
        loadingCalendar: false,
        supplierId: 0,
        currentMonth: [],
        nextMonth: [],
    }


    componentDidMount() {
        this.updateCalendar(this.props.supplierId);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.supplierId && !this.props.fromSupplierPage) {
            this.updateCalendar(nextProps.supplierId);
        }
    }

    updateCalendar = (supplierId) => {
        const { auth } = this.props;
        this.setState({
            loadingCalendar:true
        })
        let currentDate = new Date();
        if (supplierId) {
            apiRequest('search-supplier/calendar', 'POST', {supplier_id: supplierId, year: currentDate.getFullYear(), month: (currentDate.getMonth()+1)}, auth.api_key).then(responce => {
                if (responce.status == 200) {
                    responce.json().then(data => {
                        this.setState({
                            currentMonth: data.data,
                        });
                        let nextMouthDate = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 1);
                        apiRequest('search-supplier/calendar', 'POST', {supplier_id: supplierId, year: nextMouthDate.getFullYear(), month: (nextMouthDate.getMonth()+1)}, auth.api_key).then(responce => {
                            if (responce.status == 200) {
                                responce.json().then(data => {
                                    this.setState({
                                        nextMonth: data.data,
                                        loadingCalendar: false
                                    })

                                })
                            }
                        })
                    })
                }
            })
        }
    }

    slotInfo = (date) => {
        const { supplierId } = this.props;
        let { currentMonth, nextMonth } = this.state;
        let allMonth = currentMonth.concat(nextMonth);
        let selectedTime = null;
        if (typeof date.start != "undefined") {
            selectedTime = date.start.getTime()
        }
        else{
            selectedTime = date.getTime()
        }
        let element = allMonth.find((e) => {
            return new Date(e.start).setHours(0)  === selectedTime;
        });
        if (typeof element != "undefined") {
            if (typeof date.start != "undefined") {
                let month = ('0' + (date.start.getMonth() + 1)).slice(-2);
                this.props.goToLink('/booking/' + supplierId + '/' + date.start.getFullYear() + "-" + month + '-' + ('0' + date.start.getDate()).slice(-2));
            }
            else{
                let month = ('0' + (date.getMonth() + 1)).slice(-2);
                this.props.goToLink('/booking/' + supplierId + '/' + date.getFullYear() + "-" + month + '-' + ('0' + date.getDate()).slice(-2));
            }
        }
    }

    render() {
        let currentDate = new Date();
        const { loadingCalendar, currentMonth, nextMonth } = this.state;
        const { openCalendar, supplierName } = this.props;
        return (<>
            <Dialog TransitionComponent={Transition} keepMounted fullScreen open={openCalendar} onClose={this.props.closeCalendar}>
                <div className="searchHeader">
                    <div></div>
                    <span style={{fontSize: 16, fontWeight: 600, position: 'absolute',left: 0, right: 0, textAlign: 'center', width: '80%',margin: 'auto'}}>Calendar</span>
                    <CloseIcon onClick={this.props.closeCalendar} />
                </div>
                <div style={{minHeight: 71,display: 'flex', alignItems: 'center',justifyContent: 'center',fontSize:20,color: '#f7f7f7', background: 'rgb(45, 51, 64)'}}>{supplierName}</div>
                <div style={{padding: 5}}>
                    <br/>
                    {loadingCalendar && <div style={{textAlign: 'center'}}>
                        <CircularProgress size={29} />
                    </div>}
                    {!loadingCalendar && <>
                        <Calendar
                            localizer={localizer}
                            events={currentMonth}
                            startAccessor="start"
                            selectable
                            resizable
                            showMultiDayTimes
                            endAccessor="end"
                            onSelectSlot={this.slotInfo}
                            components={{
                                dateCellWrapper: (props) => (
                                    <DateCellWrapper onSelectSlot={this.slotInfo} dates={currentMonth} {...props} />
                                ),
                            }}
                            style={{ height:400 }}
                            views={['month']}
                        />
                        <div className="sep"></div>
                        <Calendar
                            localizer={localizer}
                            events={nextMonth}
                            startAccessor="start"
                            selectable
                            resizable
                            showMultiDayTimes
                            endAccessor="end"
                            onSelectSlot={this.slotInfo}
                            defaultDate={new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 1)}
                            components={{
                                dateCellWrapper: (props) => (
                                    <DateCellWrapper onSelectSlot={this.slotInfo} dates={nextMonth}  {...props} />
                                ),
                            }}
                            style={{ height:400 }}
                            views={['month']}
                        />
                    </>}
                </div>
            </Dialog>
        </>)
    }
}

export default SupplierCalendar;