import React from 'react';
import Search from './screens/search/index'
import NotFound from './NotFound'
import BottomNavigationWrapper from './BottomNavigationWrapper';
import Supplier from './screens/supplier/index';
import BookingsHistory from './screens/booking/index';
import Bookings from './screens/supplierInterface/bookings/index';
import SuppierCalendar from './screens/supplierInterface/calendar/index';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import WelcomeInfo from '@semper/WelcomeInfo';
import ChatKitty from 'chatkitty';
import Loader from '@semper/Loader';
import Profile from './screens/profile/index';
import { getAuthData } from '@reducers/authentication';
import { getCountUnreadMessages } from '@reducers/chat';
import { chatActions } from '@actions/chat';
import { chatHelper } from '@helpers/chat';
import PaymentRedirect from './screens/supplier/paymentRedirect'
import InviteForm from './screens/advisor/invite';
import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    withRouter,
    Route,
    Link
} from "react-router-dom";
import {connect} from "react-redux";
import {getChannels} from "@reducers/chat";
import PayEntranceFees from './screens/pay_entrance_fees/index'

const kitty = ChatKitty.getInstance(process.env.REACT_APP_CHATKITTY_API_KEY);
class GuestApp extends React.Component{
    state = {
        currentItem: 0,
        loading: true,
        chatSession: {succeeded: false},
        kitty: null,
        welcomeOpened: false
    }

    changeMenu = (event, newValue) => {
        this.setState({
            currentItem: newValue
        })
    }

    async componentDidMount() {
        const mainRouting = ['/page', '/profile', '/booking', '/booking-list'];
        const { auth, dispatch } = this.props;
        const index = mainRouting.findIndex(e => window.location.pathname.indexOf(e) >= 0 ) + 1;
        if (index > 0) {
            this.setState({
                currentItem: index
            })
        }

        if (localStorage.getItem('semper_first_login')) {
            this.setState({
                welcomeOpened: true
            });
            localStorage.removeItem('semper_first_login')
        }


        if (auth.user.user !== undefined) {
            let prefix = (auth.user.user.type !== 'supplier') ? 'guest' : 'supplier';
            let needId = (auth.user.user.type === 'supplier') ? auth.user.user.supplier_id : auth.user.user.id;
            let resultSession = await kitty.startSession({
                username: prefix + needId
            });

            if (resultSession.succeeded) {
                this.setState({
                    kitty: kitty,
                    chatSession: resultSession
                });

                kitty.onNotificationReceived(notification => {
                    dispatch(chatActions.newMessage(1, notification.channel.name))
                });

                let totalUnreadMessage = await kitty.getUnreadMessagesCount();
                if (totalUnreadMessage.succeeded) {
                    if (auth.user.user.type === 'supplier') {
                        chatHelper.loadCalendarUnreadMessages(auth, dispatch, kitty);
                    }
                    else{
                        dispatch(chatActions.loadTotalCount(totalUnreadMessage.count))
                    }
                }
            }
            else{
                console.log(resultSession);
            }
        }
        this.setState({
            loading: false
        })
    }


    closeWelcome = () => {
        this.setState({
            welcomeOpened: false
        })
    }

    openWelcome = () => {
        this.setState({
            welcomeOpened: true
        })
    }


    render() {
        const { auth } = this.props;
        const { currentItem, welcomeOpened, chatSession, kitty, countMessages, loading } = this.state;
        const { user } = auth.user;
        const pathname = this.props.location.pathname;

        if (loading) {
            return <Loader color={'#F7F7F7'} />
        }
        if ((pathname === '/validate-3dsecure-entrance' || pathname === '/validate-3dsecure-capture' || pathname === '/validate-3dsecure-guest' || pathname === '/validate-3dsecure-subscription' )) {
            return <div className="bg-white">
                <Switch>
                    <Route exact path="/validate-3dsecure-guest" component={() => <PaymentRedirect url="validate-3dsecure-guest" auth={auth} />} />
                    <Route exact path="/validate-3dsecure-capture" component={() => <PaymentRedirect url="validate-3dsecure-capture" auth={auth} />} />
                    <Route exact path="/validate-3dsecure-entrance" component={() => <PaymentRedirect url="validate-3dsecure-entrance" auth={auth} />} />
                    <Route exact path="/validate-3dsecure-subscription" component={() => <PaymentRedirect url="validate-3dsecure-subscription" auth={auth} />} />
                </Switch>
            </div>
        }

        if (auth.loading === false) {
            if (auth.user.user.type === 'guest' && auth.user.user.entrance_fees_paid === false) {
                return <PayEntranceFees auth={auth} />
            }
        }

        if (typeof user == "undefined") {
            return <Loader color={'#F7F7F7'} />
        }

        let wrapperClass = (pathname !== '/validate-3dsecure-capture' || pathname !== '/validate-3dsecure-guest' ) ? 'fullscreen with-bottom-navigation'  : 'fullscreen';
        return(
            <>
                <div className={wrapperClass}>
                    <div className="bg-white">
                        <Switch>
                            {(user.type === 'guest' || user.type === 'advisor')  && <Route exact path="/" component={() => <Search auth={auth} />} />}
                            {(user.type === 'guest' || user.type === 'advisor')  && <Route path="/city/:id" component={(props) => <Search {...props} auth={auth} />} />}
                            {user.type === 'supplier' && <Route exact path="/" component={() => <Bookings  kitty={kitty} chatSession={chatSession} auth={auth} />} />}
                            {(user.type === 'guest' || user.type === 'advisor') && <Route exact path="/booking/:id/:date" component={(props) => <Supplier {...props} auth={auth} />} />}
                            <Route exact path="/profile" component={() => <Profile closeWelcome={this.closeWelcome} openWelcome={this.openWelcome}  auth={auth} />} />
                            {(user.type === 'guest') && <Route exact path="/booking-list" component={() => <BookingsHistory  kitty={kitty} chatSession={chatSession} auth={auth} />} />}
                            {(user.type === 'guest') && <Route exact path="/booking-list/:id" component={(props) => <BookingsHistory {...props}  kitty={kitty} chatSession={chatSession} auth={auth} />} />}
                            {( user.type === 'advisor' || user.type === 'guest') && <Route exact path="/invite" component={() => <InviteForm auth={auth} />} />}
                            {user.type === 'supplier' && <Route exact path="/calendar" component={() => <SuppierCalendar  kitty={kitty} chatSession={chatSession} auth={auth} />} />}
                            <Route component={NotFound} />
                        </Switch>
                    </div>
                </div>

                {(pathname !== '/validate-3dsecure-capture' || pathname !== '/validate-3dsecure-guest' ) && <BottomNavigationWrapper
                    value={currentItem}
                    onChange={this.changeMenu}
                    user={user}
                    auth={auth}
                    showLabels
                />}
                <WelcomeInfo
                    auth={auth}
                    closeWelcome={this.closeWelcome}
                    open={welcomeOpened}
                />
            </>

        )
    }
}

function mapStateToProps(state) {
    return {
        auth: getAuthData(state)
    }
}

export default connect(mapStateToProps)(withRouter(GuestApp));