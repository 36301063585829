import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Select from '@material-ui/core/Select';
import SupplierCalendar from '@semper/SupplierCalendar';
import CloseIcon from '@material-ui/icons/Close';
import { serverUrl } from '@services/Request';
const localizer = momentLocalizer(moment);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class SearchResults extends React.Component{

    state = {
        openCalendar: false,
        openedDescriptions: [],
        selectItem: {},
    }


    openCalendar = (item) => {
        const { auth } = this.props;
        this.setState({
            selectItem: item,
            openCalendar: true,
            loadingCalendar: true,
        });
    }
    closeCalendar = () => {
        this.setState({
            openCalendar: false
        })
    }

    toggleShowDescription = (i) => {
        let openedDescriptions = [...this.state.openedDescriptions];
        if (openedDescriptions.indexOf(i) >= 0) {
            openedDescriptions.splice(openedDescriptions.indexOf, 1)
        }
        else{
            openedDescriptions.push(i)
        }

        this.setState({
            openedDescriptions: openedDescriptions
        })
    }

    goToSupplierPage = (supplierId) => {
        const date = new Date();
        let month = ('0' + (date.getMonth() + 1)).slice(-2);
        this.props.goToLink('/booking/' + supplierId + '/' + date.getFullYear() + "-" + month + '-' + ('0' + date.getDate()).slice(-2));
    }



    render() {
        const { searchResult, searchLoading } = this.props;
        const { openedDescriptions } = this.state;
        if (searchLoading) {
            return <div style={{display: 'flex',alignItems: "center", justifyContent: "center", height: "100%"}}><CircularProgress /></div>
        }

        if (!searchLoading && !searchResult.length) {
            return <div style={{fontSize:16, display: 'flex',alignItems: "center", justifyContent: "center", height: "100%"}}><br/><br/>No results</div>
        }



        return (<div className="searchResultWrapper">
            <Typography variant="h7" component="h3">{searchResult.length} Results</Typography>
            {searchResult.map((item, i) => {
                let descriptionClass = openedDescriptions.indexOf(i) >= 0 ? 'short_description show-all' : 'short_description';
                let label = openedDescriptions.indexOf(i) >= 0 ? 'less' : 'more';
                return <>
                    <a onClick={(e) => this.goToSupplierPage(item.id)}  >
                        {item.images.length > 0 && <img src={serverUrl + '/' + item.images[0].path} alt={item.name}/>}
                        {item.price_from && <div className="price_from"><span>from</span> {item.price_from}</div>}
                        <p>{item.name}</p>
                    </a>
                    {item.short_description !== null && <div className={descriptionClass}>
                        <div className="content">{item.short_description}</div>
                        <span onClick={e => this.toggleShowDescription(i)} className="moreless">
                            {label}
                        </span>
                    </div>}
                    {item.tags !== null && <div>
                        <div className="tags">
                            {item.tags.map(tag => {
                                return <div>{tag}</div>
                            })}
                        </div>
                    </div>}
                </>
            })}
        </div>)
    }
}

export default SearchResults;