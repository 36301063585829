
const data = {
    'guest' : [
        {
            'title': 'Welcome to the rarified world of Semper.',
            'text': 'Book without constraint or delay and benefit from premium services.',
            'img' : 'welcome1'
        },
        {
            'title': 'VVIP treatment',
            'text': 'Thanks to Semper, you can book when you want and what you want, even if the wished establishment are full.',
            'img' : 'guest1'
        },
        {
            'title': 'Guaranteed booking in the most exclusive locations',
            'text': 'You will be sure to benefit from a top-of-the-range service and premium services at the level of your investment.',
            'img' : 'guest2'
        },
        {
            'title': 'A worldwilde selection of the best experience',
            'text': 'Choose simplicity for your international trips and travels. Centralize your leisure bookings with Semper.',
            'img' : 'guest3'
        },
    ],
    'admin' : [
        {
            'title': 'Welcome to the rarified world of Semper.',
            'text': 'Book without constraint or delay and benefit from premium services.',
            'img' : 'welcome1'
        },
        {
            'title': 'VVIP treatment',
            'text': 'Thanks to Semper, you can book when you want and what you want, even if the wished establishment are full.',
            'img' : 'guest1'
        },
        {
            'title': 'Guaranteed booking in the most exclusive locations',
            'text': 'You will be sure to benefit from a top-of-the-range service and premium services at the level of your investment.',
            'img' : 'guest2'
        },
        {
            'title': 'A worldwilde selection of the best experience',
            'text': 'Choose simplicity for your international trips and travels. Centralize your leisure bookings with Semper.',
            'img' : 'guest3'
        },
    ],
    'advisor' : [
        {
            'title': 'Welcome to the rarified world of Semper.',
            'text': 'Find books without constraint or delay for your premium customers.',
            'img' : 'welcome1'
        },
        {
            'title': 'VVIP treatment',
            'text': 'Offer reservations in establishments which are already fully booked. Find services specially reserved for your Premium customers.',
            'img' : 'guest1'
        },
        {
            'title': 'Suggest Premium services',
            'text': 'Offer your customers high-end services even at the last minute. Renowned establishments coveted for their quality.',
            'img' : 'guest2'
        },
        {
            'title': 'A worldwilde selection of the best experience',
            'text': 'Find and propose your customers high quality establishments all over the world.',
            'img' : 'guest3'
        },
    ],
    'supplier': [
        {
            'title': 'Welcome to the rarified world of Semper.',
            'text': 'Because there are only advantages in welcoming good customers.',
            'img' : 'welcome1'
        },
        {
            'title': 'Increase your turnover',
            'text': 'You will be in control of your prices and conditions. Propose the prices that suit you and quickly increase your average basket.',
            'img' : 'supplier1'
        },
        {
            'title': 'Secure your reservations',
            'text': 'You will be assured that a defined minimum amount will be charged to your customer. Payment is guaranteed even in case of no-show.',
            'img' : 'supplier2'
        },
        {
            'title': 'Gain in profitability',
            'text': 'You will be in a position to no longer refuse the customers who spend the most. Multiply the profitability of your service by 3, 4, 5 or more.',
            'img' : 'supplier3'
        },
        {
            'title': 'Benefit from selected clients',
            'text': 'You will become part of a community of over 12,000 members around the world. Premium customers who are ready to invest in their leisure time.',
            'img' : 'supplier4'
        },
    ]
};

export default data;
