import React from 'react';

class IFrame extends React.Component{
    render() {
        return(
            <iframe name="iframe_3d" src={this.props.src} style={{width: '100%', height: '100%'}} frameBorder="0">
            </iframe>
        )
    }
}

export default React.memo(IFrame);