import React from 'react';
import {connect} from "react-redux";
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonIcon from '@material-ui/icons/Person';
import { getCountUnreadMessages } from '@reducers/chat';
import EventIcon from '@material-ui/icons/Event';
import BottomNotifications from './BottomNotifications'

import {
    withRouter,
    Link
} from "react-router-dom";

class BottomNavigationWrapper extends React.Component{

    render() {
        const { user } = this.props;
        return <>
            <BottomNotifications auth={this.props.auth} />
            <BottomNavigation
                value={this.props.currentItem}
                onChange={this.props.changeMenu}
                showLabels
            >
                {(user.type === 'guest' || user.type === 'advisor') && <BottomNavigationAction component={Link} to="/" label="Search" icon={<LocationOnIcon />} />}
                {(user.type === 'guest') && <BottomNavigationAction component={Link} to="/booking-list" label="Bookings" icon={<div>
                    <EventAvailableIcon />
                    {this.props.counterUnreadMessages !== 0 && <span className="newMessageCounter">{this.props.counterUnreadMessages}</span>}
                </div>} />}
                {(user.type === 'advisor') && <BottomNavigationAction component={Link} to="/invite" label="Invite" icon={<GroupAddIcon />} />}
                {user.type === 'supplier' && <BottomNavigationAction component={Link} to="/" label="Bookings" icon={<div>
                    <EventAvailableIcon />
                </div>} />}
                {user.type === 'supplier' && <BottomNavigationAction component={Link} to="/calendar" label="Calendar" icon={<div>
                    <EventIcon />
                    {this.props.counterUnreadMessages !== 0 && <span className="newMessageCounter">{this.props.counterUnreadMessages}</span>}
                </div>} />}
                <BottomNavigationAction component={Link} to="/profile" label="Profile" icon={<PersonIcon />} />
            </BottomNavigation>
        </>
    }
}

function mapStateToProps(state) {
    return {
        counterUnreadMessages: getCountUnreadMessages(state),
    }
}

export default connect(mapStateToProps)(withRouter(BottomNavigationWrapper));