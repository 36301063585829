import React from 'react';
import { apiRequest } from '@services/Request';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentSubscription from '@semper/PaymentSubscription'
import WhiteMark from '@images/wmark.svg';
import {
    Link
} from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Subscriptions from '@semper/Subscriptions';
import TermsAndConditions from '@semper/TermsAndConditions';


class Premium extends React.Component{

    state = {
        isPremium: false,
        isLoading: true,
        openTermsAndConditions: false,
        selected_premium_product: 0,
        selected_premium_cities: [],
        isOpenPaymentDialog: false,
        generalFees: 15,
        unsubscribe: false,
        pricePremium: 0,
        cities: [],
        premium_products: [],
        next_billing_date: 0,
        cards: [],
        last_hours_duration_for_fees: '',
        free_expiration_date: '',
        entrance_fees: '',
        discount_entrance_fees: '',
        premium_member_monthly_price: '',
    }

    componentDidMount() {
        const { auth } = this.props;
        apiRequest('user/get-premium', 'GET', null, auth.api_key).then(response =>{
            if (response.status === 200) {
                response.json().then(data => {
                    this.setState({
                        isPremium: data.is_premium,
                        generalFees: data.fees,
                        cards: data.credit_cards,
                        next_billing_date: data.next_billing_date,
                        cities: data.cities,
                        last_hours_duration_for_fees: data.last_hours_duration_for_fees,
                        free_expiration_date: data.free_expiration_date,
                        premium_products: data.premium_products,
                        selected_premium_cities: data.selected_premium_cities,
                        selected_premium_product: data.selected_premium_product,
                        pricePremium: data.price_per_month,
                        entrance_fees: data.entrance_fees,
                        discount_entrance_fees: data.discount_entrance_fees,
                        premium_member_monthly_price: data.premium_member_monthly_price,
                        isLoading: false,
                    })
                })
            }
        })
    }


    switchToPremium = (productId) => {
        this.setState({
            isPremium: true,
            selected_premium_product: productId
        })
    }

    subscribe = () => {
        const { auth } = this.props;
        apiRequest('user/subscribe-premium', 'POST', null, auth.api_key).then(responce => {
            if (responce.status === 200) {
                responce.json().then((data) => {
                    this.setState({
                        isOpenPaymentDialog: true,
                        pricePremium: data.price_per_month,
                        cards: data.credit_cards
                    })
                })

            }
        });
    }

    unsubscribeCheckbox = () => {
        this.setState({
            unsubscribe: !this.state.unsubscribe
        })
    }

    closePaymentDialog = () => {
        this.setState({
            isOpenPaymentDialog: false
        })
    }

    closeTermsAndConditions = () => {
        this.setState({
            openTermsAndConditions: false
        })
    }

    openTermAndConditions = () => {
        this.setState({
            openTermsAndConditions: true,
            terms: !this.state.terms,
        })
    }

    render(){
        const { cities, last_hours_duration_for_fees, free_expiration_date,
            selected_premium_cities,  selected_premium_product, premium_products, premium_member_monthly_price,
            isLoading, cards, openTermsAndConditions } = this.state;


        if (isLoading) {
            return(
                <div className="child-by-center">
                    <CircularProgress />
                </div>
            )
        }

        return(
            <div className="plans-from-profile">
                <Subscriptions
                    switchToPremium={this.switchToPremium}
                    access_token={this.props.auth.api_key}
                    cities={cities}
                    premium_member_monthly_price={premium_member_monthly_price}
                    cards={cards}
                    free_expiration_date={free_expiration_date}
                    last_hours_duration_for_fees={last_hours_duration_for_fees}
                    premium_products={premium_products}
                    selected_premium_cities={selected_premium_cities}
                    selected_premium_product={selected_premium_product}
                />
                <div className="subscribe-extra-info">
                    <div>
                        Only applicable for restaurant bookings<br/>
                        See more informations about <a style={{textDecoration: 'underline'}} onClick={this.openTermAndConditions}>Premium plan</a>
                    </div>

                </div>
                <TermsAndConditions closeTermsAndConditions={this.closeTermsAndConditions} open={openTermsAndConditions} />
            </div>
        )

    }
}

export default Premium;
