import React from 'react';
import './chat.css';
import Attachment from '@images/attachment.svg';
import Send from '@images/sendmessage.svg';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Attachments from './attachments.js';
import GuestIcon from '@images/guest.svg'
import {apiRequest} from "@services/Request";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


class Chat extends React.Component{


    state = {
        loadingMessages: true,
        sendingMessage: false,
        messageText: '',
        channel: null,
        attachments: [],
        resultChatSession: null,
        error: '',
        messages: []
    }

    scrollToBottomMessagesBlock = () => {
        const scrollHeight = this.messageList.scrollHeight;
        const height = this.messageList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    parseFileSize = (fileName) => {
        let result = {
            width: 344,
            height: 344,
        };
        if (fileName.indexOf('|') > 0) {
            let sizePart = fileName.split('|')[0];
            if (sizePart.indexOf('x') > 0) {
                result = {
                    width: sizePart.split('x')[0],
                    height: sizePart.split('x')[1]
                }
            }
        }
        return result;
    }

    prepareMessages = (messages) => {
        const { auth, fromGuest, } = this.props;
        let needId = fromGuest ? auth.user.user.id : auth.user.user.supplier_id;
        let needMessagesFormat = messages.map(message => {
            let prefix = fromGuest ? 'guest' : 'supplier';
            message['direction'] = (prefix + needId === message.user.name) ? "right" : "left";
            if (message.type === 'FILE') {
                message['size'] = this.parseFileSize(message.file.name);
            }
           return message
        });

        this.setState({
            messages: needMessagesFormat,
            loadingMessages: false
        });
        this.scrollToBottomMessagesBlock();
    }

    async componentDidMount() {
        const { auth, chatSession, kitty, fromGuest, channel } = this.props;
        let needId = fromGuest ? auth.user.user.id : auth.user.user.supplier_id;
        if (auth.user.user.email && chatSession !== undefined) {
            if (chatSession.succeeded) {
                if (channel.channel) {
                    this.setState({
                        channel: channel.channel
                    })
                    const resultChatSession = kitty.startChatSession({
                        channel: channel.channel,
                        onReceivedMessage: async (message) => {
                            let { messages } = this.state;
                            let prefix = fromGuest ? 'guest' : 'supplier';
                            message['direction'] = (prefix + needId === message.user.name) ? "right" : "left";
                            if (message.type === 'FILE') {
                                message['size'] = this.parseFileSize(message.file.name);
                            }
                            messages.push(message);
                            this.setState({
                                messages: [...messages]
                            })

                            await kitty.readChannel({ channel: channel.channel })

                            this.scrollToBottomMessagesBlock();
                        },
                        onReceivedKeystrokes: (keystrokes) => {
                            // handle received typing keystrokes
                        },
                        onTypingStarted: (user) => {
                            // handle user starts typing
                        },
                        onTypingStopped: (user) => {
                            // handle user stops typing
                        },
                        onParticipantEnteredChat: (user) => {
                            // handle user who just entered the chat
                        },
                        onParticipantLeftChat: (user) => {
                            // handle user who just left the chat
                        },
                        onParticipantPresenceChanged: (user) => {
                            // handle user who became online, offline, do not disturb, invisible
                        },
                        onMessageRead: (message, receipt) => {
                            // handle read receipt for message
                        },
                        onMessageUpdated: (message) => {
                            // handle message changes
                        },
                        onChannelUpdated: (channel) => {
                            // handle channel changes
                        },
                    });

                    if (resultChatSession.succeeded) {
                        this.setState({
                            resultChatSession: resultChatSession.session
                        })
                        const resultGetMessages = await kitty.getMessages({
                            channel: channel.channel,
                        });

                        if (resultGetMessages.succeeded) {
                            const messages = resultGetMessages.paginator.items; // Handle messages
                            this.prepareMessages(messages.reverse());
                            await kitty.readChannel({ channel: channel.channel })
                        }

                        if (resultGetMessages.failed) {
                            this.setState({
                                loadingMessages: false,
                                error: resultGetMessages.error.message,
                            })
                        }

                    }

                    if (resultChatSession.failed) {
                        this.setState({
                            loadingMessages: false,
                            error: resultChatSession.error.message,
                        })
                    }
                }

                if (channel.failed) {
                    this.setState({
                        loadingMessages: false,
                        error: channel.error.message,
                    })
                }
            }

            if (chatSession.failed) {
                this.setState({
                    loadingMessages: false,
                    error: chatSession.error.message,
                })
            }
        }
    }

    async componentWillUnmount() {
        const { resultChatSession } = this.state;
        if (resultChatSession !== null) {
            await resultChatSession.end();
        }

    }

    sendMessage = async () => {
        let { messageText, loadingMessages, error, attachments } = this.state;
        const { channel } = this.props;
        if (messageText && !loadingMessages && error === '') {
            let result;
            this.setState({
                sendingMessage: true
            })
            result = await this.props.kitty.sendMessage({
                channel: channel.channel,
                direction: "right",
                body: messageText,
            });

            if (result.succeeded) {
                const message = result.message; // Handle message
                this.setState({
                    messageText: ''
                })
            }

            if (result.failed) {
                const error = result.error; // Handle error
            }

            this.setState({
                sendingMessage: false
            })
        }

        if (attachments.length > 0 && !loadingMessages && error === '') {
            console.log(attachments[0].file);
            this.setState({
                sendingMessage: true
            })
            const resultFile = await this.props.kitty.sendMessage({
                channel: channel.channel,
                type: 'FILE',
                file: attachments[0].file,
                progressListener: {
                    onStarted: () => {
                        // Handle file upload started
                    },

                    onProgress: (progress) => {
                        // Handle file upload process
                    },

                    onCompleted: (result) => {
                        // Handle file upload completed
                        this.setState({
                            sendingMessage: false
                        })
                    },
                },
            });

            if (resultFile.succeeded) {
                const message = resultFile.message; // Handle message
                this.setState({
                    attachments: []
                })
            }

            if (resultFile.failed) {
                const error = resultFile.error; // Handle error
                console.log(error);
            }
        }

    }




    readFile = (e) => {
        let { attachments } = this.state;
        if (e.target.files.length > 0){
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = () => {
                function renameFile(originalFile, newName) {
                    return new File([originalFile], newName, {
                        type: originalFile.type,
                        lastModified: originalFile.lastModified,
                    });
                }
                if (file.type.indexOf('video') >= 0) {


                    attachments = [{
                        file: file,
                        type: 'video',
                        preview: window.URL.createObjectURL(file)
                    }]

                    this.setState({
                        attachments: [...attachments]
                    })
                }
                else{
                    let img = new Image();
                    var objectUrl = window.URL.createObjectURL(file);
                    img.onload = (e) =>  {
                        let standartPlaceholderWidth = 344;
                        let oldRatio = e.target.width / e.target.height;
                        let newFile;
                        if (e.target.width >= standartPlaceholderWidth) {
                            let height = 344 / oldRatio;
                            newFile = renameFile(file, 344 + 'x' + height +'|' + file.name);
                        }
                        else{
                            let height = e.target.width / oldRatio;
                            newFile = renameFile(file, e.target.width + 'x' + height +'|' + file.name);
                        }

                        attachments = [{
                            file: newFile,
                            type: 'image',
                            preview: btoa(reader.result)
                        }]
                        this.setState({
                            attachments: [...attachments]
                        })
                        window.URL.revokeObjectURL(objectUrl);
                    };
                    img.src = objectUrl;

                }
            }


        }
    }
    changeMessage = (e) => {
        this.setState({
            messageText: e.target.value
        })
    }

    removeAttachment = () => {
        this.setState({
            attachments: []
        });
    }

    render() {
        const { messages, messageText,sendingMessage, attachments, loadingMessages, error} = this.state;
        const { bookingName, bookingDate, fromGuest } = this.props;
        const avatar = (fromGuest) ? this.props.supplier_avatar : GuestIcon;
        const extraClassAvatar = (fromGuest) ? "avatar supplier_avatar" : 'avatar';
        return (
            <div className="chat">
                <div className="top">
                    <div className={extraClassAvatar}>
                        <img src={avatar} alt=""/>
                    </div>
                    <div style={{margin: '0 15px'}}>
                        <p style={{fontSize: 16, margin: 0, fontWeight:600, fontFamily: 'Barlow'}}>{bookingName}</p>
                        <p style={{fontSize: 14, margin: 0, fontFamily: 'Barlow'}}>Booking: {bookingDate}</p>
                    </div>
                    <div className="more-actions">

                    </div>
                </div>
                {loadingMessages === false && error !== '' && <div>
                    <p style={{textAlign: 'center', padding: 20}}>Error: {error}</p>
                </div>}
                {loadingMessages === false && <div className="messages"  ref={(el) => { this.messageList = el; }}>
                    {messages.map(message => {
                        let date = new Date(message.createdTime);
                        let time = new Date(message.createdTime).toLocaleString('en-GB', { hour12: true }).split(', ')[1];
                        return (
                            <div className={'message ' + message.direction}>
                                <div className="date">{date.getFullYear()}/{date.getMonth() + 1}/{date.getDate()} {time}</div>
                                {message.type === 'TEXT' && <div className="content">{message.body}</div>}
                                {message.type === 'FILE' && <div className="content">
                                    {(message.file.contentType.indexOf('video') === -1) && <div className="chat-image" style={{width: message.size.width + 'px', height: message.size.height + 'px'}}>
                                        <LazyLoadImage effect="blur" {...message.size} src={message.file.url} />
                                    </div>}
                                    {(message.file.contentType.indexOf('video') !== -1) && <div className="chat-video">
                                        <video height={400} controls="controls" src={message.file.url}></video>
                                    </div>}
                                </div>}
                            </div>
                        )
                    })}
                </div>}
                {loadingMessages === true && <div className="child-by-center">
                    <CircularProgress />
                </div>}
                <div className="actions">
                    <Attachments removeAttachment={this.removeAttachment} attachments={attachments} />
                    <div className="wrapper-actions">
                        <form action="">
                            <div className="attachments">
                                <label htmlFor="file">
                                    <img className="attachmentIcon" src={Attachment} />
                                </label>
                                <input onChange={(event)=> {
                                    this.readFile(event)
                                }} accept="image/*, video/mp4,video/x-m4v,video/*" id="file" style={{display: 'none'}} type="file"/>
                            </div>
                        </form>
                        <TextField
                            variant="outlined"
                            multiline
                            value={messageText}
                            placeholder="Message..."
                            onChange={this.changeMessage}
                            rowsMax={10}
                            disabled={loadingMessages || error}
                            InputProps={{
                                endAdornment: sendingMessage ? <CircularProgress size={13} /> : <img onClick={this.sendMessage} className="sendIcon" src={Send} />
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}


export default Chat;