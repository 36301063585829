import React from 'react';
import Money from '@images/money.svg'
import RangeTime from '@images/range_time.svg'
import Time from '@images/range_time.svg'
import Guest from '@images/guest.svg'
import Button from '@material-ui/core/Button';
import { apiRequest } from '@services/Request';
import CircularProgress from '@material-ui/core/CircularProgress';
import './react-big-calendar.css';
import './style.css';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { formatTime } from '@semper/FormatTime';
import TextsmsIcon from '@material-ui/icons/Textsms';
import Chat from '../../chat/index';
import Portal from '@material-ui/core/Portal';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { chatHelper } from '@helpers/chat';
import {connect} from "react-redux";
import {getChannels} from "@reducers/chat";
import { withRouter } from "react-router-dom";
import {isChannelLoading} from "@reducers/chat";
import { chatActions } from '@actions/chat';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SuggestNewTime from '../suggestNewTime';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconForRejectButton from '@images/back_arrow_white.svg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const localizer = momentLocalizer(moment);
let now = new Date();
const DateCellWrapper = ({dates, onSelectSlot, children, value}) => {
    let extraStyles = 'white';
    let boxShadow = 'none';
    let element = dates.find((e) => {
        return new Date(e.start).setHours(0)  === value.getTime();
    });

    let valueForCell = value.getDate();
    let notifications = 0;
    if (typeof element != "undefined" && typeof element.bookings != "undefined") {
        extraStyles = '#EDE6DD';
        boxShadow = '0px 4px 4px rgba(0, 0, 0, 0.15)';
        if (element.unread_messages !== undefined) {
            notifications += element.unread_messages;
        }
    }
    else if(typeof element != "undefined"){
        extraStyles = '#F7F7F7';
        boxShadow = '0px 4px 4px rgba(0, 0, 0, 0.15)';
    }

    if (notifications) {
        return React.cloneElement(React.Children.only(children), {
            onTouchEnd: () => onSelectSlot(value),
            notifications: notifications,
            style: {
                ...children.style,
                background: extraStyles,
                borderRadius: 11,
                boxShadow: boxShadow,
                zIndex: 11,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold'
            },
        }, valueForCell);
    }
    else{
        return React.cloneElement(React.Children.only(children), {
            onTouchEnd: () => onSelectSlot(value),
            style: {
                ...children.style,
                background: extraStyles,
                borderRadius: 11,
                boxShadow: boxShadow,
                zIndex: 11,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold'
            },
        }, valueForCell);
    }



};

class SuppierCalendar extends React.Component{

    state = {
        isLoading: true,
        currentMonthBookings: [],
        selectedDate: null,
        nextMonthBookings: [],
        options: [],
        optionsForSuggest: [],
        bookings:[],
        openChat: false,
        userIdForChat: 0,
        bookingHash: 0,
        bookingIdForChat: 0,
        bookingName: '',
        bookingDate: '',
        bookingInPopupMenu: {},
        openSuggestionPopup: false,
        booking_id: false,
        chatkitty_channel_id: 0
    }

    componentDidMount() {
        this.loadCalendar();
        if (window.location.hash && new Date(window.location.hash.replace('#','')).getDate()) {
            this.setState({
                selectedDate: new Date(window.location.hash.replace('#','')),
                isLoading: false
            });
            setTimeout(() => {
                this.getOptionAndBookingByCurentDay();
            },100)
        }
        else{
            window.location.hash = '';
        }

    }

    loadCalendar = () => {
        const { auth, dispatch, kitty, chatSession } = this.props;
        this.setState({
            isLoading: true
        });
        apiRequest('supplier/bookings-current-next-month', 'POST', null, auth.api_key).then(responce => {
            if (responce.status === 200) {
                responce.json().then(data => {
                    this.setState({
                        isLoading: false,
                        currentMonthBookings: data.bookings.currentMonthResults,
                        nextMonthBookings: data.bookings.nextMonthResults,
                    })
                    if (chatSession.succeeded) {
                        let allBookings = [];
                        data.bookings.currentMonthResults.concat(data.bookings.nextMonthResults).forEach(booking => {
                            if (booking.bookings) {
                                allBookings = allBookings.concat(booking.bookings)
                            }
                        });
                        chatHelper.prepareChatChannels(auth, dispatch, kitty, allBookings, 'guest');
                    }
                    else{
                        dispatch(chatActions.errorChatKitty());
                    }
                })
            }
            else{
                this.setState({
                    isLoading: false
                })
            }
        })
    }

    mergeByOption = (bookings, day) => {
        let results = [];
        let relation = {};
        let skipOption = [];
        bookings.sort((a, b) => {
            if (a.status < b.status) return 1;
            if (b.status < a.status) return -1;
            return 0;
        });
        bookings.forEach((booking,i) => {
            if (skipOption.indexOf(booking.id) < 0){
                if (day === 'options') {
                    if (booking.status === 10) {
                        bookings.forEach(_booking => {
                            if (_booking.status === 1 && _booking.start_hour == booking.start_hour && _booking.end_hour == booking.end_hour) {
                                skipOption.push(_booking.id);
                            }
                        })
                    }
                    else{
                        if (typeof relation[booking.option.name] == "undefined") {
                            results.push({
                                name: booking.option.name,
                                variants: [booking]
                            })
                            relation[booking.option.name] = Object.keys(relation).length;
                        }
                        else{
                            results[relation[booking.option.name]]['variants'].push(booking);
                        }
                    }
                }
                else{
                    results.push({
                        name: booking.name,
                        variants: [booking]
                    })
                }
            }

        })
        if (day === 'options') {
            this.setState({
                options: results
            })
        }
        else{
            this.setState({
                bookings: results
            })
        }

    }

    getOptionAndBookingByCurentDay = () => {
        const { selectedDate } = this.state;
        const { auth, kitty, dispatch, channels, loading } = this.props;
        this.setState({
            isLoading: true
        })
        let dateToSent = selectedDate.getFullYear() + "-" + ('0' + (selectedDate.getMonth()+1)).slice(-2) + "-" + ('0' + selectedDate.getDate()).slice(-2);
        apiRequest('supplier/bookings-date', 'POST', {date: dateToSent}, auth.api_key).then(responce => {
            if (responce.status === 200) {
                responce.json().then(data => {
                    this.mergeByOption(data.bookings.availableServices, 'options');
                    this.mergeByOption(data.bookings.bookings, false);
                    this.setState({
                        optionsForSuggest: data.extra_data.options
                    })
                })
            }
        })
    }

    slotInfo = (date) => {
        if (typeof date.start != "undefined") {
            this.setState({
                selectedDate: date.start,
                isLoading: true
            });

            setTimeout(() => {
                window.location.hash = date.start.getFullYear() + "-" + ('0' + (date.start.getMonth()+1)).slice(-2) + "-" + ('0' + date.start.getDate()).slice(-2);
                this.getOptionAndBookingByCurentDay();
            }, 100)
        }
        else{
            this.setState({
                selectedDate: date,
                isLoading: true
            });

            setTimeout(() => {
                window.location.hash = date.getFullYear() + "-" + ('0' + (date.getMonth()+1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2);
                this.getOptionAndBookingByCurentDay();
            }, 100)
        }

    }

    handleBack = () => {
        this.setState({
            selectedDate: null,
            options: [],
            bookings: [],
        });
        window.location.hash = '';
    }


    closeOption = (optionId) => {
        if (window.confirm('Are you sure you want close this option for this day?')) {
            const { auth } = this.props;
            const { selectedDate } = this.state;
            let dateToSent = selectedDate.getFullYear() + "-" + ('0' + (selectedDate.getMonth()+1)).slice(-2) + "-" + ('0' + selectedDate.getDate()).slice(-2);
            apiRequest('supplier/close-option','POST',{id: optionId, date: dateToSent}, auth.api_key).then(responce => {
                if (responce.status === 200) {
                    this.getOptionAndBookingByCurentDay();
                }
                else{
                    alert('Something went wrong');
                }
            })
        }
    }

    reject = (id, skip, newHour) => {
        const { auth } = this.props;
        if (skip !== undefined || window.confirm('Are you sure you want reject this booking?')) {
            apiRequest('supplier/reject-booking', 'POST', {id: id, time: newHour}, auth.api_key).then(responce => {
                if (responce.status === 200) {
                    this.getOptionAndBookingByCurentDay();
                }
                else{
                    alert('Something went wrong')
                }
            })
        }
    }
    aprove = (id) => {
        const { auth } = this.props;
        if (window.confirm('Are you sure you want accept booking?')) {
            apiRequest('supplier/accept-booking', 'POST', {id: id}, auth.api_key).then(responce => {
                if (responce.status === 200) {
                    this.getOptionAndBookingByCurentDay();
                }
                else{
                    alert('Something went wrong')
                }
            })
        }
    }

    handleCloseChat = () => {
        this.setState({
            openChat: false,
            bookingIdForChat: 0,
            userIdForChat: 0,
            bookingHash: '',
            bookingName: '',
            chatkitty_channel_id: 0,
            bookingDate: ''
        })
    }

    openChat = (booking) => {
        const { channels, dispatch } = this.props;
        this.setState({
            openChat: true,
            userIdForChat: booking.user.id,
            bookingHash: booking.hash,
            bookingIdForChat: booking.id,
            bookingName: booking.firstname + " " + booking.lastname,
            bookingDate: booking.date,
            chatkitty_channel_id: booking.chatkitty_channel_id
        });
        if (channels[booking.hash] !== undefined) {
            dispatch(chatActions.readMessages(channels[booking.hash].countUnreadMesages, booking.hash));
        }

    }


    suggestNewHours = async (newHour, bookingId) => {
        const { channels } = this.props;
        const { bookingInPopupMenu } = this.state;

        if (channels[bookingInPopupMenu.hash] !== undefined) {
            const resultChatSession = await this.props.kitty.startChatSession({
                channel: channels[bookingInPopupMenu.hash].channel,
            });

            if (resultChatSession.succeeded) {
                const result = await this.props.kitty.sendMessage({
                    channel: channels[bookingInPopupMenu.hash].channel,
                    direction: "right",
                    body: 'Hello! Booking for time ' + bookingInPopupMenu.hour + ' is not possible. But it is possible at ' + newHour + '. Please create a new booking and select this new time. \n Best regards'
                });

                if (result.failed) {
                    const error = result.error; // Handle error
                    console.log(error)
                }
            }
            else{
                console.log(resultChatSession);
            }

        }
        else{
            console.log('Channel doesn\'t exist');
        }

        this.reject(bookingId, true, newHour);
        this.setState({
            openSuggestionPopup: false,
            bookingInPopupMenu: {},
        });
    }

    openSuggestionPopup = () => {
        this.setState({
            openSuggestionPopup: true,
            showRejectedMenu: false
        })
    }

    handleCloseSuggestionPopup = () => {
        this.getOptionAndBookingByCurentDay();
        this.setState({
            openSuggestionPopup: false
        })
    }

    handleCloseRejectedMenu = () => {
        this.setState({
            showRejectedMenu: false,
        })
    }

    openRejectMenu = (event, booking) => {
        if (event) {
            this.setState({
                showRejectedMenu: event.currentTarget,
                bookingInPopupMenu: booking
            })
        }

    }

    markAsCheckedBooking = (id) => {
        if (window.confirm('Please confirm that booking is well saved in your side.')) {
            const { auth } = this.props;
            apiRequest('supplier/check-booking', 'POST', {checked: true, booking_id: id}, auth.api_key).then(responce => {
                if (responce.status === 200) {
                    this.getOptionAndBookingByCurentDay();
                }
                else{
                    alert('Something went wrong')
                }
            })
        }
    }

    banGuest = (id) => {
        if (window.confirm('Are you sure ?')) {
            const { auth } = this.props;
            apiRequest('supplier/ban-user', 'POST', {booking_id: id}, auth.api_key).then(responce => {
                if (responce.status === 200) {
                    this.getOptionAndBookingByCurentDay();
                }
                else{
                    alert('Something went wrong')
                }
            })
        }
    }


    render() {
        const { userIdForChat,  bookingInPopupMenu, optionsForSuggest, showRejectedMenu, openSuggestionPopup, selectedDate, options, bookings, chatkitty_channel_id, bookingDate, openChat, bookingHash, bookingName, bookingIdForChat } = this.state;
        const { loading, channels } = this.props;
        let { currentMonthBookings, nextMonthBookings } = this.state;
        if (loading) {
            return <div style={{display: 'flex',alignItems: "center", justifyContent: "center", height: "100%"}}><CircularProgress /></div>
        }
        currentMonthBookings = currentMonthBookings.map(day => {
            if (day.bookings !== undefined) {
                day['unread_messages'] = 0;
                day.bookings.forEach(booking => {
                    if (channels[booking.hash] !== undefined) {
                        day['unread_messages'] = day['unread_messages'] + channels[booking.hash].countUnreadMesages
                    }
                })
            }
            return day
        });

        nextMonthBookings = nextMonthBookings.map(day => {
            if (day.bookings !== undefined) {
                day['unread_messages'] = 0;
                day.bookings.forEach(booking => {
                    if (channels[booking.hash] !== undefined) {
                        day['unread_messages'] = day['unread_messages'] + channels[booking.hash].countUnreadMesages
                    }
                })
            }
            return day
        });

        return (<div className="supplier-interface" style={{padding: 15}}>
            <div className="sup-header child-by-center">
                {!!selectedDate && <IconButton className="close-day" edge="start" color="white" onClick={this.handleBack} aria-label="close">
                    <KeyboardArrowLeftIcon />
                </IconButton>}
                <span>Calendar</span>
            </div>
            {!selectedDate && <>
                <Calendar
                    localizer={localizer}
                    events={currentMonthBookings}
                    startAccessor="start"
                    selectable
                    resizable
                    showMultiDayTimes
                    endAccessor="end"
                    onSelectSlot={this.slotInfo}
                    components={{
                        dateCellWrapper: (props) => (
                            <DateCellWrapper onSelectSlot={this.slotInfo} dates={currentMonthBookings} {...props} />
                        ),
                    }}
                    style={{ height:400 }}
                    views={['month']}
                />
                <div className="sep"></div>
                <Calendar
                    localizer={localizer}
                    events={nextMonthBookings}
                    startAccessor="start"
                    selectable
                    resizable
                    showMultiDayTimes
                    defaultDate={new Date(now.getFullYear(), now.getMonth()+1, 1)}
                    endAccessor="end"
                    onSelectSlot={this.slotInfo}
                    components={{
                        dateCellWrapper: (props) => (
                            <DateCellWrapper onSelectSlot={this.slotInfo} dates={nextMonthBookings} {...props} />
                        ),
                    }}
                    style={{ height:400 }}
                    views={['month']}
                />
            </>}
            {!!selectedDate && <>
                <div>
                    <div className="date">{selectedDate.getFullYear() + "/" + ('0' + (selectedDate.getMonth()+1)).slice(-2) + "/" + ('0' + selectedDate.getDate()).slice(-2)}</div>
                </div>
                <br/><br/>
                <div className="day-block-wrapper services">
                    <div className="day-block">
                        <div>
                            <div className="sub-title">Available Services</div>
                        </div>
                    </div>
                    {options.map((option, j) => {
                        return (
                            <div key={j} className="optionBlock new">
                                <div className="justify-between">
                                    <p className="title">{option.name}</p>
                                </div>
                                {option.variants.map((variant, i) => {
                                    return (
                                        <div key={i} className="line">
                                            <span><img src={RangeTime}/><span>{formatTime(variant.start_hour)}<br/>{formatTime(variant.end_hour)}</span></span>
                                            <span><img src={Money}/> <span>{variant.price.replace('.00', '')} CHF / guest</span></span>
                                            <span><img src={Guest}/> <span>{variant.nb_users_min} min.&nbsp;guests</span></span>
                                            <span>
                                                <Button onClick={(e) => this.closeOption(variant.id)} className="blackButton small-btn">Close</Button>
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                    {options.length === 0 && <p>No services for this date</p>}
                </div>

                <div className="day-block-wrapper">
                    <div className="day-block">
                        <div>
                            <div className="sub-title">Bookings</div>
                        </div>
                    </div>
                    {bookings.map((option, i) => {
                        return (
                            <div key={i} className="optionBlock">
                                {option.variants.map((variant, j) => {
                                    return (
                                        <div key={j}>
                                            {variant.can_check === true && variant.status !== 15 && <>
                                                <div className="booking-status-checkbox">
                                                    <div className="label">New !</div>
                                                    <div className="checkbox">
                                                        <FormControlLabel
                                                            color="white"
                                                            labelPlacement="start"
                                                            control={
                                                                <Checkbox
                                                                    name="booking_status"
                                                                    onChange={() => this.markAsCheckedBooking(variant.id)}
                                                                />
                                                            }
                                                            label="Save in my internal system"
                                                        />
                                                    </div>
                                                </div>
                                            </>}
                                            {variant.status === 15 && <>
                                                <div className="booking-status-checkbox">
                                                    <div style={{position: 'relative'}}>
                                                        <Button
                                                            aria-owns={showRejectedMenu ? 'simple-menu' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={(e) => this.openRejectMenu(e, variant)} className="blackButton gray small-btn">
                                                            Reject
                                                            <img style={{width:8, transform:'rotate( -90deg)', marginLeft: 10}} src={IconForRejectButton} alt=""/>
                                                        </Button>
                                                        <Menu
                                                            id="simple-menu"
                                                            anchorEl={showRejectedMenu}
                                                            open={Boolean(showRejectedMenu)}
                                                            onClose={this.handleCloseRejectedMenu}
                                                        >
                                                            <MenuItem onClick={(e) => this.reject(bookingInPopupMenu.id)}>Reject this booking</MenuItem>
                                                            <MenuItem onClick={(e) => this.openSuggestionPopup()}>Reject and suggest another time</MenuItem>
                                                        </Menu>
                                                    </div>
                                                    <Button onClick={(e) => this.aprove(variant.id)} className="blackButton blue small-btn">Accept</Button>
                                                </div>
                                            </>}
                                            <div className="justify-between">
                                                <p className="title">{option.name}</p>
                                                {variant.status === 20 && <Button className="blackButton gray small-btn">Rejected</Button>}
                                                {variant.status === 40 && <Button className="blackButton gray small-btn">Cancelled</Button>}
                                                {variant.status === 35 && <Button className="blackButton gray small-btn">Waiting for guest approval</Button>}
                                                {variant.status === 50 && <Button className="blackButton gray small-btn">No show</Button>}
                                                {variant.status === 60 && <Button className="blackButton gray small-btn">Complete</Button>}
                                            </div>
                                            <div className="line">
                                                <span><img src={Time}/><span>{variant.hour}</span></span>
                                                <span><img src={Money}/> <span>{variant.price_per_guest} CHF / guest</span></span>
                                                <span><img src={Guest}/> <span>{variant.nb_guests}&nbsp;guests</span></span>
                                            </div>
                                            <div className="by" style={{justifyContent: 'space-between', position: 'relative'}}>
                                                <div>
                                                    Book by : <span> {variant.firstname} {variant.lastname}  -  <a href={'tel:'+variant.phone_number}>{variant.phone_number}</a></span>
                                                </div>
                                                <div>
                                                    {variant.guest_banned !== 1 && <Button onClick={() => this.banGuest(variant.id)} className="blackButton small-btn">Ban guest</Button>}
                                                    {variant.guest_banned === 1 && <Button className="blackButton gray small-btn">Guest banned</Button>}
                                                </div>
                                                <div onClick={(e) => this.openChat(option.variants[0])}>
                                                    {(channels[variant.hash] !== undefined && variant.hash) && <TextsmsIcon />}
                                                    { (channels[variant.hash] !== undefined && channels[variant.hash].countUnreadMesages !== 0 && channels[variant.hash].countUnreadMesages !== undefined) && <span className="newMessageCounter">{channels[variant.hash].countUnreadMesages}</span>}
                                                </div>
                                            </div>
                                            {variant.dietary_names && <div className="diet">
                                                Diet : {variant.dietary_names}
                                            </div>}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                    {bookings.length === 0 && <p>No bookings for this date</p>}
                </div>
            </>}
            {Object.keys(bookingInPopupMenu).length > 0 && <SuggestNewTime
                openPopup={openSuggestionPopup}
                oldTime={bookingInPopupMenu.hour}
                handleCloseSuggestionPopup={this.handleCloseSuggestionPopup}
                suggestNewHours={this.suggestNewHours}
                booking_id={bookingInPopupMenu.id}
                options={optionsForSuggest}
                option_id={bookingInPopupMenu.option_id}
                option_name={bookingInPopupMenu.name}
                auth={this.props.auth}
            />}
            {openChat && bookingHash && <Portal>
                <Dialog TransitionComponent={Transition} keepMounted fullScreen open={openChat} onClose={this.handleCloseChat}>
                    <div className="searchHeader">
                        <ArrowBackIosIcon onClick={this.handleCloseChat} />
                        <span style={{    position: 'absolute',left: 0, right: 0, textAlign: 'center', width: '80%',margin: 'auto'}}>Messages</span>
                        <div></div>
                    </div>
                    <div style={{height: '100%'}}>
                        {(bookingHash && bookingName && bookingDate) && <Chat fromGuest={false} kitty={this.props.kitty} chatSession={this.props.chatSession} userId={userIdForChat} channel={channels[bookingHash]} bookingId={bookingIdForChat} chatkitty_channel_id={chatkitty_channel_id} auth={this.props.auth} hash={bookingHash} bookingName={bookingName} bookingDate={bookingDate} />}
                    </div>
                </Dialog>
            </Portal>}
        </div>)
    }
}

function mapStateToProps(state) {
    return {
        channels: getChannels(state),
        loading: isChannelLoading(state),
    }
}

export default connect(mapStateToProps)(withRouter(SuppierCalendar));

