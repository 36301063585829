import React from 'react';
import AnonymousSearch from './screens/search/anonymous'
import NotFound from './NotFound'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import InvitedGuestSupplier from './screens/supplier/invited';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
    Switch,
    Route,
    Link
} from "react-router-dom";

class InvitedGuestApp extends React.Component{
    state = {
        currentItem: 0,
        welcomeOpened: false
    }

    changeMenu = (event, newValue) => {
        this.setState({
            currentItem: newValue
        })
    }

    componentDidMount() {
        const mainRouting = ['/page', '/profile', '/booking', '/booking-list'];
        const { auth } = this.props;
        const index = mainRouting.findIndex(e => window.location.pathname.indexOf(e) >= 0 ) + 1;
        if (index > 0) {
            this.setState({
                currentItem: index
            })
        }

        if (localStorage.getItem('semper_first_login')) {
            this.setState({
                welcomeOpened: true
            });
            localStorage.removeItem('semper_first_login')
        }
    }

    closeWelcome = () => {
        this.setState({
            welcomeOpened: false
        })
    }

    openWelcome = () => {
        this.setState({
            welcomeOpened: true
        })
    }


    render() {
        const { currentItem } = this.state;
        let token = window.location.pathname.split('/invite_token/')[1];
        if (!token) {
            token = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        }
        if (!token) {
            return (<div>Something went wrong</div>)
        }
        return(
            <>
                <div className="bg-white fullscreen">
                    <Switch>
                        <Route exact path="/invite_token/:token" component={() => <AnonymousSearch />} />
                        <Route path="/city/:id/invite_token/:token" component={(props) => <AnonymousSearch {...props} />} />
                        <Route path="/booking/:id/:date/:key" component={(props) => <InvitedGuestSupplier {...props} />} />
                        <Route component={NotFound} />
                    </Switch>
                </div>
                <BottomNavigation
                    value={currentItem}
                    onChange={this.changeMenu}
                    showLabels
                >
                    <BottomNavigationAction component={Link} to={'/invite_token/' + token} label="Search" icon={<LocationOnIcon />} />
                </BottomNavigation>
            </>

        )
    }
}

export default InvitedGuestApp;