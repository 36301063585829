import React from 'react';
import Login from './screens/login/index';
import Register from './screens/register/index';
import ForgotPassword from './screens/forgotpassword/index';
import paymentRedirect from './screens/supplier/paymentRedirect'
import ChangePassword from './screens/forgotpassword/ChangePassword';
import ConfirmAccount from './screens/confirm_account/index';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { Redirect, withRouter } from 'react-router-dom';
import NotFound from "./NotFound";
class Auth extends React.Component{



    render() {
        const { auth, isAuthPage } = this.props;
        return (
          <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/register/:token" component={Register} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/forgot_password" component={ForgotPassword} />
              <Route exact path="/password/reset/:token" component={ChangePassword} />
              <Route exact path="/confirmation/:user_id/:hash" component={ConfirmAccount} />
              <Route component={NotFound} />
          </Switch>
        );
    }
}

export default Auth;
