import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";
import { apiRequest } from '@services/Request';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { userActions } from '@actions/index';
import { getUser } from '@reducers/authentication'
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AddressForm from './AddressForm'
// import Toolbar from "../search";

class Addresses extends React.Component{


    state = {
        isLoading: true,
        countries: [],
        success: '',
        error: '',
        addresses: [],
        selectedAddress: 0,
        form: {
            firstname: '',
            lastname: '',
            phone_number: '',
        }
    }

    onChange = (e) => {
        var form = { ...this.state.form };
        form[e.target.name] = e.target.value;
        this.setState({
            form
        })
    }

    deleteAddress = (id) => {
        const { api_key } = this.props.auth;
        if (window.confirm('Are you sure you want remove this address?')) {
            apiRequest('user/address-delete/'+id, 'DELETE', null, api_key).then(request => {
                if (request.status === 200) {
                    this.getAddresses();
                }
            })
        }
    }


    getAddresses = () => {
        const { api_key } = this.props.auth;
        this.setState({
           isLoading: true
        });
        apiRequest('user/addresses', 'GET', null, api_key).then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    this.setState({
                        addresses: data.addresses
                    })
                })
            }
            this.setState({
                isLoading: false
            })
        })
    }
    componentDidMount() {
        const { user } = this.props.auth.user;
        const { api_key } = this.props.auth;
        apiRequest('user/address-details/0', 'GET', null, api_key).then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    this.setState({
                        countries: data.extra_data.countries
                    })
                })
            }
        })
        this.getAddresses();

    }

    addAddressToState = (address) => {
        var addresses = [ ...this.state.addresses ];
        addresses.push(address);
        this.setState({addresses})
    }
    handleClose = () => {
        this.setState({
            success: '',
            error: '',
        })
    }

    onSave = () => {
        const { api_key } = this.props.auth;
        const { dispatch } = this.props;
        this.setState({
            isLoading: true
        });
        apiRequest('user/update-profile', 'POST', this.state.form, api_key).then(request => {
            if (request.status === 200) {
                request.json().then(data => {
                    var auth = {...this.props.auth};
                    auth.user.user.firstname = this.state.form.firstname;
                    auth.user.user.lastname = this.state.form.lastname;
                    auth.user.user.phone_number = this.state.form.phone_number;
                    auth.access_token = auth.api_key;
                    this.setState({
                        success: 'Your account has been updated',
                        isLoading: false
                    });
                   setTimeout(() => {
                       dispatch(userActions.updateUserInfo(auth));
                   }, 1000)
                })

            }
            else{
                if (request.status === 401) {
                    // this.setState({error: 'Incorrect email or password'})
                }
                else if (request.status === 422) {
                    request.json().then(data => {
                        this.setState({
                            error: data.messages.join("\n")
                        })
                    })
                }
            }

            this.setState({
                isLoading:false
            })

        });
    }

    openEditDialog = () => {

    }

    render() {
        const { form, isLoading, success, error, addresses, countries } = this.state;
        if (isLoading || countries.length === 0) {
            return <div style={{height: '100%', display: 'flex',alignItems: "center", justifyContent: "center"}}><CircularProgress /></div>
        }
        return(
            <>
                {!!addresses.length && addresses.map(address => <>
                    <div className="address">
                        <div className="left">
                            <div className="title">{address.firstname + ' ' + address.lastname}</div>
                            {!!address.company && <div className="subtitle">{address.company}</div>}
                            <div className="subtitle">{address.street_1 + ' - ' + address.zipcode + ' ' + address.city + (address.country_name ? ' - '+ address.country_name: "")}</div>
                        </div>
                        <div className="right">
                            <CloseIcon onClick={() => this.deleteAddress(address.id)} fontSize="large" />
                        </div>
                    </div>
                </>)}
                {!addresses.length && <>
                <p>You don't have any saved addresses</p>
                </>}
                <br/>
                <Typography variant="h6">
                    Add new address
                </Typography>
                <AddressForm countries={countries} isNew={true} addAddressToState={this.addAddressToState} auth={this.props.auth} updateAddresses={this.getAddresses} />
                <Snackbar open={!!success}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="success">
                        {success}
                    </Alert>
                </Snackbar>
                <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </>
        )
    }
}


function mapStateToProps(state) {
    return {
        user: getUser(state),
    };
}

export default connect(mapStateToProps)(Addresses);
// export default PersonalInformation;
