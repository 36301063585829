import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiRequest } from '@services/Request';


class PaymentRedirect extends React.Component{


    state = {
        error: false,
        user: null,
        user_id: null,
        city_ids: null,
        api_key: null,
        loading: false,
        firstLoading: true,
        error_text: '',
    }


    send3DRequest = () => {
        const { user, user_id, city_ids, api_key, product_id } = this.state;
        const { auth } = this.props;

        let url = new URLSearchParams(window.location.search);
        let prefixUrlPayment = 'anonymous';
        let toSend = {
            payment_intent: url.get('payment_intent'),
            payment_intent_client_secret: url.get('payment_intent_client_secret'),
            source_redirect_slug: url.get('source_redirect_slug'),
            source_type: url.get('source_type'),
        }

        if (user !== null && user_id === null) {
            prefixUrlPayment = 'payment';
        }
        else{
            toSend['user_id'] = user_id;
        }

        if (this.props.url === 'validate-3dsecure-entrance') {
            prefixUrlPayment = 'payment-entrance';
        }

        if (this.props.url === 'validate-3dsecure-subscription') {
            prefixUrlPayment = 'premium';
            toSend['city_ids'] = city_ids.map(function(e){return parseInt(e)});
            toSend['product_id'] = product_id;
        }

        apiRequest(prefixUrlPayment + '/' + this.props.url, 'POST', toSend, api_key).then(responce => {
            if (responce.status === 200) {
                responce.json().then((data) => {
                    if (data.status) {
                        if (prefixUrlPayment === 'payment-entrance' && data.status === true) {
                            window.top.postMessage({
                                type: 'payment-done',
                                url: "/"
                            }, '*');
                        }
                        else if (prefixUrlPayment === 'premium') {
                            window.top.postMessage({
                                type: 'payment-done',
                                auth: auth
                            }, '*')
                        }
                        else{
                            if (typeof data.skip_second_payment != "undefined" && data.skip_second_payment) {
                                window.top.postMessage({
                                    type: 'define_first_booking',
                                    first_booking: data.show_dietary_popup + 0
                                }, '*');
                                if (user === null && user_id !== null) {
                                    let auth = { loading: false, api_key: data.access_token, loggedIn: false, user: {user: data.user} }; //todo make it better
                                    window.top.postMessage({
                                        type: 'payment-done-redirect',
                                        auth: auth
                                    }, '*')
                                }
                                else{
                                    window.top.postMessage({
                                        type: 'redirect',
                                        url: "/booking-list"
                                    }, '*');

                                }
                            }
                            else{
                                if (this.props.url === 'validate-3dsecure-capture') {
                                    window.top.postMessage({
                                        type: 'define_first_booking',
                                        first_booking: data.show_dietary_popup + 0
                                    }, '*');

                                    if (user === null && user_id !== null) {
                                        let auth = { loading: false, api_key: data.access_token, loggedIn: false, user: {user: data.user} }; //todo make it better
                                        window.top.postMessage({
                                            type: 'payment-done-redirect',
                                            auth: auth
                                        }, '*')
                                    }
                                    else{
                                        window.top.postMessage({
                                            type: 'redirect',
                                            url: "/booking-list"
                                        }, '*');
                                    }


                                }
                                else{
                                    let object = {
                                        type: 'payment-done',
                                        url: this.props.url
                                    };

                                    if (this.props.url === 'validate-3dsecure-guest') {
                                        object['nextUrl'] = data.redirect_url;
                                    }
                                    window.top.postMessage(
                                        object,
                                        '*'
                                    );
                                }
                            }
                        }

                    }
                    else{
                        let object = {
                            type: 'payment-error',
                            error_text: data.error_text
                        };
                        window.top.postMessage(
                            object,
                            '*'
                        );
                    }
                })

            }
        });
    }


    componentDidMount() {

        const handlerIframeEvent = event => {
            const { loading, error_text } = this.state;
            if (event.data.type === 'payment-error-update-message') {
                if (error_text !== event.data.error_text) {
                    this.setState({
                        error:true,
                        firstLoading:false,
                        error_text:event.data.error_text,
                    })
                }
            }
            else if (event.data.type === 'payment-transfer-data-response') {
                this.setState({
                    loading: false,
                    user: event.data.user,
                    user_id: event.data.user_id,
                    city_ids: event.data.city_ids,
                    product_id: event.data.product_id,
                    firstLoading:false,
                    api_key: event.data.api_key,
                });


                if (!loading) {
                    this.setState({
                        loading: true
                    })
                    this.send3DRequest();
                }

            }
        }

        window.addEventListener("message", handlerIframeEvent);

        window.parent.postMessage(
            {
                type: 'payment-transfer-data-request'
            },
            '*'
        );

    }


    render() {
        const { error, firstLoading, error_text } = this.state;
        if (firstLoading) {
            return <div className="child-by-center">
                <CircularProgress />
            </div>
        }
        return (
            <div className="child-by-center">
                <br/><br/><br/>
                {!error && <CircularProgress />}
                {error && <p style={{textAlight: 'center'}}>{error_text}</p>}
            </div>
        )
    }

}

export default PaymentRedirect;