import React from 'react';
import Banner from '@semper/Banner';
import Logo from '@images/logo_black_text.svg';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { withRouter } from 'react-router-dom';
import { apiRequest } from '@services/Request';
import CircularProgress from '@material-ui/core/CircularProgress';
import AnonymousSearchWrapper from './AnonymousSearchWrapper';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import './search.css'

class AnonymousSearch extends React.Component{

    state = {
        categories: [],
        searchByName: '',
        wasSearch: false,
        wasSearchByName: false,
        isLoading: true,
        searchByNameResult: [],
        cities:[],
        selectedCity:[],
        ourSelections: [],
        selectedCategory: 0,
        selected_city: [],
        selectedDate: new Date(),
        selectedCategoryByIndex: 0,
    }

    componentDidMount() {
        if (!this.props.match.params.token) {
            this.props.history.push('/login')
        }
        let cityId = typeof this.props.match.params.id != "undefined" ? this.props.match.params.id : false;
        if (cityId) {
            this.updateHomepage(parseInt(cityId), true);
            return false;
        }
        else if (window.localStorage.getItem('city_id')) {
            cityId = window.localStorage.getItem('city_id');
        }
        this.updateHomepage(parseInt(cityId), false);
    }

    updateHomepage = (cityId, activeSearch) => {
        let toSent = {};
        if (cityId) {
            toSent['city_id'] = cityId;
            window.localStorage.setItem('city_id', cityId);
        }
        apiRequest('anonymous/homepage', 'POST', { hash: this.props.match.params.token ,city_id: cityId}).then(request => {
            if (request.status === 200) {
                request.json().then(data => {
                    if (window.localStorage.getItem('category_index') && typeof data.data.categories[parseInt(window.localStorage.getItem('category_index'))] != "undefined") {
                        this.setState({
                            categories: data.data.categories,
                            selectedCategoryByIndex: parseInt(window.localStorage.getItem('category_index')),
                            selectedCategory: data.data.categories[parseInt(window.localStorage.getItem('category_index'))].id,
                            cities: data.data.cities,
                            selectedCity: (cityId && data.data.cities.findIndex(city => city.id === cityId) >= 0) ? cityId : data.data.default_city[0].id,
                            ourSelections: data.data.suppliers,
                            selected_city: (data.data.selected_city) ? data.data.selected_city : data.data.default_city[0],
                            isLoading: false,
                            wasSearch: activeSearch
                        })
                    }
                    else{
                        this.setState({
                            categories: data.data.categories,
                            selectedCategoryByIndex: 0,
                            selectedCategory: data.data.categories[0].id,
                            cities: data.data.cities,
                            selectedCity: (cityId && data.data.cities.findIndex(city => city.id === cityId) >= 0) ? cityId : data.data.default_city[0].id,
                            ourSelections: data.data.suppliers,
                            selected_city: (data.data.selected_city) ? data.data.selected_city : data.data.default_city[0],
                            isLoading: false,
                            wasSearch: activeSearch
                        })
                    }

                })
            }
        })
    }

    changeCategory = (e, index, seeAll) => {
        if (!this.state.categories.length) {
            return false;
        }
        window.localStorage.setItem('category_index', index);
        this.setState({
            selectedCategoryByIndex: index,
            searchLoading: true,
            selectedCategory: this.state.categories[index].id
        })
        if (typeof seeAll != "undefined") {
            this.setState({
                wasSearch: true
            });
            this.searchRedirect()
        }
    }

    searchRedirect = () => {
        const { selectedCity } = this.state;
        this.props.history.push('/city/'+ selectedCity + '/invite_token/' + this.props.match.params.token);
    }


    goToLink = (href) => {
        this.props.history.push(href + '/' + this.props.match.params.token);
    }

    searchByName = () => {
        const { searchByName } = this.state;
        if (searchByName) {
            this.setState({
                isLoading: true
            })

            apiRequest('anonymous/elastic-search/' + searchByName, 'POST', {hash: this.props.match.params.token}).then(responce => {
                if (responce.status === 200) {
                    responce.json().then(data => {
                        this.setState({
                            wasSearchByName: true,
                            isLoading: false,
                            searchByNameResult: data.results
                        })
                    })
                }
                else{
                    this.setState({
                        isLoading:false
                    })
                }

            })

        }
    }

    handleChangeSearch = (e) => {
        if (e.key === 'Enter') {
            this.searchByName();
        }
    }

    handleFilterByName = (e) => {
        this.setState({
            searchByName: e.target.value
        })
    }

    resetSarchByName = () => {
        this.setState({
            searchByName: '',
            wasSearchByName: false

        })
    }




    render() {
        const {
            categories,
            selectedDate,
            searchByName,
            ourSelections,
            selectedCategoryByIndex,
            cities, selectedCity,
            selectCityDialogIsOpen,
            isLoading, selectedCategory,
            searchLoading, searchResult,
            wasSearch, filterByName,
            selected_city, searchByNameResult, wasSearchByName } = this.state;
        if (isLoading) {
            return <div style={{display: 'flex',alignItems: "center", justifyContent: "center", height: "100%"}}><CircularProgress /></div>
        }

        return(
            <>
                <div className="searchHeader" style={{position:'relative'}}>
                    {!wasSearch && !wasSearchByName && <>
                        <img className="main-logo" src={Logo} />
                        <>
                            <OutlinedInput
                                placeholder="Search by name"
                                value={searchByName}
                                style={{
                                    height:35,
                                    fontSize:12
                                }}
                                onKeyDown={this.handleChangeSearch}
                                onChange={this.handleFilterByName}
                                variant="outlined"
                                endAdornment={
                                    <InputAdornment onClick={this.searchByName} position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                        </>
                    </>}
                    {!!wasSearch && <>
                        <ArrowBackIosIcon onClick={this.props.history.goBack} />
                        <span style={{position: 'absolute',left: 0, right: 0, textAlign: 'center', width: '80%',margin: 'auto', fontSize:18, fontWeight: 600}}>{cities.find(city => {return city.id === selectedCity}).name}</span>
                        <div></div>
                    </>}
                    {!!wasSearchByName && <>
                        <ArrowBackIosIcon onClick={this.resetSarchByName} />
                        <span style={{position: 'absolute',left: 0, right: 0, textAlign: 'center', width: '80%',margin: 'auto', fontSize:18, fontWeight: 600}}>Search</span>
                        <div></div>
                    </>}
                </div>
                {!wasSearch && <Banner bunnerText={(wasSearchByName) ? searchByName : '' } />}
                {wasSearch && <AppBar position="static" color="default">
                    <Tabs
                        className="customTabWrapper"
                        value={selectedCategoryByIndex}
                        onChange={this.changeCategory}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        {categories.map(category => {
                            return (<Tab className="customTab" label={category.name} />)
                        })}
                    </Tabs>
                </AppBar>}
                <AnonymousSearchWrapper
                    {...this.props}
                    categories={categories}
                    wasSearch={wasSearch}
                    updateHomepage={this.updateHomepage}
                    searchByNameResult={searchByNameResult}
                    wasSearchByName={wasSearchByName}
                    selected_city={selected_city}
                    goToLink={this.goToLink}
                    searchLoading={searchLoading}
                    searchRedirect={this.searchRedirect}
                    searchResult={searchResult}
                    ourSelections={ourSelections}
                    cities={cities}
                    selectedCategoryByIndex={selectedCategoryByIndex}
                    selectedCity={selectedCity}
                    changeCategory={this.changeCategory}
                />
            </>
        )
    }
}

export default withRouter(AnonymousSearch);