import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Portal from '@material-ui/core/Portal';
import Messages from './WelcomeInfoData';
import SwipeableViews from 'react-swipeable-views';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Box from '@material-ui/core/Box';
import NextArrow from '@images/nextArrow.svg'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{textAlign: props.textAlign, padding:'5px'}} p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}
class WelcomeInfo extends React.Component{

    state = {
        currentStep: 0,
        close: false

    }

    close = () => {
        this.setState({
            close: true
        })
    }

    goTo = (dir) => {
        this.setState({
            currentStep: this.state.currentStep + dir
        })
    }

    render() {
        const { currentStep, close } = this.state;
        return (
            <Portal>
                <Dialog fullScreen open={this.props.open}>
                    <div style={{background: '#2D3340', width: '100%', height:'100%',display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        <div
                            className="welcome-info-popup"
                            style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: 10,
                            color: 'white'
                        }}>
                            {currentStep > 0 && <ArrowBackIosIcon onClick={e => this.goTo(-1)} />}
                            <span></span>
                            <span onClick={this.props.closeWelcome} style={{textDecoration: 'underline', fontSize:15, margin: '5px 15px 0 0'}}>Skip</span>
                        </div>
                        <div>
                            <SwipeableViews disabled={true} axis="x" className="box-shadow-images" index={currentStep}>
                                {Messages[this.props.auth.user.user.type].map((element,i) => {
                                    return (<TabPanel key={i} textAlign={'center'} value={currentStep} index={i}>
                                        <img src={'/'+element.img +'.svg'} alt=""/>
                                    </TabPanel>)
                                })}
                            </SwipeableViews>
                        </div>
                        <div style={{minHeight: '40%',background: 'white', borderRadius: '30px 30px 0px 0px', padding:40, display: 'flex', flexDirection: 'column',justifyContent: 'space-between'}}>
                            <SwipeableViews disabled={true} axis="x" className="box-shadow-images" index={currentStep}>
                                {Messages[this.props.auth.user.user.type].map((element,i) => {
                                    return (<TabPanel key={i} textAlign={'left'} value={currentStep} index={i}><div style={{lineHeight: 1, fontWeight:600,fontSize:34, marginBottom:15}}>{element.title}</div><div style={{fontSize:16}}>{element.text}</div></TabPanel>)
                                })}
                            </SwipeableViews>
                            <div style={{display: 'flex',
                                justifyContent: 'inherit',
                                width: 90,
                                margin: '20px auto'}}>
                                {Messages[this.props.auth.user.user.type].map((e, i) => {
                                    if (currentStep < i) {
                                        return (<div key={i} style={{borderRadius: '50%',height: 10, width: 10, background:'#EDE6DD'}}></div>)
                                    }
                                    else{
                                        return (<div key={i} style={{borderRadius: '50%',height: 10, width: 10, background:'#2D3340'}}></div>)
                                    }
                                })}
                            </div>
                            {currentStep < (Messages[this.props.auth.user.user.type].length - 1)  && <div style={{
                                textAlign: 'right',
                                width: 97,
                                border: '1px solid',
                                borderRadius: 12,
                                padding: '8px 10px',
                                margin: '0 auto'
                            }} onClick={e => this.goTo(1)}>
                                <img src={NextArrow}/>
                            </div>}
                            {currentStep === (Messages[this.props.auth.user.user.type].length - 1)  && <div style={{
                                width: 97,
                                border: '1px solid',
                                textAlign: 'center',
                                background: '#2D3340',
                                color: 'white',
                                borderRadius: 12,
                                padding: '8px 10px',
                                margin: '0 auto'
                            }} onClick={this.props.closeWelcome}>
                                Get Started
                            </div>}
                        </div>
                    </div>
                </Dialog>
            </Portal>
        )
    }
}
export default WelcomeInfo;