import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";
import { apiRequest } from '@services/Request';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { userActions } from '@actions/index';
import { getUser } from '@reducers/authentication'
import CloseIcon from '@material-ui/icons/Close';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Mc from '@images/mc.svg';
import Ae from '@images/ae.svg';
import Card from '@images/card.svg'
import Visa from '@images/visa.svg';

class PaymentsCards extends React.Component{


    state = {
        isLoading: true,
        success: '',
        error: '',
        cards: [
            {
                name: 'Visa',
                last4: '1232',
                exp_month: '11',
                exp_year: '25',
                card_id: '123123123123qwe',
            }
        ],
        form: {
            firstname: '',
            lastname: '',
            phone_number: '',
        }
    }

    onChange = (e) => {
        var form = { ...this.state.form };
        form[e.target.name] = e.target.value;
        this.setState({
            form
        })
    }

    componentDidMount() {
        this.updateCardList();
    }

    updateCardList = () => {
        const { api_key } = this.props.auth;

        this.setState({
            isLoading: true
        });
        apiRequest('user/get-credit-cards', 'GET', null, api_key).then(responce => {
            if (responce.status === 200) {
                responce.json().then(data => {
                    console.log(data.credit_cards);
                    this.setState({
                        cards: data.credit_cards,
                        isLoading: false
                    })
                })
            }
        })
    }

    handleClose = () => {
        this.setState({
            success: '',
            error: '',
        })
    }

    onSave = () => {
        const { api_key } = this.props.auth;
        const { dispatch } = this.props;
        this.setState({
            isLoading: true
        });
        apiRequest('user/update-profile', 'POST', this.state.form, api_key).then(request => {
            if (request.status === 200) {
                request.json().then(data => {
                    var auth = {...this.props.auth};
                    auth.user.user.firstname = this.state.form.firstname;
                    auth.user.user.lastname = this.state.form.lastname;
                    auth.user.user.phone_number = this.state.form.phone_number;
                    auth.access_token = auth.api_key;
                    this.setState({
                        success: 'Your account has been updated',
                        isLoading: false
                    });
                   setTimeout(() => {
                       dispatch(userActions.updateUserInfo(auth));
                   }, 1000)
                })

            }
            else{
                if (request.status === 401) {
                    // this.setState({error: 'Incorrect email or password'})
                }
                else if (request.status === 422) {
                    request.json().then(data => {
                        this.setState({
                            error: data.messages.join("\n")
                        })
                    })
                }
            }

            this.setState({
                isLoading:false
            })

        });
    }

    deleteCard = (id) => {
        if (window.confirm('Are you sure you want delete this payment card?')) {
            const { api_key } = this.props.auth;
            this.setState({
                isLoading:true
            })
            apiRequest('user/credit-card-delete/' +id , 'DELETE', null, api_key).then(responce => {
                if (responce.status === 422) {
                    responce.json().then(data => {
                        this.setState({
                            error: data.messages.join("\n")
                        })
                    })
                }
                else if (responce.status === 200) {
                    this.updateCardList();
                }
            })
        }
    }

    render() {
        const { form, isLoading, success, error, cards } = this.state;
        if (isLoading) {
            return <div style={{display: 'flex',alignItems: "center", justifyContent: "center", height: "100%"}}><CircularProgress /></div>
        }
        return(
            <>
                <div className="cards-list">
                    {!!cards.length && <p className="subtitle">Registered Cards</p>}
                    {!!cards.length && <>
                        {cards.map(card => {
                            return (
                                <div className="address">
                                    <div className="left">
                                        <div className="title">{card.name}</div>
                                        <div className="subtitle">
                                            <div>
                                                {(card.type === 'MasterCard') && <img src={Mc} />}
                                                {(card.type === 'Visa') && <img src={Visa} />}
                                                {(card.type !== 'Visa' && card.type !== 'MasterCard') && <img src={Card} />}
                                                <span>XXXX XXX XXX {card.last4}</span>
                                            </div>
                                            <span className="date">{card.exp_month}/{card.exp_year}</span></div>
                                    </div>
                                    <div className="right">
                                        <CloseIcon onClick={() => this.deleteCard(card.id)} fontSize="large" />
                                    </div>
                                </div>
                            )
                        })}
                    </>}
                </div>
                <Snackbar open={!!success}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="success">
                        {success}
                    </Alert>
                </Snackbar>
                <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </>
        )
    }
}


function mapStateToProps(state) {
    return {
        user: getUser(state),
    };
}

export default connect(mapStateToProps)(PaymentsCards);
// export default PersonalInformation;
