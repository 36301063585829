import React from 'react';
import Portal from '@material-ui/core/Portal';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import ArrowBackIosIcon from "./calendar";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { apiRequest } from '@services/Request';
import { formatTime } from '@semper/FormatTime';
function range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class SuggestNewTime extends React.Component{


    state = {
        selectedHour: '',
        formWasChanged: false,
        selectedOption: '',
        new_price_per_guest: null,
    }

    suggest = () => {
        const { auth, hash } = this.props;
        const { selectedHour, selectedOption, formWasChanged, new_price_per_guest } = this.state;

        if (typeof auth == "undefined") {
            apiRequest('anonymous/bookings/suggest-time-booking/' + hash, 'POST', {new_price_per_guest: (!new_price_per_guest ? this.props.price : new_price_per_guest), booking_id: this.props.booking_id, hour: (selectedHour === '') ? this.props.oldTime : selectedHour, option_id: (formWasChanged) ? selectedOption : this.props.option_id}).then(response => {
                if (response.status === 200) {
                    this.props.handleCloseSuggestionPopup();
                }
                else{
                    alert('Something went wrong, please try later');
                }
            })
        }
        else{
            apiRequest('supplier/suggest-time-booking', 'POST', {new_price_per_guest: (!new_price_per_guest ? this.props.price : new_price_per_guest), booking_id: this.props.booking_id, hour: (selectedHour === '') ? this.props.oldTime : selectedHour, option_id: (formWasChanged) ? selectedOption : this.props.option_id}, auth.api_key).then(response => {
                if (response.status === 200) {
                    this.props.handleCloseSuggestionPopup();
                }
                else{
                    alert('Something went wrong, please try later');
                }
            })
        }

    }


    selectChange = (e) => {
        let { state } = this;
        state[e.target.name] = e.target.value;
        if (e.target.name === 'selectedOption') {
            state['formWasChanged'] = true
        }
        this.setState({...state})
    }


    render() {
        const { openPopup, oldTime, options } = this.props;
        const { selectedHour, selectedOption, formWasChanged, new_price_per_guest } = this.state;
        const suggestionHour = range(parseInt(oldTime.split(':')[0]), 22);
        return <Dialog TransitionComponent={Transition}
                keepMounted
                fullScreen
                open={openPopup}
               style={{inset: "40% 0 0",borderRadius: "3% 3% 0 0", overflow: "hidden"}}
                onClose={this.props.handleCloseSuggestionPopup}>
            <div style={{height: '100%', padding: 20}}>
                <span onClick={this.props.handleCloseSuggestionPopup}>x</span>
               <p className="popup-title">Reject and suggest another time / option for this booking</p>

                <div className="suggestion-row">
                    <div className="current-time">
                        <div className="old-time" style={{color: 'gray'}}>
                            Current time:
                        </div>
                    </div>
                    <div className="new-time">
                        <div className="new-time">
                            <div style={{fontWeight: 500, marginBottom: 10}}>New time suggested:</div>
                        </div>
                    </div>
                </div>

                <div className="suggestion-row">
                    <div className="flex-align-center">
                        <div>{this.props.oldTime}</div>
                    </div>
                    <div>
                        <FormControl variant="outlined" style={{width: '100%'}}>
                            <NativeSelect
                                id="suggestion_option"
                                value={selectedHour}
                                onChange={this.selectChange}
                                name="selectedHour"
                                input={<OutlinedInput />}
                                variant="outlined"
                                style={{width: '100%'}}
                            >
                                <option value="">Select time</option>
                                {suggestionHour.map(hour => {
                                    return <option value={hour + 1 + ":00"}>{hour + 1}:00</option>
                                })}
                            </NativeSelect>
                        </FormControl>
                    </div>
                </div>
                <br/><br/>
                <div className="suggestion-row">
                    <div className="current-time">
                        <div className="old-time" style={{color: 'gray'}}>
                            Current option:
                        </div>
                    </div>
                    <div className="new-time">
                        <div className="new-time">
                            <div style={{fontWeight: 500, marginBottom: 10}}>New option suggested:</div>
                        </div>
                    </div>
                </div>
                <div className="suggestion-row">
                    <div className="flex-align-center">
                        <div>{this.props.option_name}</div>
                    </div>
                    <div>
                        <FormControl variant="outlined" style={{width: '100%'}}>
                            <NativeSelect
                                id="suggestion_hours"
                                value={ (formWasChanged) ? selectedOption : this.props.option_id }
                                onChange={this.selectChange}
                                name="selectedOption"
                                input={<OutlinedInput />}
                                variant="outlined"
                                style={{width: '100%'}}
                            >
                                <option value="">Select option</option>
                                {options.map(option =>{

                                    return (
                                        option.options_variants.map(variant => {
                                            return <option value={variant.id}>{variant.option_name} ({formatTime(variant.start_hour)} - {formatTime(variant.end_hour)})</option>
                                        })
                                    )
                                })}

                            </NativeSelect>
                        </FormControl>
                    </div>
                </div>
                <br/><br/>
                <div className="suggestion-row">
                    <div className="current-time">
                        <div className="old-time" style={{color: 'gray'}}>
                            Current price/guest:
                        </div>
                    </div>
                    <div className="new-time">
                        <div className="new-time">
                            <div style={{fontWeight: 500, marginBottom: 10}}>New price</div>
                        </div>
                    </div>
                </div>
                <div className="suggestion-row">
                    <div className="flex-align-center">
                        <div>{this.props.price} {this.props.currency}</div>
                    </div>
                    <div>
                        <FormControl variant="outlined" style={{width: '100%'}}>
                            <TextField
                              id="new_price_per_guest"
                              type="number"
                              inputProps={{
                                  min: 0
                              }}
                              value={new_price_per_guest}
                              onChange={this.selectChange}
                              name="new_price_per_guest"
                              variant="outlined"
                              style={{width: '100%'}}
                              InputProps={{
                                  endAdornment: <InputAdornment position="end">{this.props.currency}</InputAdornment>,
                              }}
                            >
                            </TextField>
                        </FormControl>
                    </div>
                </div>
                
                <p style={{fontSize:12, color: 'gray', textAlign: 'center'}}>By suggesting a new hour, you will reject the current booking, and send this informative message to the guest</p>
                <div className="button child-by-center">
                    <Button onClick={this.suggest}
                            disabled={
                                (formWasChanged && selectedOption === '') ||
                                (formWasChanged === false && (selectedHour === '' && !new_price_per_guest))
                            }
                            className="blackButton">Suggest</Button>
                </div>
            </div>
        </Dialog>
    }
}



export default SuggestNewTime;