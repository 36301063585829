import React from 'react';
import Button from '@material-ui/core/Button';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div style={{padding: 15}} className="bg-main-color">
          <h3>Something went wrong.</h3>
          <h4>Soon we fix the error</h4>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
            <br/>
            <br/>
            <div className="child-by-center">
              <Button onClick={(e) => window.location.href = '/'}>Reload the app</Button>
            </div>

        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary