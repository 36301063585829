import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import BookingInfo from '@semper/BookingInfo';
import {apiRequest} from "@services/Request";
import { generateBookingConditions } from '@services/Date';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Portal from '@material-ui/core/Portal';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import Payment from '../supplier/payment';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class BookingView extends React.Component{
    state = {
        cancelingForm: false,
        isFoundBooking: false,
        isLoading: false,
        booking: {},
        history: {},
        cancelingAmountToPay:0,
        selectedRate: 0,
        message: '',
        error: '',
        variables: {},
        amount_percent: 100,
        submittingForm: false,
        openPayemntDialog: false,
        success: false,
        cards: []
    }

    componentDidMount() {
        const { match, auth, allBookings } = this.props;
        if (match.params.id) {
            const bookingId = parseInt(match.params.id);
            if (bookingId) {
                const foundedIndexBooking = allBookings.findIndex(booking => {
                    return booking.id === bookingId
                })
                if (foundedIndexBooking >= 0) {
                    this.setState({
                        isLoading: true,
                    })
                    apiRequest('booking/booking-history/'+ bookingId, 'GET' , null, auth.api_key).then(responce => {
                        if (responce.status === 200) {
                            responce.json().then(data => {
                                this.setState({
                                    history: data.records,
                                    isFoundBooking: true,
                                    isLoading: false,
                                    booking: allBookings[foundedIndexBooking]
                                })
                            })
                        }
                    })

                    apiRequest('settings/list', 'GET', null, auth.api_key).then(responce => {
                        if (responce.status === 200) {
                            responce.json().then(data => {
                                this.setState({
                                    loading: false,
                                    variables: data.data
                                })
                            })
                        }
                    })
                }
            }
        }
    }

    cancelBooking = () => {
        const {api_key} = this.props.auth;
        const { selectedRate, message, booking, error } = this.state;
        this.setState({
            error: '',
            submittingForm: true
        })
        apiRequest('booking/cancel', 'POST', {booking_id: booking.id}, api_key).then(responce => {
            if (responce.status === 422) {
                responce.json().then(data => {
                    this.setState({
                        error: data.messages.join("\n"),
                        submittingForm: false
                    })
                })
            }
            else if (responce.status === 200) {
                responce.json().then(data => {
                    if (typeof data.extra_data.amount_to_pay != "undefined") {
                        this.setState({
                            error: '',
                            cancelingForm: true,
                            submittingForm: false,
                            cancelingAmountToPay: data.extra_data.amount_to_pay,
                            amount_percent: data.extra_data.amount_percent,
                        })
                    }

                })
            }
            else if (responce.status === 500) {
                this.setState({
                    error: "Something went wrong",
                    submittingForm: false
                })
            }
        })
    }
    sendFeedback = () => {
        const {api_key} = this.props.auth;
        const { selectedRate, message, booking, error, submittingForm } = this.state;
        this.setState({
            error: '',
            success: ''
        })
        apiRequest('booking/feedback', 'POST', {booking_id:booking.id, rate: selectedRate, comment: message  }, api_key ).then(responce => {
            if (responce.status === 422) {
                responce.json().then(data => {
                    this.setState({
                        error: data.messages.join("\n")
                    })
                })
            }
            else if (responce.status === 200) {
                apiRequest('booking/bookings', 'GET' , null, api_key).then(request => {
                    if (request.status === 200) {
                        request.json().then(data => {
                            this.setState({
                                bookings: data.records,
                                loading:false,
                                success: 'Success',
                                cancelingForm:false,
                                opendBooking: false
                            })
                            window.location.href = '/booking-list'; //todo change it
                        })
                    }
                })
            }
        })
    }


    confirmCancel = () => {
        const {api_key} = this.props.auth;
        const { selectedRate, message, booking, error } = this.state;
        this.setState({
            error: '',
            submittingForm: true
        })
        apiRequest('booking/confirm-cancel', 'POST', {booking_id: booking.id}, api_key).then(responce => {
            if (responce.status === 422) {
                responce.json().then(data => {
                    this.setState({
                        error: (typeof data.message != "undefined") ? data.message :  data.messages.join("\n"),
                        submittingForm: false
                    })
                })
            }
            else if (responce.status === 200) {
                apiRequest('booking/bookings', 'GET' , null, api_key).then(request => {
                    if (request.status === 200) {
                        request.json().then(data => {
                            this.setState({
                                bookings: data.records,
                                loading:false,
                                success: 'Success',
                                cancelingForm: false,
                                opendBooking: false
                            });

                            this.props.history.push('/booking-list');
                        })
                    }
                })
            }
        })
    }

    findDateForCanceling = () => {
        const { amount_percent, variables, booking } = this.state;
        var currentDate = new Date();
        var result = '';
        let test = generateBookingConditions(booking.date.replace(/-/g,"/") + " " + booking.hour, variables);
        if (amount_percent === 50) {
            currentDate.setHours(currentDate.getHours() - variables.min_hours_cancel_50percent_fees);
            result = 'Before ' + test.fees50;

        }
        else if (amount_percent === 100){
            currentDate.setHours(currentDate.getHours() - variables.min_hours_cancel_50percent_fees);
            result = 'After ' + test.fees50;
        }
        else{
            currentDate.setHours(currentDate.getHours() - variables.min_hours_cancel_no_fees);
            result = 'Before ' + test.no_fees;
        }


        return result;
    }

    handleClose = () => {
        this.setState({
            error: '',
            success: ''
        })
    }

    changeMessage = (e) => {
        this.setState({message: e.target.value})
    }

    selectRate = (rate) => {
        this.setState({
            selectedRate: rate
        })
    }

    hideCancelForm = () => {
        this.setState({
            cancelingForm: false
        })
    }

    pay = () => {
        const { api_key } = this.props.auth;
        this.setState({
            isLoading: true
        })

        apiRequest('user/get-credit-cards', 'GET', null, api_key).then(responce => {
            if (responce.status === 200) {
                responce.json().then(data => {
                    this.setState({
                        cards: data.credit_cards,
                        openPayemntDialog: true,
                        isLoading: false
                    })
                })
            }
        })
    }

    handleClosePaymentDialpg = () => {
        this.setState({
            openPayemntDialog: false
        })
    }

    render() {
        const { cancelingForm, isFoundBooking, booking,
                selectedRate, message, error,
                cancelingAmountToPay, submittingForm, history,
                amount_percent, success, isLoading, openPayemntDialog, cards } = this.state;
        const { chatsChannels } = this.props;
        if (isLoading) {
            return <div className="child-by-center">
                <CircularProgress />
            </div>
        }
        if (!isFoundBooking) {
            return <div className="child-by-center">
                <p>Booking not found</p>
            </div>
        }
        return (
            <div className="booking-card">
                {!cancelingForm && <>
                    <BookingInfo auth={this.props.auth}
                                 fees_amount={booking.fees_amount}
                                 lastAuthorizationStatus={booking.lastAuthorizationStatus}
                                 unreadMessagesCount={(chatsChannels[booking.hash] !== undefined) ? chatsChannels[booking.hash].countUnreadMesages : 0}
                                 showChatIcon={(booking.hash && chatsChannels[booking.hash] !== undefined)}
                                 currency={booking.currency.toUpperCase()}
                                 cancelBooking={booking.canCancel ? this.cancelBooking : false}
                                 paymentHandler={this.pay}
                                 confirmBookingDate={booking.confirmBookingDate}
                                 inBookingPage={true} supplier={booking.supplier}
                                 openChat={(e) => this.props.openChat(booking)}
                                 booking_is_ready={booking.bookingIsReady}
                                 canShowConfirmButton={booking.canShowConfirmButton}
                                 booking={{supplier_conditions: booking.supplier.booking_conditions, hours: booking.hour,serviceName: booking.supplier_option !== null ? booking.supplier_option.option.name : '', date: booking.date.replace(/-/g,"/"), guests: booking.nb_guests, price: booking.price_per_guest}} />
                    {booking.canFeedback &&
                        <div className="reviewForm">
                            <p style={{textAlign: 'center', fontSize: 16, fontWeight: 600}}>Leave a feedback</p>
                            <p style={{fontSize:14, fontWeight: 600}}>What’s your feeling ? </p>
                            <div className="rating">
                                {[1,2,3,4,5].map(rate => {
                                    return (<div onClick={() => this.selectRate(rate)} className={(selectedRate === rate) ? 'selected': ''} >{rate}</div>)
                                })}
                            </div>
                            <div style={{fontSize:12, color: '#797979',display: 'flex', justifyContent: 'space-between', marginTop: 5}}>
                                <span>Bad experience </span>
                                <span>Good experience </span>
                            </div>
                            <br/>
                            <div>
                                <label htmlFor="message">Message</label>
                                <textarea
                                    name="message"
                                    rows="5"
                                    onChange={this.changeMessage}
                                    className="textarea"
                                >{message}</textarea>
                            </div>
                            <br/>
                            <div className="child-by-center">
                                <Button onClick={this.sendFeedback} className="blackButton" style={{minWidth: 230}}>Send</Button>
                            </div>
                        </div>
                    }
                    <br/>
                    {!!history.length && <div className="child-by-center" style={{flexDirection: 'column'}}>
                        <br/>
                        <div style={{textAlign: 'center', fontSize: 18, fontWeight: 600, marginBottom:10}}>History</div>
                        {history.map(story => {
                            return (<div style={{clear: 'both', color: '#797979', fontSize:16, marginBottom:5}}>{story.description}</div>)
                        })}
                    </div>}
                </>}
                {cancelingForm && <div className="wrapperBooking">
                    <p style={{textAlign: 'center', fontSize: 18, fontWeight: 600}}>Confirm cancellation ?</p>
                    <p style={{textAlign: 'center', fontSize: 14, fontWeight: 600}}>Cancellation conditions</p>
                    <div style={{display: 'flex', justifyContent: 'space-between', fontSize:11, color: '#797979', padding: 10}}>
                        <span>{this.findDateForCanceling()}</span>
                        <span>{amount_percent+'%'} ({cancelingAmountToPay} {booking.currency.toUpperCase()} )</span>
                    </div>
                    <div className="generalInfo child-by-center">
                        <span style={{fontSize:14, fontWeight:600}}>You will be charged {cancelingAmountToPay} {booking.currency.toUpperCase()} </span>
                    </div>
                    <br/><br/>
                    {submittingForm && <>
                        <div className="child-by-center"> <CircularProgress /></div>
                    </>}
                    {!submittingForm && <>
                        <div className="child-by-center"><Button onClick={this.hideCancelForm} className="blackButton" style={{minWidth: 288}}>No</Button></div>
                        <br/>
                        <div className="child-by-center"><Button onClick={this.confirmCancel} className="blackButton" style={{minWidth: 288}}>Yes I confirm - Pay {cancelingAmountToPay} {booking.currency.toUpperCase()} </Button></div>
                    </>}
                </div>}
                <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>
                <Snackbar open={!!success}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="success">
                        {success}
                    </Alert>
                </Snackbar>
                <Portal>
                    <Dialog TransitionComponent={Transition} keepMounted fullScreen open={openPayemntDialog} onClose={this.handleClosePaymentDialpg}>
                        <div className="searchHeader">
                            <span style={{    position: 'absolute',left: 0, right: 0, textAlign: 'center', width: '80%',margin: 'auto'}}>Payment Settings</span>
                            <CloseIcon onClick={this.handleClosePaymentDialpg} />
                        </div>
                        <div style={{minHeight: 71,display: 'flex', alignItems: 'center',justifyContent: 'center',fontSize:20,color: '#f7f7f7', background: 'rgb(45, 51, 64)'}}>
                            Payment {booking.pledge_amount + ' ' + booking.currency.toUpperCase()}
                        </div>
                        <div style={{padding: 5}}>
                            <Payment
                                total={'Pledge ' + booking.pledge_amount + ' ' + booking.currency.toUpperCase()}
                                bookingId={booking.id}
                                fees={0}
                                auth={this.props.auth}
                                booking_is_ready={booking.bookingIsReady}
                                currency={booking.currency.toUpperCase()}
                                cards={cards} />
                        </div>
                    </Dialog>
                </Portal>
            </div>
        )
    }
}

export default BookingView;