import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";
import { apiRequest } from '@services/Request';
import Snackbar from '@material-ui/core/Snackbar';
import Portal from '@material-ui/core/Portal';
import Alert from '@material-ui/lab/Alert';

class GuestRegister extends React.Component{

    state = {
        registred: false,
        isLoading: false,
        wasInvated: false,
        token: '',
        invitation: {},
        form: {
            firstname: '',
            lastname: '',
            email: '',
            phone_number: '',
            password: '',
            password_confirmation: '',
        },
        error: ''
    }

    onChange = (e) => {
        var form = { ...this.state.form };
        form[e.target.name] = e.target.value;
        this.setState({
            form
        })
    }

    registerClick = () => {
        const { dispatch } = this.props;
        const { wasInvated, token } = this.state;
        var toSend = {...this.state.form};
        this.setState({
            isLoading:true
        })
        toSend['hash'] = this.props.hash;
        toSend['hour'] = this.props.hour;
        toSend['id'] = this.props.id;
        toSend['date'] = this.props.date;
        toSend['nb_guests'] = this.props.nb_guests;
        apiRequest('anonymous/create-account', 'POST', toSend).then(request => {
            if (request.status === 200) {
                request.json().then(data => {
                    this.setState({
                        registred: true
                    });
                    this.props.afterRegister(data);
                })

            }
            else{
                if (request.status === 401) {
                    this.setState({error: 'Incorrect email or password'})
                }
                else if (request.status === 422) {
                    request.json().then(data => {
                        this.setState({
                            error: data.messages.join("\n")
                        })
                    })
                }
            }

            this.setState({
                isLoading:false
            })

        });
    }

    render() {
        const { error, form, isLoading } = this.state;
        return (
            <div>
                <Grid className="fullscreen" justify="center" container alignItems="center" spacing={1}>
                    <Grid item xs={11} spacing={1}>
                        <br/><br/>
                        <Grid justify="center" container alignItems="center" spacing={1}>
                            <Grid item xs={12} spacing={1}>
                                <TextField
                                    placeholder="First name"
                                    name="firstname"
                                    onChange={this.onChange}
                                    value={form.firstname}
                                    variant="outlined"
                                    fullWidth
                                />
                                <br/><br/>
                                <TextField
                                    placeholder="Last name"
                                    name="lastname"
                                    onChange={this.onChange}
                                    value={form.lastname}
                                    variant="outlined"
                                    fullWidth
                                />
                                <br/><br/>
                                <TextField
                                    placeholder="Email"
                                    name="email"
                                    onChange={this.onChange}
                                    value={form.email}
                                    variant="outlined"
                                    fullWidth
                                />
                                <br/><br/>
                                <TextField
                                    placeholder="Phone"
                                    name="phone_number"
                                    onChange={this.onChange}
                                    value={form.phone_number}
                                    variant="outlined"
                                    fullWidth
                                />
                                <br/><br/>
                                <TextField
                                    placeholder="Password"
                                    name="password"
                                    onChange={this.onChange}
                                    value={form.password}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                />
                                <br/><br/>
                                <TextField
                                    placeholder="Password confirmation"
                                    name="password_confirmation"
                                    onChange={this.onChange}
                                    value={form.password_confirmation}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                />
                                <br/><br/>
                                <div style={{textAlign: "center"}}><Button className="blackButton book-btn" disabled={isLoading} onClick={this.registerClick} variant="contained">
                                    {!isLoading && <>Register</>}
                                    {isLoading && <CircularProgress size={29}  />}
                                </Button></div>
                                <br/><br/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Portal>
                    <Snackbar open={!!error}  autoHideDuration={7000} onClose={this.handleClose}>
                        <Alert severity="error">
                            <div dangerouslySetInnerHTML={{__html: error }}></div>
                        </Alert>
                    </Snackbar>
                </Portal>
            </div>
        );
    }
}

export default GuestRegister