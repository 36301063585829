import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import BookingInfo from '@semper/BookingInfo';
import Payment from './payment';
import GuestRegister from './register';
import InvitedPayment from './invitedPayment';
import Conditions from '@images/conditions.svg';
import { apiRequest } from '@services/Request';
import CircularProgress from '@material-ui/core/CircularProgress';
import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { formatTime } from '@semper/FormatTime';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{padding:'5px'}} p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

class invitedBooking extends React.Component{
    state = {
        booking_is_ready: false,
        currentStep: 0,
        options: [],
        selectedHours: null,
        hours_options: [],
        price: null,
        countries: [],
        userId: 0,
        must_be_approved: false,
        loadingBook: false,
        loadingConfirmBooking: false,
        bookingId: '',
        error: '',
        commission: null,
        cards: [],
        hour: null,
        can_book: false,
        titles: ['Booking','Booking', 'Booking', 'Payment Settings']
    }

    nextStep = () => {
        this.setState({
            currentStep: this.state.currentStep + 1
        })
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.hourRange.length) {
            setTimeout(e => {
                if (this.state.selectedHours === null) {
                    this.book(formatTime(this.props.selectedHours));
                }
                else{
                    this.book(this.state.selectedHours);
                }
            }, 200)

        }
    }

    stepBack = () => {
        this.setState({
            currentStep: this.state.currentStep - 1
        })
    }
    handleChangeIndex = (index) => {
        this.setState({
            currentStep: index
        })
    }
    book = (selectedHours) => {
        const { auth } = this.props;
        let minGuests = 1;
        let selectedVariantObject;
        this.setState({loadingBook: true});
        if (this.props.selectedVariant) {
            selectedVariantObject = [].concat.apply([], this.props.options.map(option => {return option.options_variants})).find(variant => { return variant.id == this.props.selectedVariant});
            minGuests = selectedVariantObject.nb_users_min;
            // guestNumberList = Array.from({length: 7}, (_, i) => i +  this.props.options.find(option => { return option.option_id === this.props.selectedOption}).options_variants.find(variant => { return variant.id == this.props.selectedVariant}).nb_users_min + 1)
        }
        else{
            selectedVariantObject = this.props.options.find(option => { return option.option_id === this.props.selectedOption}).options_variants[0];
            minGuests = selectedVariantObject.nb_users_min;
            // guestNumberList = Array.from({length: 7}, (_, i) => i +  this.props.options.find(option => { return option.option_id === this.props.selectedOption}).options_variants[0].nb_users_min + 1)
        }
        const currentSelectedGuests = (this.props.selectedGuests) ? this.props.selectedGuests : minGuests;
        if (this.props.selectedVariant) {
            apiRequest('anonymous/book', 'POST', {hash: this.props.match.params.key, hour: selectedHours, id: this.props.selectedVariant, date: this.props.date.replace(/\//g,"-"), nb_guests: currentSelectedGuests}).then(responce => {
                if (responce.status === 200) {
                    responce.json().then((data) => {
                        this.setState({
                            must_be_approved: data.must_be_approved,
                            booking_is_ready: data.booking_is_ready,
                            can_book: data.can_book,
                            hours_options: data.hours_options,
                            commission: data.commission,
                            price: data.data.price + '',
                            loadingBook: false
                        })

                        if (!this.state.selectedHours || data.hours_options.find(option => option.hour === selectedHours) === undefined) {
                            this.setState({
                                selectedHours: data.hours_options[0].hour,
                            });
                        }
                    })
                }
                else{
                    responce.json().then((data) => {
                        this.setState({
                            loadingBook: false,
                            can_book: false,
                            error: data.message
                        })
                    });

                }
            })
        }
    }

    handleClose = () => {
        this.setState({
            success: '',
            error: '',
        })
    }

    confirmBooking = () => {
        const { auth, selectedHours, hash } = this.props;
        let minGuests = 1;
        let selectedVariantObject;
        this.setState({
            loadingConfirmBooking: true
        })
        if (this.props.selectedVariant) {
            selectedVariantObject = [].concat.apply([], this.props.options.map(option => {return option.options_variants})).find(variant => { return variant.id == this.props.selectedVariant});
            minGuests = selectedVariantObject.nb_users_min;
            // guestNumberList = Array.from({length: 7}, (_, i) => i +  this.props.options.find(option => { return option.option_id === this.props.selectedOption}).options_variants.find(variant => { return variant.id == this.props.selectedVariant}).nb_users_min + 1)
        }
        else{
            selectedVariantObject = this.props.options.find(option => { return option.option_id === this.props.selectedOption}).options_variants[0];
            minGuests = selectedVariantObject.nb_users_min;
        }
        const currentSelectedGuests = (this.props.selectedGuests) ? this.props.selectedGuests : minGuests;
        this.setState({
            loadingConfirmBooking: false,
            currentStep: this.state.currentStep + 1
        })
    }

    handleCloseBookingDialog = () => {
        this.setState({currentStep: 0});
        this.props.handleCloseBookingDialog();
    }

    subscribe = () => {
        window.localStorage.setItem('supplier_id', this.props.supplier.supplier_id);
        window.localStorage.setItem('booking_date', this.props.date);
        window.localStorage.setItem('selected_booking_id', this.props.selectedOption);
        window.localStorage.setItem('selected_booking_variant_id', this.props.selectedVariant);
        window.location.href = '/register/' + this.props.match.params.key;
    }

    afterRegister = (data) => {
        this.setState({
            userId: data.user_id,
            countries: data.extra_data.countries,
            bookingId: data.booking_id,
            cards: data.extra_data.credit_cards
        })
        this.nextStep();
    }

    calcPayment = (total, commission) => {
        let calcTotal = total;
        if (commission) {
            calcTotal = calcTotal + (calcTotal * (commission / 100));
        }

        return calcTotal.toFixed(2).replace('.00','');
    }

    calcFees = (total, commission) => {
        let fees = 0;
        if (commission) {
            fees = (total * (commission / 100));
        }

        return fees.toFixed(2).replace('.00','');
    }

    changeHours = (e) => {
        this.setState({
            selectedHours: e.target.value
        });

        this.book(e.target.value);
    }

    render() {
        const { openBookingPopup, currency } = this.props;
        const { currentStep, commission, titles, cards, bookingId,
                loadingBook, loadingConfirmBooking, error, must_be_approved, can_book,
                countries, userId, price, hours_options, selectedHours, booking_is_ready } = this.state;
        if (!this.props.selectedOption || price === null || hours_options.length === 0) {
            return <></>
        }

        let minGuests = 1;
        let selectedVariantObject;
        if (this.props.selectedVariant) {
            selectedVariantObject = [].concat.apply([], this.props.options.map(option => {return option.options_variants})).find(variant => { return variant.id == this.props.selectedVariant});
            minGuests = selectedVariantObject.nb_users_min;
        }
        else{
            selectedVariantObject = this.props.options.find(option => { return option.option_id === this.props.selectedOption}).options_variants[0];
            minGuests = selectedVariantObject.nb_users_min;
        }
        const guestNumberList = Array.from({length: 7}, (_, i) => i +  minGuests);
        const currentSelectedGuests = (this.props.selectedGuests) ? this.props.selectedGuests : minGuests;
        const fees = this.calcFees(price * currentSelectedGuests, commission);
        return(<>
            <Dialog TransitionComponent={Transition} keepMounted fullScreen open={openBookingPopup} onClose={this.props.handleCloseBookingDialpg}>
                <div className="searchHeader">
                    {!!currentStep && <ArrowBackIosIcon onClick={this.stepBack} />}
                    {!currentStep && <div> </div>}
                    <span style={{    position: 'absolute',left: 0, right: 0, textAlign: 'center', width: '80%',margin: 'auto'}}>{titles[currentStep]}</span>
                    <CloseIcon onClick={this.handleCloseBookingDialog} />
                </div>
                <div style={{minHeight: 71,display: 'flex', alignItems: 'center',justifyContent: 'center',fontSize:20,color: '#f7f7f7', background: 'rgb(45, 51, 64)'}}>
                    { (currentStep <= 1) ? this.props.supplier.name : ( booking_is_ready ? 'Payment '+(this.calcPayment(price * currentSelectedGuests, commission))+" " +currency : 'Payment ' + (fees) + ' ' + currency + ' (fees)')}
                    { (currentStep === 2) ? 'Personal information' : '' }
                </div>
                <div style={{padding: 5}}>
                    <SwipeableViews
                        axis='x'
                        index={currentStep}
                        disabled={true}
                    >
                        <TabPanel value={currentStep} index={0}>
                            <FormControl variant="outlined" style={{width: '100%', marginBottom: 15}}>
                                <Select
                                    id="date"
                                    value={this.props.date}
                                    required
                                    name="date"
                                    onChange={this.props.toHomepage}
                                    fullWidth
                                >
                                    <MenuItem fullWidth value={this.props.date}>{this.props.date}</MenuItem>
                                    <MenuItem fullWidth value={20} >Modify</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" style={{width: '100%', marginBottom: 15}}>
                                <Select
                                    id="option"
                                    value={this.props.selectedOption}
                                    required
                                    name="option"
                                    onChange={this.props.changeService}
                                    fullWidth
                                >
                                    {this.props.options.map(option => {
                                        return (
                                            <MenuItem fullWidth value={option.option_id}>{option.option_name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <br/>
                            <FormControl variant="outlined" style={{width: '100%', marginBottom: 15}}>
                                <Select
                                    id="hours"
                                    value={selectedHours}
                                    required
                                    name="hour"
                                    onChange={this.changeHours}
                                    fullWidth
                                >
                                    {hours_options.map(hour => {
                                        return (
                                            <MenuItem fullWidth value={hour.hour}>{(hour.hour)} ({hour.price} {currency})</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <br/>
                            {/*<label htmlFor="guests">Guests</label>*/}
                            <FormControl variant="outlined" style={{width: '100%', marginBottom: 15}}>
                                <Select
                                    labelId="guests"
                                    id="guests"
                                    value={currentSelectedGuests}
                                    required
                                    name="guests"
                                    onChange={this.props.changeNumberGuests}
                                    fullWidth
                                >
                                    {guestNumberList.map(number => {
                                        return (
                                            <MenuItem fullWidth value={number}>{number} guests</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <br/>



                            <div className="totalBlock">
                                <div className="line grand-total">
                                    <div className="left">Minimum spending :</div>
                                    <div className="right">{currentSelectedGuests} x {price.replace('.00', '')} {currency}  -  {((price * currentSelectedGuests)).toFixed(2).replace('.00','')} {currency} </div>
                                </div>
                                {(commission !== null) && (commission !== 0) && <div>
                                    <div>
                                        <div className="commission_plan">
                                            <div>
                                                <b>Not member</b>
                                                <span>Service fees ({commission}%)</span>
                                            </div>
                                            <span className="price">
                                            <span>
                                                {((price * currentSelectedGuests) * (commission / 100)).toFixed(2).replace('.00','')} {currency}
                                            </span>
                                        </span>
                                        </div>

                                        <span onClick={this.subscribe} className="upgrade-plan">Or upgrade to</span>
                                    </div>

                                    <div className="commission_plan premium" onClick={this.subscribe}>
                                        <div>
                                            <b>Register and subscribe to premium!</b>
                                            <span>Free Service fees</span>
                                        </div>
                                        <span className="price">
                                            <span className="old-price">
                                                {((price * currentSelectedGuests) * (commission / 100)).toFixed(2).replace('.00','')} {currency}
                                            </span>
                                            <span>0 {currency} </span>
                                        </span>
                                    </div>
                                </div>}
                            </div>

                            {this.props.booking_conditions.replace(/<[^>]*>?/gm, '') && <><p style={{fontSize: 16, color:'#2D3340',marginBottom: 0}}>Supplier conditions</p>
                                <div className="supplier-conditional blockWithImage">
                                    <img src={Conditions} />
                                    <div dangerouslySetInnerHTML={{__html: this.props.booking_conditions}}></div>
                                </div></>}
                            {must_be_approved && <span style={{fontSize:12, color: '#797979'}}>Short time before event, be aware that supplier can reject this booking</span>}
                            <br/><br/>
                            <div className="child-by-center">
                                <Button disabled={(loadingBook || !can_book)} onClick={this.nextStep} className="blackButton book-btn">
                                    {!loadingBook && <span>Book</span>}
                                    {!!loadingBook && <CircularProgress size={29} style={{color:'white'}} />}
                                </Button>
                            </div>
                        </TabPanel>
                        <TabPanel value={currentStep} index={1}>
                            {must_be_approved && <span style={{fontSize:12, color: '#797979',marginBottom: 10,display: 'block',textAlign: 'center'}}>Short time before event, be aware that supplier can reject this booking</span>}
                            <BookingInfo currency={currency} commission={commission} auth={this.props.auth} booking={{hours: selectedHours,serviceName: this.props.options[this.props.options.findIndex(option => {return option.option_id == this.props.selectedOption})].option_name, date: this.props.date, guests: currentSelectedGuests, price: price}} />
                            <div className="child-by-center">
                                <Button disabled={loadingConfirmBooking} onClick={this.confirmBooking} className="blackButton book-btn">
                                    {booking_is_ready === true && <>Confirm and pay</>}
                                    {booking_is_ready === false && <>Accept and pay fees</>}
                                </Button>
                            </div>
                        </TabPanel>
                        <TabPanel value={currentStep} index={2}>
                            <GuestRegister hour={selectedHours} id={this.props.selectedVariant} date={this.props.date.replace(/\//g,"-")} nb_guests={currentSelectedGuests}  hash={this.props.match.params.key} afterRegister={this.afterRegister} nextStep={this.nextStep} />
                        </TabPanel>
                        <TabPanel value={currentStep} index={3}>
                            <InvitedPayment
                                userId={userId}
                                countries={countries}
                                hash={this.props.match.params.key}
                                total={(((price * currentSelectedGuests) * (commission / 100)).toFixed(2).replace('.00',''))+ currency + ' + Pledge '+((price * currentSelectedGuests).toFixed(2).replace('.00',''))+ ' ' +currency}
                                bookingId={bookingId}
                                fees={fees}
                                booking_is_ready={booking_is_ready}
                                currency={currency}
                                auth={this.props.auth}
                                cards={cards} />
                        </TabPanel>
                    </SwipeableViews>
                </div>
            </Dialog>
            <Portal>
                <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </Portal>
        </>)
    }
}

export default invitedBooking;
