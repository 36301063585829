const deviceIsiOS = () =>  {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(window.navigator.platform)
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const deviceHelper = {
    deviceIsiOS
}