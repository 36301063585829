import {apiRequest} from "@services/Request";
import { chatActions } from '@actions/chat';


const saveChannelId = (auth, channelId, bookingId) => {
    apiRequest('booking/set-chatkitty-channel-id', 'POST', {booking_id: bookingId, chatkitty_channel_id: channelId}, auth.api_key).then(responce => {

    });
}

const saveChannelIdAnonymous = (channelId, bookingId, hash) => {
    apiRequest('/anonymous/set-chatkitty-channel-id/' + hash, 'POST', {booking_id: bookingId, chatkitty_channel_id: channelId}, null).then(responce => {

    });
}

const loadCalendarUnreadMessages = (auth,dispatch, kitty) => {
    apiRequest('supplier/bookings-current-next-month', 'POST', null, auth.api_key).then(responce => {
        if (responce.status === 200) {
            responce.json().then(async data => {
                let allBookings = [];
                data.bookings.currentMonthResults.concat(data.bookings.nextMonthResults).forEach(booking => {
                    if (booking.bookings) {
                        allBookings = allBookings.concat(booking.bookings)
                    }
                });

                let channels = {}, newMessages = {};
                let getChannels = allBookings.map(async booking => {
                    let channel;
                    if (booking.chatkitty_channel_id) {
                        channel = await kitty.getChannel(booking.chatkitty_channel_id)
                        if (channel.succeeded) {
                            channels[booking.hash] = channel;
                        }
                        return channels[booking.hash]
                    }
                });

                await Promise.all(getChannels);

                let getCountMessages = Object.keys(channels).map(hash => {
                    return kitty.getUnreadMessagesCount({channel: channels[hash].channel}).then(result => {
                        return {
                            hash: hash,
                            data: result,
                        }
                    })
                });

                await Promise.all(getCountMessages).then(getUnreadMessagesCount => {
                    getUnreadMessagesCount.map(unreadMessage => {
                        newMessages[unreadMessage.hash] = unreadMessage.data.count
                    })
                })



                let result = {};
                let totalCountMessages = 0;
                Object.keys(channels).forEach(hash => {
                    totalCountMessages += newMessages[hash]
                })

                dispatch(chatActions.loadTotalCount(totalCountMessages))
            })
        }
    })
}


const prepareChatChannels = async (auth, dispatch, kitty, bookings, userTypeForCreate, hash) => {
    let channels = {}, newMessages = {};
    let getChannels = bookings.map(async booking => {
        let channel;
        if (booking.chatkitty_channel_id) {
            channel = await kitty.getChannel(booking.chatkitty_channel_id)
        }
        else{
            let needId = (userTypeForCreate === 'supplier') ? booking.supplier_id : booking.user_id;
            channel = await kitty.createChannel({
                type: 'PRIVATE',
                members: [{username: userTypeForCreate + needId}],
                name: booking.hash,
            });


            if (channel.succeeded) {
                if (hash !== undefined) {
                    saveChannelIdAnonymous(channel.channel.id, booking.id, hash)
                }
                else{
                    saveChannelId(auth, channel.channel.id, booking.id);
                }

            }
            else{
                console.error(channel.error);
            }
        }

        if (channel.succeeded) {
            channels[booking.hash] = channel;
            return channels[booking.hash]
        }
        else{
            console.error(channel);
            return Promise.resolve()
        }
    });


    await Promise.all(getChannels);

    let getCountMessages = Object.keys(channels).map(hash => {
        return kitty.getUnreadMessagesCount({channel: channels[hash].channel}).then(result => {
            return {
                hash: hash,
                data: result,
            }
        })
    });

    await Promise.all(getCountMessages).then(getUnreadMessagesCount => {
        getUnreadMessagesCount.map(unreadMessage => {
            newMessages[unreadMessage.hash] = unreadMessage.data.count
        })
    })



    let result = {};
    Object.keys(channels).forEach(hash => {
        result[hash] =  {
            channel: channels[hash].channel,
            countUnreadMesages: newMessages[hash],
        }
    })


    dispatch(chatActions.setChannels(result));
    return Promise.resolve()
}

export const chatHelper = {
    prepareChatChannels,
    loadCalendarUnreadMessages
}