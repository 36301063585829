import React from 'react';
import SearchResults from './SearchResults';
import SelectCity from '@semper/SelectCity';
import { apiRequest } from '@services/Request';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SwipeableViews from 'react-swipeable-views';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SupplierCalendar from '@semper/Calendar/SupplierCalendar';
import Slide from '@material-ui/core/Slide';
import { serverUrl } from '@services/Request';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
    root: {
        padding: '0 30px 0 0',
    },
    slideContainer: {
        padding: '0 10px 0 0',
    }
};

class AnonymousSearchWrapper extends React.Component{


    state = {
        isLoading: true,
        selectedCity: 0,
        selectedCategory: 0,
        ourSelections: {},
        searchLoading: false,
        wasSearch: false,
        filterByName: '',
        searchResult: [],
        currentFilters: [],
        originSearchResult: [],
        selected_city: {name: 'Geneva'},
        childCategory: [],
        openCalendar: false,
        selectItem: {},
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.wasSearch) {
            this.handleSearch(nextProps.selectedCategory, nextProps.selectedCity);
        }
    }

    componentDidMount() {
        if (this.props.wasSearch) {
            this.handleSearch(this.props.selectedCategory, this.props.selectedCity);
        }
    }

    handleSearch = (selectedCategory, selectedCity) => {
        this.setState({searchLoading: true, wasSearch: true, selectedCategory: selectedCategory, selectedCity: selectedCity});
        apiRequest('anonymous/search', 'POST', {hash: this.props.match.params.token, category_id: selectedCategory, city_id: selectedCity}).then(responce => {
            if (responce.status == 200) {
                responce.json().then(data => {
                    this.setState({
                        originSearchResult: data.data.results,
                        childCategory: data.extra_data.childCategories
                    });
                    setTimeout(() => {
                        this.filterSearchByName();
                    }, 200)
                })
            }
        })

    }

    handleFilterByName = (e) => {
        this.setState({
            filterByName: e.target.value
        })
        this.filterSearchByName(e.target.value);

    }

    handleFilter = (e) => {
        let { currentFilters, childCategory } = this.state;
        currentFilters.push(childCategory.find(category => {
            return category.id === e.target.value
        }));
        this.setState({
            currentFilters: [...currentFilters]
        })
        this.updateResultsWithSubCategory();
    }

    removeFilter = (categoryId) => {
        let { currentFilters } = this.state;
        currentFilters.splice(currentFilters.findIndex(category => {
            return category.id === categoryId
        }),1);
        this.setState({
            currentFilters: [...currentFilters]
        })
        this.updateResultsWithSubCategory();
    }

    updateResultsWithSubCategory = () => {
        const { auth } = this.props;
        const { selectedCategory, selectedCity, currentFilters } = this.state;
        this.setState({searchLoading: true});
        apiRequest('anonymous/search', 'POST', {hash: this.props.match.params.token, category_id: selectedCategory, city_id: selectedCity, subcategory_ids: currentFilters.map(category => {return category.id})}, auth.api_key).then(responce => {
            if (responce.status == 200) {
                responce.json().then(data => {
                    this.setState({
                        originSearchResult: data.data.results,
                        childCategory: data.extra_data.childCategories
                    });
                    setTimeout(() => {
                        this.filterSearchByName();
                    }, 200)
                })
            }
        })

    }

    filterSearchByName = (value) => {
        const { originSearchResult } = this.state;
        const filterByName = (typeof value != "undefined") ? value : this.state.filterByName;
        let filtered = [];
        if (filterByName) {
            filtered = originSearchResult.map(item => {
                if (item.name.toLocaleLowerCase().indexOf(filterByName.toLocaleLowerCase()) + 1) {
                    return item
                }
                else{
                    return false;
                }
            }).filter(Boolean);

            this.setState({
                searchResult: filtered,
                searchLoading: false
            })
        }
        else{
            this.setState({
                searchResult: [...originSearchResult],
                searchLoading: false
            })
        }
    }


    clickOnItem = (selection) => {
        this.openCalendar(selection)
    }
    seeAll = (categoryId) => {
        const { categories } = this.props;
        this.props.changeCategory({}, categories.findIndex(e => {return e.id === categoryId}), true);
    }

    openCalendar = (item) => {
        const { auth } = this.props;
        this.setState({
            selectItem: item,
            openCalendar: true,
            loadingCalendar: true,
        });
    }
    closeCalendar = () => {
        this.setState({
            openCalendar: false
        })
    }

    render() {
        const { filterByName, searchLoading, childCategory, currentFilters } = this.state;
        const { openCalendar, selectItem, loadingCalendar } = this.state;
        const { ourSelections, selectedCategoryByIndex, categories, wasSearch, selected_city, wasSearchByName, searchByNameResult } = this.props;
        const hasOurSelectionItems = (Object.keys(ourSelections).length > 0) && (typeof ourSelections[categories[selectedCategoryByIndex].id] != "undefined") && (ourSelections[categories[selectedCategoryByIndex].id].items.length > 0);
        let extraClass = hasOurSelectionItems ? '' : ' no-border';
        let searchResult = [...this.state.searchResult];
        if (wasSearchByName) {
            searchResult = [...searchByNameResult];
        }
        if (!wasSearch) {
            extraClass += ' big-padding'
        }
        return (
            <>
                {!wasSearchByName && <div className={'selectDateOnSearchForm' + extraClass}>
                    {!!wasSearch && <>
                        <FormControl
                            style={{width: '100%', maxWidth: '450px'}}
                        >
                            <OutlinedInput
                                placeholder="Search by name"
                                value={filterByName}
                                onChange={this.handleFilterByName}
                                variant="outlined"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon style={{marginRight: 10}} />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </>}
                    {!wasSearch && <div style={{width: '100%'}}>
                        <span style={{color:'white',fontSize: 22,fontWeight: 500,textAlign: 'center', display: 'block', marginBottom: 20}}>Where do you want to book?</span>
                        <SelectCity
                            wasSearch={wasSearch}
                            updateHomepage={this.props.updateHomepage}
                            cities={this.props.cities}
                            selected_city={selected_city}
                            defaultCity={this.props.cities}
                            auth={this.props.auth} />
                    </div>}
                    {!!wasSearch && <>
                        <FormControl
                            style={{width: '100%', maxWidth: '450px', marginTop: 20}} variant="outlined"
                        >
                            <Select
                                labelId="filter"
                                id="filter"
                                placeholder
                                value={0}
                                onChange={this.handleFilter}
                            >
                                <MenuItem value={0}>Filter by category</MenuItem>
                                {childCategory.map(category => {
                                    let alreadyHasInFilter = currentFilters.find(addedCategory => {
                                        return addedCategory.id === category.id;
                                    })
                                    if (alreadyHasInFilter) {
                                        return false
                                    }
                                    return  <MenuItem value={category.id}>{category.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </>}
                    {!!wasSearch && currentFilters.length > 0 && <div className="wrapperFilters">
                        {currentFilters.map(category => {
                            return <span className="filter"><span onClick={e => (this.removeFilter(category.id))}>x</span>{category.name}</span>
                        })}
                    </div>}
                    {/*{!wasSearch && <Button onClick={this.props.searchRedirect} className="blackButton" style={{marginTop: 40}}>Search</Button>}*/}
                </div>}

                {!wasSearch && !wasSearchByName && (hasOurSelectionItems) && <div className="ourSectionSlider">
                    <Typography style={{fontSize: 19, marginBottom:15}} variant="h7" component="h3">Semper selection</Typography>
                    {categories.map((category, index) => {
                        if (typeof ourSelections[category.id] == "undefined" || ourSelections[category.id].items.length === 0) {
                            return null;
                        }
                        return (
                            <div>
                                <div style={{display:'flex',justifyContent: 'space-between',alignItems: 'center', marginBottom:15}}>
                                    <Typography
                                        variant="h6"
                                        component="h4"
                                        style={{fontSize:16,fontWeight: 'bold', lineHeight: 1}}
                                    >{ourSelections[category.id].title}</Typography>
                                    <Button onClick={(e) => this.seeAll(category.id)} className="blackButton small">See all</Button>
                                </div>
                                <SwipeableViews style={styles.root} slideStyle={styles.slideContainer} axis="x" className="box-shadow-images" >
                                    {ourSelections[category.id].items.map(selection => {
                                        return (<>
                                            <a style={{color: '#2D3340', textDecoration: 'none'}} onClick={(e) => this.clickOnItem(selection)}>
                                                <img src={serverUrl + '/' + selection.image} alt={selection.name}/>
                                                <p style={{fontSize:14, fontWeight: 600}}>{selection.name}</p>
                                            </a>
                                        </>)
                                    })}
                                </SwipeableViews>
                            </div>
                        )
                    })}
                </div>}
                {(!!wasSearch || !!wasSearchByName) && <div>
                    <SearchResults openCalendar={this.openCalendar} goToLink={this.props.goToLink} searchLoading={searchLoading} searchResult={searchResult} />
                </div>}

                {typeof selectItem.id != "undefined" && <SupplierCalendar
                    openCalendar={openCalendar}
                    hash={this.props.match.params.token}
                    goToLink={this.props.goToLink}
                    closeCalendar={this.closeCalendar}
                    supplierName={selectItem.name}
                    supplierId={selectItem.id}
                    fromSupplierPage={false}
                    isAnonymous={true}
                />}
            </>
        );
    }
}

export default AnonymousSearchWrapper;
