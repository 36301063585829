import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppWrapper from './app/AppWrapper';
import * as serviceWorker from './serviceWorker';
import { deviceHelper } from "@helpers/device"

if (window.location.href.indexOf('redirect_to_app=1') > 0 && deviceHelper.deviceIsiOS()) {
    window.location.href = 'semperapp:/' + window.location.pathname
}
ReactDOM.render(
    <AppWrapper />,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
