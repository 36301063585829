import React from 'react';
import Banner from '@semper/Banner';
import Logo from '@images/logo_black.svg';
import Arrow from '@images/back_arrow_black.svg';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { Redirect, withRouter } from 'react-router-dom';
import { apiRequest } from '@services/Request';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchWrapper from './SearchWrapper';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';

import './search.css'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

class Search extends React.Component{

    state = {
        categories: [],
        searchByName: '',
        wasSearch: false,
        wasSearchByName: false,
        isLoading: true,
        searchByNameResult: [],
        cities:[],
        selectedCity:[],
        ourSelections: [],
        selectedCategory: 0,
        selected_city: [],
        selectedDate: new Date(),
        selectedCategoryByIndex: 0,
    }

    componentDidMount() {
        let cityId = typeof this.props.match.params.id != "undefined" ? this.props.match.params.id : false;
        if (cityId) {
            this.updateHomepage(parseInt(cityId), true);
            return false;
        }
        else if (window.localStorage.getItem('city_id')) {
            cityId = window.localStorage.getItem('city_id');
        }
        this.updateHomepage(parseInt(cityId), false);
    }

    updateHomepage = (cityId, activeSearch) => {
        const { auth } = this.props;
        let toSent = {};
        if (cityId) {
            toSent['city_id'] = cityId;
            window.localStorage.setItem('city_id', cityId);
        }
        apiRequest('homepage', 'POST', { city_id: cityId}, auth.api_key).then(request => {
            if (request.status === 200) {
                request.json().then(data => {
                    if (window.localStorage.getItem('category_index') && typeof data.data.categories[parseInt(window.localStorage.getItem('category_index'))] != "undefined") {
                        this.setState({
                            categories: data.data.categories,
                            selectedCategoryByIndex: parseInt(window.localStorage.getItem('category_index')),
                            selectedCategory: data.data.categories[parseInt(window.localStorage.getItem('category_index'))].id,
                            cities: data.data.cities,
                            selectedCity: (cityId && data.data.cities.findIndex(city => city.id === cityId) >= 0) ? cityId : data.data.default_city[0].id,
                            ourSelections: data.data.suppliers,
                            selected_city: (data.data.selected_city) ? data.data.selected_city : data.data.default_city[0],
                            isLoading: false,
                            wasSearch: activeSearch
                        })
                    }
                    else{
                        this.setState({
                            categories: data.data.categories,
                            selectedCategoryByIndex: 0,
                            selectedCategory: data.data.categories[0].id,
                            cities: data.data.cities,
                            selectedCity: (cityId && data.data.cities.findIndex(city => city.id === cityId) >= 0) ? cityId : data.data.default_city[0].id,
                            ourSelections: data.data.suppliers,
                            selected_city: (data.data.selected_city) ? data.data.selected_city : data.data.default_city[0],
                            isLoading: false,
                            wasSearch: activeSearch
                        })
                    }

                })
            }
        })
    }

    changeCategory = (e, index, seeAll) => {
        if (!this.state.categories.length) {
            return false;
        }
        window.localStorage.setItem('category_index', index);
        this.setState({
            selectedCategoryByIndex: index,
            searchLoading: true,
            selectedCategory: this.state.categories[index].id
        })
        if (typeof seeAll != "undefined") {
            this.setState({
                wasSearch: true
            });
            this.searchRedirect()
        }
    }

    searchRedirect = () => {
        const { selectedCity } = this.state;
        this.props.history.push('/city/'+ selectedCity);
    }


    goToLink = (href) => {
        this.props.history.push(href);
    }

    searchByName = () => {
        const { searchByName } = this.state;
        const { auth } = this.props;
        if (searchByName) {
            this.setState({
                isLoading: true
            })

            apiRequest('elastic-search/' + searchByName, 'GET', null, auth.api_key).then(responce => {
                if (responce.status === 200) {
                    responce.json().then(data => {
                        this.setState({
                            wasSearchByName: true,
                            isLoading: false,
                            searchByNameResult: data.results
                        })
                    })
                }
                else{
                    this.setState({
                        isLoading:false
                    })
                }

            })

        }
    }

    handleChangeSearch = (e) => {
        if (e.key === 'Enter') {
            this.searchByName();
        }
    }

    handleFilterByName = (e) => {
        this.setState({
            searchByName: e.target.value
        })
    }

    resetSarchByName = () => {
        this.setState({
            searchByName: '',
            wasSearchByName: false

        })
    }




    render() {
        const {
            categories,
            selectedDate,
            searchByName,
            ourSelections,
            selectedCategoryByIndex,
            cities, selectedCity,
            selectCityDialogIsOpen,
            isLoading, selectedCategory,
            searchLoading, searchResult,
            wasSearch, filterByName,
            selected_city, searchByNameResult, wasSearchByName } = this.state;
        if (isLoading) {
            return <div style={{display: 'flex',alignItems: "center", justifyContent: "center", height: "100%"}}><CircularProgress /></div>
        }

        return(
            <>
                <SearchWrapper
                    categories={categories}
                    wasSearch={true}
                    updateHomepage={this.updateHomepage}
                    searchByNameResult={searchByNameResult}
                    auth={this.props.auth}
                    wasSearchByName={wasSearchByName}
                    selected_city={selected_city}
                    goToLink={this.goToLink}
                    searchLoading={searchLoading}
                    searchRedirect={this.searchRedirect}
                    searchResult={searchResult}
                    ourSelections={ourSelections}
                    cities={cities}
                    selectedCategory={selectedCategory}
                    selectedCategoryByIndex={selectedCategoryByIndex}
                    selectedCity={selectedCity}
                    changeCategory={this.changeCategory}
                />
            </>
        )
    }
}

export default withRouter(Search);