import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import MenuItem from '@material-ui/core/MenuItem';
import './subscriptions.css'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentSubscription from '@semper/PaymentSubscription'
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

class Subscriptions extends React.Component{
    state = {
        selectedPlan: 0,
        isOpenPaymentDialog:false,
        selectedCity: 0,
        selectedCitiesForPlans: {
            1: [],
            2: [],
            3: [],
        },
    }


    componentDidMount() {
        const { selected_premium_product, premium_products } = this.props;
        let selectedCitiesForPlans = {};

        if (selected_premium_product) {
            this.setState({
                selectedPlan: selected_premium_product
            })

            if (this.props.selected_premium_cities) {
                premium_products.forEach(product => {
                    selectedCitiesForPlans[product.id] = [];
                })
                selectedCitiesForPlans[selected_premium_product] = this.props.selected_premium_cities
                this.setState({
                    selectedPlan: selected_premium_product,
                    selectedCitiesForPlans: selectedCitiesForPlans,
                })
            }
        }
        else{
            if (typeof this.props.autocomplite_city_id != "undefined") {
                premium_products.forEach(product => {
                    selectedCitiesForPlans[product.id] = [this.props.autocomplite_city_id];
                })
            }
            else{
                premium_products.forEach(product => {
                    selectedCitiesForPlans[product.id] = [];
                })
            }

            this.setState({
                selectedCitiesForPlans: selectedCitiesForPlans,
            })
        }
    }

    selectPlan = (productId) => {
        if (productId === 1) {
            this.setState({
                selectedPlan: productId
            })
        }
    }

    subscribe = () => {
        this.setState({
            isOpenPaymentDialog: true
        })
    }

    closePaymentDialog = () => {
        this.setState({
            isOpenPaymentDialog: false
        })
    }

    selectCity = (e, productId, number_of_cities) => {
        let { selectedCitiesForPlans } = this.state;
        if (e.target.value.length <= number_of_cities) {
            selectedCitiesForPlans[productId] = e.target.value;
            this.setState({
                selectedCitiesForPlans: {...selectedCitiesForPlans}
            })
        }

    }




    render() {
        const { selectedPlan, selectedCitiesForPlans, isOpenPaymentDialog } = this.state;
        const { premium_products, cities, cards } = this.props;
        if (cities.length === 0 || premium_products.length === 0) {
            return (<p>No products yet</p>)
        }

        return(
            <div>
                <div>
                    <div className="product">
                        <div className="content">
                            <div>
                                <Radio
                                    checked={true}
                                    name="radio-buttons"
                                />
                            </div>
                            <div>
                                <div className="top">
                                    <div className="product-name">
                                        Premium plan
                                    </div>
                                    <div className="product-price">
                                        <div>Free <small>Until {this.props.free_expiration_date}</small></div>
                                        <div className="old-price"><small><span>{this.props.premium_member_monthly_price} €</span> / year</small></div>
                                    </div>
                                </div>
                                <div className="info">
                                    <p>Service fees <b>0%</b></p>
                                    Up to {this.props.last_hours_duration_for_fees} hours before booking
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*{premium_products.map(product => {*/}
                    {/*return (*/}
                        {/*<div className={(product.id !== 1) ? 'product disabled': 'product'} onClick={(e) => this.selectPlan(product.id)}>*/}
                            {/*<div className="soon">Coming soon</div>*/}
                            {/*<div className="content">*/}
                                {/*<div>*/}
                                    {/*<Radio*/}
                                        {/*checked={selectedPlan === product.id}*/}
                                        {/*name="radio-buttons"*/}
                                    {/*/>*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                    {/*<div className="top">*/}
                                        {/*<div className="product-name">*/}
                                            {/*{product.name}*/}
                                        {/*</div>*/}
                                        {/*<div className="product-price">*/}
                                            {/*{product.price} € / <small>month</small>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                    {/*<div className="info">*/}
                                        {/*Access to bookings for <b>{product.number_of_cities} {(product.number_of_cities === 1 ? 'city' : 'cities')}</b>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            {/*<div style={(selectedPlan !== product.id) ? {display: 'none'} : {}} className="select-city" >*/}
                                {/*<FormControl style={{width: '100%', marginBottom: 15, marginLeft: 15}}>*/}
                                    {/*<InputLabel htmlFor="name-multiple">Choose {product.number_of_cities} {(product.number_of_cities === 1 ? 'city' : 'cities')}</InputLabel>*/}
                                    {/*<Select*/}
                                        {/*labelId={'city_id' + product.id}*/}
                                        {/*id="city_id + product.id"*/}
                                        {/*multiple*/}
                                        {/*required*/}
                                        {/*disabled={(this.props.selected_premium_product && product.id === this.props.selected_premium_product)}*/}
                                        {/*value={selectedCitiesForPlans[product.id]}*/}
                                        {/*name="city_id + product.id"*/}
                                        {/*onChange={(e) => this.selectCity(e, product.id, product.number_of_cities)}*/}
                                        {/*fullWidth*/}
                                    {/*>*/}
                                        {/*{cities.map(city => {*/}
                                            {/*return (*/}
                                                {/*<MenuItem fullWidth value={city.id}>{city.name}</MenuItem>*/}
                                            {/*)*/}
                                        {/*})}*/}
                                    {/*</Select>*/}
                                {/*</FormControl>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*)*/}
                {/*})}*/}
                {/*{typeof this.props.selected_premium_product == "undefined" && <div className="bottom bg-main-color">*/}
                    {/*<p>Subscription for 12 months</p>*/}
                    {/*<Button disabled={!(selectedPlan !== 0 && selectedCitiesForPlans[selectedPlan].length > 0)} onClick={this.subscribe} variant="contained" >Subscribe</Button>*/}
                {/*</div>}*/}
                {/*{typeof this.props.selected_premium_product != "undefined" && <div className="bottom">*/}
                    {/*<p>Subscription for 12 months</p>*/}
                    {/*<Button className="blackButton" disabled={(this.props.selected_premium_product === null && selectedPlan === 0) || (selectedPlan === this.props.selected_premium_product) || (selectedPlan && selectedCitiesForPlans[selectedPlan].length < premium_products[premium_products.findIndex(product => product.id === selectedPlan)].number_of_cities)} onClick={this.subscribe} variant="contained" >Subscribe</Button>*/}
                {/*</div>}*/}
                {/*{!!selectedPlan && <PaymentSubscription*/}
                    {/*switchToPremium={this.props.switchToPremium}*/}
                    {/*auth={{*/}
                        {/*api_key: this.props.access_token*/}
                    {/*}}*/}
                    {/*fromBooking={false}*/}
                    {/*product_id={selectedPlan}*/}
                    {/*cities={selectedCitiesForPlans[selectedPlan]}*/}
                    {/*pricePremium={premium_products[premium_products.findIndex(product => product.id === selectedPlan)].price}*/}
                    {/*cards={cards}*/}
                    {/*isOpenPaymentDialog={isOpenPaymentDialog}*/}
                    {/*closePaymentDialog={this.closePaymentDialog}*/}
                {/*/>}*/}
            </div>
        )
    }
}

export default Subscriptions;
