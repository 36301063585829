import React from 'react';
import Grid from '@material-ui/core/Grid';
import Logo from '@images/logo_white_with_label.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { apiRequest } from '@services/Request';
import { helperValidator } from '@helpers/validator';
import Alert from '@material-ui/lab/Alert';
import { userActions } from '@actions/index';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect, withRouter } from 'react-router-dom';
import './index.css'
import {
    Link
} from "react-router-dom";



class Login extends React.Component{

    state = {
        isLoading: false,
        form: {
            email: '',
            password: '',
        },
        formValidation: {
            email: 'email|required',
            password: 'min:5|required',
        },
        formErrors:{},
        error: ''
    }

    onChange = (e) => {
        var form = { ...this.state.form };
        form[e.target.name] = e.target.value;
        this.setState({
            form
        })
    }

    handleClose = () => {
        this.setState({
            error: ''
        })
    }

    loginClick = () => {
        const { dispatch } = this.props;

        let errors = helperValidator.validate(this.state.form, this.state.formValidation);
        this.setState({
            formErrors: {}
        })
        if (Object.keys(errors).length) {
            this.setState({
                formErrors: errors
            })
            return false;
        }
        this.setState({
            isLoading:true
        })
        apiRequest('login', 'POST', this.state.form).then(request => {
            if (request.status === 200) {
                request.json().then(data => {
                    dispatch(userActions.login({
                        access_token: data.access_token,
                        user: { user: data.user }
                    }));
                    if (window.localStorage.getItem('supplier_id') && window.localStorage.getItem('booking_date')) {
                        let supplierId = window.localStorage.getItem('supplier_id');
                        window.localStorage.removeItem('supplier_id');
                        let bookingDate = window.localStorage.getItem('booking_date');
                        window.localStorage.removeItem('booking_date');
                        window.location.href = "/booking/" + supplierId + "/" + bookingDate.replaceAll('/','-');

                    }
                    else{
                        window.location.href = '/';
                    }
                })

            }
            else{
                if (request.status === 401) {
                    this.setState({error: 'Incorrect email or password'})
                }
                else if (request.status === 422) {
                    request.json().then(data => {
                        let message = (typeof data.messages != "undefined") ? data.messages.join("\n") : data.message;
                        this.setState({
                            error: message
                        })
                    })
                }
            }

            this.setState({
                isLoading:false
            })

        });
    }

    render() {
        const { form, error, isLoading, formErrors } = this.state;
        return (
            <div className="fullscreen bg-main-color login-page">
                <Grid className="fullscreen" justify="center" container alignItems="center" spacing={1}>
                    <Grid style={{height: '100%'}} item xs={11} spacing={1}>
                        <Grid style={{height: '100%'}} justify="center" container alignItems="center" spacing={1}>
                            <Grid item xs={12} align="center" spacing={1}>
                                <br/><br/>
                                <img src={Logo} alt="Semper"/>
                                <p>Welcome on Semper !</p>
                            </Grid>
                            <Grid item xs={12} align="center" spacing={1}>
                                <TextField
                                    placeholder="Email"
                                    name="email"
                                    onChange={this.onChange}
                                    value={form.email}
                                    variant="outlined"
                                    fullWidth
                                    error={formErrors['email'] !== undefined}
                                    helperText={formErrors['email'] !== undefined ? formErrors['email'] : ''}
                                />
                                <TextField
                                    placeholder="Password"
                                    name="password"
                                    onChange={this.onChange}
                                    value={form.password}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    error={formErrors['password'] !== undefined}
                                    helperText={formErrors['password'] !== undefined ? formErrors['password'] : ''}
                                />
                                <Link className="text-right" to="/forgot_password">Forgot Password ?</Link>
                            </Grid>

                            <Grid item xs={12} align="center" spacing={1}>
                                <div style={{textAlign: "center"}}><Button disabled={isLoading} onClick={this.loginClick} variant="contained">
                                    {!isLoading && <>Log in</>}
                                    {isLoading && <CircularProgress size={29}  />}
                                </Button></div>
                                <div className="create-account-link">
                                    <Link to="/register"><b>Create Account</b></Link>
                                </div>
                                <b className="tel-number">Contact : <a href="tel:+44 7488 869945">+44 7488 869945</a></b>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(withRouter(Login));
