import React from 'react';
import SearchResults from './SearchResults';
import SelectCity from '@semper/SelectCity';
import { apiRequest } from '@services/Request';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SwipeableViews from 'react-swipeable-views';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SupplierCalendar from '@semper/Calendar/SupplierCalendar';
import Slide from '@material-ui/core/Slide';
import SearchIcon from '@images/homepage_filter_by_name_icon.svg';
import FilterIcon from '@images/homepage_filter_by_categories.svg';
import { serverUrl } from '@services/Request';
import SeeAll from '@images/see_all_arrow.svg'
import AppBar from '@material-ui/core/AppBar';
import Banner from '@semper/Banner';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SwipeableLink extends React.Component {
    handleMouseDown = e => {
        this.x = e.screenX;
    }

    handleClick = e => {
        const delta = Math.abs(e.screenX - this.x);

        if (delta > 10) {
            e.preventDefault();
        }
        else{
            this.props.handleClick();
        }

        this.x = 0;
    }

    render() {
        return (
            <a
                onMouseDown={this.handleMouseDown}
                onClick={this.handleClick}
                {...this.props}
            >
                {this.props.children}
            </a>
        );
    }
}

const styles = {
    root: {
        padding: '0 30px 0 0',
    },
    slideContainer: {
        padding: '0 10px 0 0',
    }
};

class SearchWrapper extends React.Component{


    state = {
        isLoading: true,
        selectedCity: 0,
        selectedCategory: 0,
        ourSelections: {},
        searchLoading: false,
        wasSearch: false,
        filterByName: '',
        searchResult: [],
        currentFilters: [],
        originSearchResult: [],
        selected_city: {name: 'Geneva'},
        childCategory: [],
        openCalendar: false,
        selectItem: {},
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.wasSearch) {
            this.handleSearch(nextProps.selectedCategory, nextProps.selectedCity);
        }
    }

    componentDidMount() {
        if (this.props.wasSearch) {
            this.handleSearch(this.props.selectedCategory, this.props.selectedCity);
        }
    }

    handleSearch = (selectedCategory, selectedCity) => {
        const { auth } = this.props;
        this.setState({searchLoading: true, wasSearch: true, selectedCategory: selectedCategory, selectedCity: selectedCity});
        apiRequest('search', 'POST', {category_id: selectedCategory, city_id: selectedCity}, auth.api_key).then(responce => {
            if (responce.status == 200) {
                responce.json().then(data => {
                    this.setState({
                        originSearchResult: data.data.results,
                        childCategory: data.extra_data.childCategories
                    });
                    setTimeout(() => {
                        this.filterSearchByName();
                    }, 200)
                })
            }
        })

    }

    handleFilterByName = (e) => {
        this.setState({
            filterByName: e.target.value
        })
        this.filterSearchByName(e.target.value);

    }

    handleFilter = (e) => {
        let { currentFilters, childCategory } = this.state;
        currentFilters.push(childCategory.find(category => {
            return category.id === e.target.value
        }));
        this.setState({
            currentFilters: [...currentFilters]
        })
        this.updateResultsWithSubCategory();
    }

    removeFilter = (categoryId) => {
        let { currentFilters } = this.state;
        currentFilters.splice(currentFilters.findIndex(category => {
            return category.id === categoryId
        }),1);
        this.setState({
            currentFilters: [...currentFilters]
        })
        this.updateResultsWithSubCategory();
    }

    updateResultsWithSubCategory = () => {
        const { auth } = this.props;
        const { selectedCategory, selectedCity, currentFilters } = this.state;
        this.setState({searchLoading: true});
        apiRequest('search', 'POST', {category_id: selectedCategory, city_id: selectedCity, subcategory_ids: currentFilters.map(category => {return category.id})}, auth.api_key).then(responce => {
            if (responce.status == 200) {
                responce.json().then(data => {
                    this.setState({
                        originSearchResult: data.data.results,
                        childCategory: data.extra_data.childCategories
                    });
                    setTimeout(() => {
                        this.filterSearchByName();
                    }, 200)
                })
            }
        })

    }

    accentFold = (inStr) => {
        return inStr.replace(
            /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
            function (str, a, c, e, i, n, o, s, u, y, ae) {
                if (a) return 'a';
                if (c) return 'c';
                if (e) return 'e';
                if (i) return 'i';
                if (n) return 'n';
                if (o) return 'o';
                if (s) return 's';
                if (u) return 'u';
                if (y) return 'y';
                if (ae) return 'ae';
            }
        );
    }

    filterSearchByName = (value) => {
        const { originSearchResult } = this.state;
        const filterByName = (typeof value != "undefined") ? value : this.state.filterByName;
        let filtered = [];
        if (filterByName) {
            filtered = originSearchResult.map(item => {
                if (this.accentFold(item.name.toLocaleLowerCase()).indexOf(filterByName.toLocaleLowerCase()) + 1) {
                    return item
                }
                else{
                    return false;
                }
            }).filter(Boolean);

            this.setState({
                searchResult: filtered,
                searchLoading: false
            })
        }
        else{
            this.setState({
                searchResult: [...originSearchResult],
                searchLoading: false
            })
        }
    }


    clickOnItem = (selection) => {
        this.openCalendar(selection)
    }
    seeAll = (categoryId) => {
        const { categories } = this.props;
        this.props.changeCategory({}, categories.findIndex(e => {return e.id === categoryId}), true);
    }

    openCalendar = (item) => {
        const { auth } = this.props;
        this.setState({
            selectItem: item,
            openCalendar: true,
            loadingCalendar: true,
        });
    }
    closeCalendar = () => {
        this.setState({
            openCalendar: false
        })
    }

    render() {
        const { filterByName, searchLoading, childCategory, currentFilters } = this.state;
        const { openCalendar, selectItem, loadingCalendar } = this.state;
        const { ourSelections, selectedCategoryByIndex, categories, wasSearch, selected_city, wasSearchByName, searchByNameResult } = this.props;
        const hasOurSelectionItems = (Object.keys(ourSelections).length > 0);
        let extraClass = hasOurSelectionItems ? '' : ' no-border';
        let searchResult = [...this.state.searchResult];
        if (wasSearchByName) {
            searchResult = [...searchByNameResult];
        }
        return (
            <>

                <Banner>

                </Banner>
                {!wasSearchByName && <div className="selectDateOnSearchForm">
                    <SelectCity
                        wasSearch={true}
                        updateHomepage={this.props.updateHomepage}
                        cities={this.props.cities}
                        selected_city={selected_city}
                        defaultCity={this.props.cities}
                        auth={this.props.auth} />
                    <br/>
                    <FormControl
                        style={{width: '100%', maxWidth: '450px'}}
                    >
                        <OutlinedInput
                            placeholder="Search by name"
                            value={filterByName}
                            onChange={this.handleFilterByName}
                            variant="outlined"
                            startAdornment={
                                <InputAdornment position="start">
                                    <img src={SearchIcon}/>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl
                        style={{width: '100%', maxWidth: '450px', marginTop: 20}} variant="outlined"
                    >
                        <Select
                            labelId="filter"
                            id="filter"
                            placeholder
                            value={0}
                            style={{
                                color: '#797979'
                            }}
                            onChange={this.handleFilter}
                            startAdornment={
                                <InputAdornment position="start">
                                    <img src={FilterIcon}/>
                                </InputAdornment>
                            }
                        >
                            <MenuItem value={0}>Filter by category</MenuItem>
                            {childCategory.map(category => {
                                let alreadyHasInFilter = currentFilters.find(addedCategory => {
                                    return addedCategory.id === category.id;
                                })
                                if (alreadyHasInFilter) {
                                    return false
                                }
                                return  <MenuItem value={category.id}>{category.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    {currentFilters.length > 0 && <div className="wrapperFilters">
                        {currentFilters.map(category => {
                            return <span className="filter"><span onClick={e => (this.removeFilter(category.id))}>x</span>{category.name}</span>
                        })}
                    </div>}
                </div>}
                <div>
                    <SearchResults openCalendar={this.openCalendar} auth={this.props.auth} goToLink={this.props.goToLink} searchLoading={searchLoading} searchResult={searchResult} />
                </div>
                {(typeof selectItem.id != "undefined" && openCalendar) && <SupplierCalendar
                    openCalendar={openCalendar}
                    auth={this.props.auth}
                    goToLink={this.props.goToLink}
                    closeCalendar={this.closeCalendar}
                    supplierName={selectItem.name}
                    supplierId={selectItem.id}
                    fromSupplierPage={false}
                    isAnonymous={false}
                />}
            </>
        );
    }
}

export default SearchWrapper;
