import React from 'react';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Card from '@images/card.svg'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { apiRequest } from '@services/Request';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Portal from '@material-ui/core/Portal';
import Mc from '@images/mc.svg';
import Ae from '@images/ae.svg';
import Visa from '@images/visa.svg';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { userActions } from '@actions/index';
import { getUser } from '@reducers/authentication'
import {connect} from "react-redux";
import Csv from '@images/csv.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import {Link} from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import TermsAndConditions from '@semper/TermsAndConditions';
import './payment.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class InvitedPayment extends React.Component{

    state = {
        loading: true,
        open: false,
        openTermsAndConditions: false,
        isCompany: false,
        open3dsecureDialog: false,
        linkFor3Dsecure: '',
        iframeLoaded:false,
        success: '',
        error: '',
        loadingPayment: false,
        addNewCard: 0,
        terms: false,
        addresses: [],
        paymentCard: {
            save_my_card: 0,
            booking_id: '',
            credit_card_id: "0",
            billing_address_id: '',
            credit_card_number: '',
            credit_card_name: '',
            credit_card_expiration_month: '',
            credit_card_expiration_year: '',
            credit_card_expiration_date: '',
            tax_number: '',
            company: '',
            credit_card_cvv: '',
            credit_card_nickname: '',
            country_id: 0,
            firstname: '',
            lastname: '',
            street_1: '',
            zipcode: '',
            city: '',
        }
    }

    componentDidMount() {
        const { auth, cards, dispatch } = this.props;
        if (cards.length === 0) {
            this.addNewCard();
        }

        const handlerIframeEvent = event => {
            if (event.data.type === 'loaded') {
                this.setState({
                    iframeLoaded: true
                })
            }
            if (event.data.type === 'payment-done') {
                window.localStorage.removeItem('dataWasSendedToFrame');
                this.setState({
                    linkFor3Dsecure: event.data.nextUrl
                })
            }

            if (event.data.type === 'payment-done-redirect') {
                window.localStorage.removeItem('dataWasSendedToFrame');
                dispatch(userActions.updateUserInfo(event.data.auth));
                window.localStorage.removeItem('user_id');
                setTimeout(() => {
                    window.location.href = '/booking-list';
                }, 200)

            }
            else if(event.data.type === 'payment-error'){
                window.localStorage.removeItem('dataWasSendedToFrame');
                this.setState({
                    loadingPayment: false,
                })

                let frame = window.frames.iframe_3d;
                frame.postMessage(
                    {
                        error_text: event.data.error_text,
                        type: 'payment-error-update-message'
                    },
                    '*'
                )
            }
            else if(event.data.type === 'payment-transfer-data-request'){
                if (window.localStorage.getItem('dataWasSendedToFrame') === null) {
                    let frame = window.frames.iframe_3d;
                    window.localStorage.setItem('dataWasSendedToFrame', 1);
                    frame.postMessage(
                        {
                            user: null,
                            user_id: this.props.userId,
                            city_ids: null,
                            api_key: null,
                            product_id: null,
                            type: 'payment-transfer-data-response'
                        },
                        '*'
                    )
                }

            }
            else if(event.data.type === 'define_first_booking'){
                window.localStorage.setItem('first_booking', event.data.first_booking);
            }
            else if(event.data.type === 'redirect'){
                window.localStorage.removeItem('dataWasSendedToFrame');
                window.location.href = event.data.url;
                window.location.reload();
            }

        }

        window.addEventListener("message", handlerIframeEvent);

    }

    addNewCard = () => {
        this.setState({
            addNewCard: 1
        })
    }

    onChangeDate = (event) => {
        var paymentCard = { ...this.state.paymentCard };
        let newString = event.target.value;
        if (newString.split('/').length && typeof newString.split('/')[1] != "undefined" && newString.split('/')[1].length === 4) {
            paymentCard.credit_card_expiration_date = newString.split('/')[0] + '/' + newString.split('/')[1].split('')[2] + newString.split('/')[1].split('')[3];
        }
        else{
            let lastChar = newString.split('')[newString.split('').length - 1];
            if (event.target.value.length < paymentCard.credit_card_expiration_date.length) {
                if (lastChar === '/') {
                    let arrayText = newString.split('');
                    arrayText.splice(-1, 1);
                    paymentCard.credit_card_expiration_date = arrayText.join('');
                }
                else{
                    paymentCard.credit_card_expiration_date = newString;
                }

            }
            else{
                if (paymentCard.credit_card_expiration_date.length < 5 && !isNaN(parseInt(lastChar))) {
                    if (paymentCard.credit_card_expiration_date.length === 0 && ['0','1'].indexOf(lastChar) >= 0) {
                        paymentCard.credit_card_expiration_date += lastChar;
                    }
                    else if (paymentCard.credit_card_expiration_date.length === 1 &&  paymentCard.credit_card_expiration_date.split('')[0] === '1' && ['0', '1', '2'].indexOf(lastChar) >= 0) {
                        paymentCard.credit_card_expiration_date += lastChar + '/';
                    }
                    else if (paymentCard.credit_card_expiration_date.length === 1 && paymentCard.credit_card_expiration_date.split('')[0] === '0' && Array.from(Array(10).keys()).indexOf(parseInt(lastChar)) >= 0) {
                        paymentCard.credit_card_expiration_date += lastChar + '/';
                    }
                    else if (paymentCard.credit_card_expiration_date.length === 2  && ['0','1', '2', '3'].indexOf(lastChar) >= 0 ) {
                        paymentCard.credit_card_expiration_date +=  '/' + lastChar;
                    }
                    else if (paymentCard.credit_card_expiration_date.length === 3  && ['0','1', '2', '3'].indexOf(lastChar) >= 0 ) {
                        paymentCard.credit_card_expiration_date += lastChar;
                    }
                    else if (paymentCard.credit_card_expiration_date.length === 4 && Array.from(Array(10).keys()).indexOf(parseInt(lastChar)) >= 0 ) {
                        paymentCard.credit_card_expiration_date += lastChar;
                    }
                }
            }
        }

        this.setState({
            paymentCard
        })
    }

    onChange = (e) => {
        var paymentCard = { ...this.state.paymentCard };
        paymentCard[e.target.name] = e.target.value;
        this.setState({
            paymentCard
        })
    }

    changeSaveCard = (e) => {
        var paymentCard = { ...this.state.paymentCard };
        paymentCard[e.target.name] = e.target.checked;
        this.setState({
            paymentCard
        })
    }

    onChangeCompany = (e) => {
        var paymentCard = { ...this.state.paymentCard };
        if (!e.target.checked) {
            paymentCard['company'] = '';
            paymentCard['tax_number'] = '';
        }
        this.setState({
            isCompany: e.target.checked,
            paymentCard: paymentCard
        })
    }


    pay = () => {
        let paymentCard = {...this.state.paymentCard};
        const { dispatch } = this.props;
        const { auth } = this.props;
        this.setState({
            loadingPayment: true
        })
        paymentCard['booking_id'] = this.props.bookingId;
        paymentCard['hash'] = this.props.hash;
        if (paymentCard.credit_card_id === '0') {
            paymentCard['credit_card_expiration_month'] = parseInt(paymentCard.credit_card_expiration_date.split('/')[0]);
            paymentCard['credit_card_expiration_year'] = '20' + parseInt(paymentCard.credit_card_expiration_date.split('/')[1]);
        }
        else {
            delete paymentCard.credit_card_expiration_month;
            delete paymentCard.credit_card_expiration_year;
            delete paymentCard.credit_card_cvv;
        }

        paymentCard['user_id'] = this.props.userId;

        apiRequest('anonymous/pay', 'POST', paymentCard).then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    if (data.show_popup) {
                        this.setState({
                            open3dsecureDialog: true,
                            linkFor3Dsecure: data.redirect_url,
                        })
                    }
                    else{
                        if (data.data !== null) {
                            if (data.data.create_card_message != null) {
                                this.setState({
                                    error: data.data.create_card_message,
                                    loadingPayment: false
                                })
                            }
                            else if (data.data.payment_status_fees === false && data.data.payment_status_guest === false) {
                                this.setState({
                                    error: data.data.payment_message_guest,
                                    loadingPayment: false
                                })
                            }
                            else if (data.data.payment_status_fees === false && data.data.payment_status_guest === true) {
                                this.setState({
                                    error: data.data.payment_message_fees,
                                    loadingPayment: false
                                })
                            }
                            else if (data.data.create_card_message == null) {
                                let auth = { loading: false, api_key: data.access_token, loggedIn: false, user: {user: data.user} }; //todo make it better
                                window.localStorage.setItem('first_booking', data.show_dietary_popup + 0);
                                dispatch(userActions.updateUserInfo(auth));
                                window.location.href = '/booking-list';
                            }
                        }
                    }
                })
            }
            else{
                if (response.status === 401) {
                    // this.setState({error: 'Incorrect email or password'})
                }
                else if (response.status === 422) {
                    response.json().then(data => {
                        if (data.data !== undefined) {
                            if (data.data.create_card_message != null) {
                                this.setState({
                                    error: data.data.create_card_message,
                                    loadingPayment: false
                                })
                            }
                            else if (data.data.payment_status_fees === false && data.data.payment_status_guest === false) {
                                this.setState({
                                    error: data.data.payment_message_guest,
                                    loadingPayment: false
                                })
                            }
                            else if (data.data.payment_status_fees === false && data.data.payment_status_guest === true) {
                                this.setState({
                                    error: data.data.payment_message_fees,
                                    loadingPayment: false
                                })
                            }
                            else if (typeof data.messages != "undefined") {
                                this.setState({
                                    error: data.messages.join("\n"),
                                    loadingPayment: false
                                })
                            }
                            else if(typeof data.message != "undefined"){
                                this.setState({
                                    error: data.message,
                                    loadingPayment: false
                                })
                            }
                            else if (data.data.payment_message_guest) {
                                this.setState({
                                    error: data.data.payment_message_guest,
                                    loadingPayment: false
                                })
                            }
                        }
                    })
                }
            }
        })
    }

    validatePay = () => {
        const { paymentCard, terms, addNewCard, loadingPayment } = this.state;
        if (addNewCard) {
            return !(terms && paymentCard.billing_address_id) || loadingPayment ;
        }
        else{
            return !(terms && paymentCard.credit_card_cvv && paymentCard.credit_card_number && paymentCard.credit_card_name && paymentCard.credit_card_expiration_date ) || loadingPayment;
        }
    }

    changeTerms = (e) => {
        this.setState({
            terms: e.target.checked
        })
    }

    handleClose = () => {
        this.setState({
            success: '',
            error: '',
        })
    }
    selectCard = (e) => {
        var paymentCard = { ...this.state.paymentCard };
        paymentCard['credit_card_id'] = e;
        this.setState({
            addNewCard: true,
            paymentCard
        })
    }

    handleTooltipClose = () => {
        this.setState({open:false})
    }

    showTooltip = () => {
        this.setState({open:true})
    }

    close3dsecureDialog = () => {
        window.localStorage.removeItem('dataWasSendedToFrame');
        this.setState({
            open3dsecureDialog: false,
            iframeLoaded: false,
            linkFor3Dsecure: ''
        })
    }

    closeTermsAndConditions = () => {
        this.setState({
            openTermsAndConditions: false
        })
    }

    openTermAndConditions = () => {
        this.setState({
            openTermsAndConditions: true,
            terms: !this.state.terms,
        })
    }

    render() {
        const { cards, classes, countries } = this.props;
        const { addNewCard, openTermsAndConditions, paymentCard, terms, addresses, error, success, open, isCompany, loadingPayment, open3dsecureDialog, linkFor3Dsecure, iframeLoaded } = this.state;

        var selectedCard = cards[cards.findIndex(card => {
            return card.card_id == paymentCard.credit_card_id;
        })];
        return(<>
            {!addNewCard && <div className="cards-list">
                {!!cards.length && <p className="subtitle">Registered Cards</p>}
                {!!cards.length && <>
                    {cards.map(card => {
                        return (
                            <div className="address">
                                <div className="left">
                                    <div className="title">{card.name}</div>
                                    <div className="subtitle">
                                        <div>
                                            {(card.type === 'MasterCard') && <img src={Mc} />}
                                            {(card.type === 'Visa') && <img src={Visa} />}
                                            {(card.type !== 'Visa' && card.type !== 'MasterCard') && <img src={Card} />}
                                            <span>XXXX XXX XXX {card.last4}</span>
                                        </div>
                                         <span className="date">{card.exp_month}/{card.exp_year}</span></div>
                                </div>
                                <div className="right">
                                    <ArrowForwardIosIcon onClick={() => this.selectCard(card.card_id)} fontSize="large" />
                                </div>
                            </div>
                        )
                    })}
                </>}


                {!!cards.length && <p className="subtitle">Or use a new card</p>}
                {!cards.length && <p className="subtitle">Add new card</p>}
                <div className="cards" onClick={this.addNewCard} >
                    <img src={Visa} />
                    <img src={Mc} />
                    <img src={Ae} />
                </div>
            </div>}

            {!!addNewCard && <>
                {paymentCard.credit_card_id === '0' &&
                <Grid className="fullscreen" justify="center" container alignItems="center" spacing={0}>
                    <Grid item xs={12} spacing={0}>
                        <Grid justify="center" container alignItems="center" spacing={1}>
                            <Grid item xs={12} spacing={1}>
                                <TextField
                                    name="credit_card_number"
                                    id="credit_card_number"
                                    placeholder="Card number"
                                    onChange={this.onChange}
                                    value={paymentCard.credit_card_number}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} spacing={1}>
                                <TextField
                                    name="credit_card_name"
                                    id="credit_card_name"
                                    placeholder="Name"
                                    onChange={this.onChange}
                                    value={paymentCard.credit_card_name}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6} spacing={1}>
                                <TextField
                                    name="credit_card_expiration_date"
                                    id="credit_card_expiration_date"
                                    placeholder="Expiration date"
                                    onChange={this.onChangeDate}
                                    value={paymentCard.credit_card_expiration_date}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6} spacing={1}>
                                <OutlinedInput
                                    name="credit_card_cvv"
                                    id="credit_card_cvv"
                                    placeholder="CVV code"
                                    onChange={this.onChange}
                                    value={paymentCard.credit_card_cvv}
                                    variant="outlined"
                                    endAdornment={
                                        <ClickAwayListener onClickAway={this.handleTooltipClose}>
                                            <div>
                                                <Tooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={this.handleTooltipClose}
                                                    open={open}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    arrow
                                                    title={
                                                        <span className="child-by-center">
                                                            <img src={Csv}/>
                                                            <span>You can find your code at the back of your credit card above your signature, or on the front above your account number</span>
                                                        </span>
                                                    }
                                                    placement="top-start"
                                                >
                                                    <InfoIcon onClick={this.showTooltip} />
                                                </Tooltip>
                                            </div>
                                        </ClickAwayListener>


                                    }
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={6} spacing={1}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={paymentCard.save_my_card}
                                            onChange={this.changeSaveCard}
                                            name="save_my_card"
                                        />
                                    }
                                    label="Save my card"
                                />
                            </Grid>
                            <Grid item xs={6} spacing={1}>
                                <TextField
                                    name="credit_card_nickname"
                                    id="credit_card_nickname"
                                    placeholder="Card nickname"
                                    onChange={this.onChange}
                                    value={paymentCard.credit_card_nickname}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
                {paymentCard.credit_card_id !== '0' && <>
                        <div className="cards-list">
                            <div className="address">
                                <div className="left">
                                    <div className="title">{selectedCard.name}</div>
                                    <div className="subtitle">
                                        <div>
                                            {(selectedCard.name === 'MasterCard') && <img src={Mc} />}
                                            {(selectedCard.name === 'Visa') && <img src={Visa} />}
                                            {(selectedCard.name !== 'Visa' && selectedCard.name !== 'MasterCard') && <img src={Card} />}
                                            <span>XXXX XXX XXX {selectedCard.last4}</span>
                                        </div>
                                        <span className="date">{selectedCard.exp_month}/{selectedCard.exp_year}</span></div>
                                </div>
                            </div>
                        </div>
                </>}
                <p className="subtitle">Choose a billing address</p>
                <FormControl variant="outlined" style={{width: '100%'}}>
                    <NativeSelect
                        id="billing_address_id"
                        value={paymentCard.billing_address_id}
                        onChange={this.onChange}
                        name="billing_address_id"
                        input={<OutlinedInput />}
                        variant="outlined"
                        style={{width: '100%'}}
                    >
                        <option value="">Choose a biling address</option>
                        {addresses.map(address => {
                          return <option value={address.id}>{address.firstname + " " + address.lastname + " " + (address.company ? address.company + ' ': '') + "- " + address.street_1 + ' ' + address.zipcode + " " + address.city + ' - ' + address.country_name}</option>
                        })}
                        <option value="0">Create a new address</option>
                    </NativeSelect>
                </FormControl>
                <br/><br/>
                {(paymentCard.billing_address_id === '0') && <>
                    <br/>
                    <label htmlFor="firstname">Firstname</label>
                    <TextField
                        className="mt-8 mb-16"
                        id="firstname"
                        name="firstname"
                        onChange={this.onChange}
                        value={paymentCard.firstname}
                        type="text"
                        variant="outlined"
                        fullWidth
                    />
                    <br/><br/>
                    <label htmlFor="lastname">Lastname</label>
                    <TextField
                        className="mt-8 mb-16"
                        id="lastname"
                        name="lastname"
                        onChange={this.onChange}
                        value={paymentCard.lastname}
                        type="text"
                        variant="outlined"
                        fullWidth
                    />
                    <br/><br/>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isCompany}
                                onChange={this.onChangeCompany}
                                name="is_company"
                            />
                        }
                        label="It’s a company address ?"
                    />
                    <br/><br/>

                    {isCompany && <>
                        <label htmlFor="company">Company</label>
                        <TextField
                            className="mt-8 mb-16"
                            id="company"
                            name="company"
                            onChange={this.onChange}
                            value={paymentCard.company}
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                        <br/><br/>
                        <label htmlFor="tax_number">Tax number</label>
                        <TextField
                            className="mt-8 mb-16"
                            id="tax_number"
                            name="tax_number"
                            onChange={this.onChange}
                            value={paymentCard.tax_number}
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                        <br/><br/>
                    </>}
                    <label htmlFor="street_1">Street line 1</label>
                    <TextField
                        className="mt-8 mb-16"
                        id="street_1"
                        name="street_1"
                        onChange={this.onChange}
                        value={paymentCard.street_1}
                        type="text"
                        variant="outlined"
                        fullWidth
                    />
                    <br/><br/>
                    <label htmlFor="zipcode">Zipcode</label>
                    <TextField
                        className="mt-8 mb-16"
                        id="zipcode"
                        name="zipcode"
                        onChange={this.onChange}
                        value={paymentCard.zipcode}
                        type="text"
                        variant="outlined"
                        fullWidth
                    />
                    <br/><br/>
                    <label htmlFor="city">City</label>
                    <TextField
                        className="mt-8 mb-16"
                        id="city"
                        name="city"
                        onChange={this.onChange}
                        value={paymentCard.city}
                        type="text"
                        variant="outlined"
                        fullWidth
                    />
                    <br/><br/>
                    <label htmlFor="country_id">Country</label>
                    <FormControl variant="outlined" style={{width: '100%', marginBottom: 15}}>
                        <Select
                            labelId="country_id"
                            id="country_id"
                            error={paymentCard.country_id === ''}
                            value={paymentCard.country_id}
                            required
                            name="country_id"
                            onChange={this.onChange}
                            fullWidth
                        >
                            {countries.map(country => {
                                return (
                                    <MenuItem fullWidth value={country.id}>{country.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <br/><br/>
                </>}
                <FormControlLabel
                    // style={{fontSize:12}}
                    control={
                        <Checkbox
                            checked={terms}
                            onChange={this.changeTerms}
                            name="accept_terms"
                        />
                    }
                    label={<span style={{fontSize: 12}}>I accept the <a style={{textDecoration: 'underline'}} onClick={this.openTermAndConditions}>booking conditions</a> of the partner and of application</span>}
                />
                <br/><br/>
                <div className="child-by-center">
                    <Button onClick={this.pay} disabled={this.validatePay()} className="blackButton">
                        {!loadingPayment && <span>
                            {this.props.booking_is_ready === true && <>Pay {this.props.total}</>}
                            {this.props.booking_is_ready === false && <>Pay {this.props.fees} {this.props.currency} (fees) </>}
                            </span>
                        }
                        {!!loadingPayment && <CircularProgress size={29} style={{color:'white'}} />}
                    </Button>
                </div>
            </>}
            <TermsAndConditions closeTermsAndConditions={this.closeTermsAndConditions} open={openTermsAndConditions} />
            <Portal>
                <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </Portal>

            {linkFor3Dsecure && <>
                <Dialog TransitionComponent={Transition} keepMounted fullScreen open={open3dsecureDialog} onClose={this.close3dsecureDialog}>
                    <div className="searchHeader">
                        <span style={{position: 'absolute',left: 0, right: 0, textAlign: 'center', width: '80%',margin: 'auto'}}>3D secure</span>
                        <CloseIcon onClick={this.close3dsecureDialog} />
                    </div>
                    {!iframeLoaded && <div className="child-by-center"><CircularProgress size={29} /></div>}
                    <iframe name="iframe_3d" src={linkFor3Dsecure} style={{width: '100%', height: '100%'}} frameborder="0">
                    </iframe>
                </Dialog>
            </>}

        </>)
    }
}

function mapStateToProps(state) {
    return {
        user: getUser(state),
    };
}


export default connect(mapStateToProps)(InvitedPayment);