
import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { apiRequest } from '@services/Request';
import LocationIcon from '@images/homepage_select_city_icon.svg';
import Arrow from '@images/back_arrow_black.svg';
import InputAdornment from '@material-ui/core/InputAdornment';
class SelectCity extends React.Component{
    state = {
        originalCities: [],
        selectCityDialogIsOpen: false,
        cities: [],
        filterByName: ''
    }


    handleCloseCityDialog = () => {
        this.setState({
            selectCityDialogIsOpen: false,
            filterByName: ''
        })

        this.filterSearchByName('');
    }

    selectCityForm = (e, cityId) => {
        window.localStorage.setItem('city_id', cityId);
        this.props.updateHomepage(cityId, this.props.wasSearch);
        this.handleCloseCityDialog();
    }

    filterSearchByName = (value) => {
        const { originalCities } = this.state;
        const filterByName = (typeof value != "undefined") ? value : this.state.filterByName;
        let filtered = [];
        if (filterByName) {
            filtered = originalCities.map(item => {
                if (item.name.toLocaleLowerCase().indexOf(filterByName.toLocaleLowerCase()) + 1) {
                    return item
                }
                else{
                    return false;
                }
            }).filter(Boolean);

            this.setState({
                cities: filtered
            })
        }
        else{
            this.setState({
                cities: [...originalCities]
            })
        }
    }

    componentDidMount() {
        const { auth } = this.props;
        
        this.setState({
            originalCities: this.props.cities,
            cities: this.props.cities
        })
    }

    handleFilterByName = (e) => {
        this.setState({
            filterByName: e.target.value
        });
        this.filterSearchByName(e.target.value)
    }

    openCityDialog = () => {
        this.setState({
            selectCityDialogIsOpen: true
        })
    }

    render() {
        const { cities, filterByName, selectCityDialogIsOpen, loading, originalCities } = this.state;
        const { selected_city } = this.props;
        return (
            <>
                {!!cities.length && (cities.length === originalCities.length)  &&
                <div className="customSelectCity" style={{width: '100%', maxWidth: '450px'}} onClick={this.openCityDialog}>
                    <span>
                        <img className="start-icon" src={LocationIcon} />
                        {cities.find(city => {return city.id == selected_city.id}).name}
                    </span>
                    <img src={Arrow} />
                </div>}
                <Dialog fullScreen open={selectCityDialogIsOpen} onClose={this.handleCloseCityDialog}>
                    <div className="searchHeader">
                        <div> </div>
                        <Typography style={{marginLeft: 30, color: '#091026'}} variant="h6">
                            Localization
                        </Typography>
                        <IconButton edge="start" color="inherit" onClick={this.handleCloseCityDialog} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div style={{padding:15}}>
                        <FormControl style={{width:'100%'}}>
                            <TextField
                                style={{width:'100%'}}
                                placeholder="Search for a city in the world"
                                value={filterByName}
                                onChange={this.handleFilterByName}
                                variant="outlined"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <img src={LocationIcon}/>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>

                    {!!cities.length && <List>
                        <ListItem>
                            <ListItemText primary={<span style={{fontWeight: 600, color: '#091026'}}>City Suggestions</span>} />
                        </ListItem>
                        {cities.map(city => {
                            return (
                                <ListItem button onClick={(e) => this.selectCityForm(e, city.id)}>
                                    <ListItemText style={{color: '#797979'}} primary={city.name} />
                                </ListItem>
                            )
                        })}
                    </List>}
                    {!cities.length && <p style={{textAlign: 'center'}}>No results</p>}
                </Dialog>
            </>
        );
    }
}

export default SelectCity