import React from 'react';
import {apiRequest} from "@services/Request";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
    withRouter,
} from "react-router-dom";
import './style.css';
import TextsmsIcon from '@material-ui/icons/Textsms';
import Bell from '@images/bell.svg';
import Chat from '@images/chat.svg';
import BlackArrow from '@images/back_arrow_black.svg';
import Button from '@material-ui/core/Button';


const statusLabels = {
    10: 'Pending',
    20: 'Rejected',
    30: 'Approved',
    40: 'Canceled',
}
class BookingList extends React.Component{
    state = {
        opendBooking: false,
        loading:true,
    }


    rejectSuggestion = (bookingId) => {
        const { api_key } = this.props.auth;
        this.setState({
            error: '',
            success: ''
        })
        apiRequest('booking/reject-suggested-time', 'POST', {id: bookingId}, api_key).then(response => {
            if (response.status === 200) {
                this.props.getBookingHistory();
                this.setState({
                    success: 'You rejected the suggestion',
                })
            }
            else{
                this.setState({
                    error: 'Something went wrong, please try later'
                })
            }
        })
    }

    acceptSuggestion = (bookingId) => {
        const { api_key } = this.props.auth;
        this.setState({
            error: '',
            success: ''
        })
        apiRequest('booking/accept-suggested-time', 'POST', {id: bookingId}, api_key).then(response => {
            if (response.status === 200) {
                this.props.getBookingHistory();
                this.setState({
                    success: 'You accepted the suggestion',
                })
            }
            else{
                this.setState({
                    error: 'Something went wrong, please try later'
                })
            }
        })
    }



    componentDidMount() {
        const { auth, kitty, dispatch, chatSession } = this.props;

    }

    render() {
        const { opendBooking } = this.state;
        const { chatsChannels, loading, future, previous } = this.props;
        return (
            <>
                <div style={{padding: 15}}>
                    {!future.length && !loading && <p>No bookings</p>}
                    {!!future.length && <>
                        {future.map((booking, i) => {
                            return (
                                <div key={i} className="address">
                                    <div className="left">
                                        <div className="title">{booking.supplier.name} - {booking.date.replace(/-/g,"/")}</div>
                                        <div className="wrapper-booking-content-on-list">
                                            {(chatsChannels[booking.hash] !== undefined && booking.hash && chatsChannels[booking.hash].countUnreadMesages !== undefined && chatsChannels[booking.hash].countUnreadMesages > 0) && <>
                                                <div className="chat-btn" onClick={(e) => this.props.openChat(booking)} >
                                                    <img src={Chat} alt=""/>
                                                    <span>
                                                        {chatsChannels[booking.hash].countUnreadMesages === 1 && <>1 Message</>}
                                                        {chatsChannels[booking.hash].countUnreadMesages > 1 && <>{chatsChannels[booking.hash].countUnreadMesages} Message</>}
                                                    </span>
                                                </div>
                                            </>}
                                            {booking.status != 40 && booking.canFeedback && <>
                                                <div className={'booking-status status' + booking.status}>Leave a Feedback</div>
                                            </>}
                                            {booking.status == 15 && <>
                                                <div className={'booking-status status40'}>Waiting approval</div>
                                            </>}
                                            {booking.status == 20 && <>
                                                <div className={'booking-status status40'}>Rejected</div>
                                            </>}
                                            {booking.status == 25  && booking.canShowConfirmButton === true && <>
                                                <div className={(booking.bookingIsReady === true) ? 'booking-status status25 active' : 'booking-status status25'}>To be confirmed by you 5 days before</div>
                                            </>}
                                            {booking.status == 50 && <>
                                                <div className={'booking-status status40'}>No show</div>
                                            </>}
                                            {booking.status == 40 && <>
                                                <div className={'booking-status status' + booking.status}>Cancelled</div>
                                            </>}
                                            {booking.status === 35 && <div>
                                                <div className="suggestion">
                                                    <div className="bell">
                                                        <img src={Bell} alt=""/>
                                                    </div>
                                                    <div className="old-time">
                                                        <div>Asked for {booking.old_hour}</div>
                                                        <div>{booking.oldOptionName}</div>
                                                        <div>Price/guest</div>
                                                        <Button onClick={(e) => this.rejectSuggestion(booking.id)} className="small reject blackButton">Reject</Button>
                                                    </div>
                                                    <div className="arrow">
                                                        <img src={BlackArrow} alt=""/>
                                                    </div>
                                                    <div className="new-time">
                                                        <div>New offer {booking.hour}</div>
                                                        <div>{booking.supplier_option.option.name}</div>
                                                        <div>{booking.price_per_guest} {booking.currency}</div>
                                                        <Button onClick={(e) => this.acceptSuggestion(booking.id)} className="small blackButton">Accept</Button>
                                                    </div>
                                                </div>
                                                <div className="suggestion-info">
                                                    You have <span>{this.props.x_minutes_to_approve}:00 min</span> to accept
                                                    then your booking will be cancelled
                                                </div>
                                            </div>}
                                        </div>
                                    </div>

                                    <div className="right">
                                        <ArrowForwardIosIcon onClick={() => this.props.history.push('/booking-list/' + booking.id)} fontSize="large" />
                                    </div>
                                </div>
                            )
                        })}
                    </>}

                    <div className="child-by-center" style={{marginTop: 15, marginBottom:15, position: 'relative',height: 50, fontSize:18, fontWeight: 600}}>
                        Past
                    </div>

                    {!previous.length && !loading && <p>No booking history</p>}
                    {!!previous.length && <>
                        {previous.map((booking, i) => {
                            return (
                                <div key={i} className="address">
                                    <div className="left">
                                        <div className="title">{booking.supplier.name} - {booking.date.replace(/-/g,"/")}</div>
                                        <div className="wrapper-booking-content-on-list">
                                            {(chatsChannels[booking.hash] !== undefined && booking.hash && chatsChannels[booking.hash].countUnreadMesages !== undefined && chatsChannels[booking.hash].countUnreadMesages > 0) && <>
                                                <div className="chat-btn" onClick={(e) => this.props.openChat(booking)} >
                                                    <img src={Chat} alt=""/>
                                                    <span>
                                                      {chatsChannels[booking.hash].countUnreadMesages === 1 && <>1 Message</>}
                                                        {chatsChannels[booking.hash].countUnreadMesages > 1 && <>{chatsChannels[booking.hash].countUnreadMesages} Message</>}
                                                </span>

                                                </div>
                                            </>}
                                            {booking.status != 40 && booking.canFeedback && <>
                                                <div className={'booking-status status' + booking.status}>Leave a Feedback</div>
                                            </>}
                                            {booking.status == 15 && <>
                                                <div className={'booking-status status40'}>Waiting approval</div>
                                            </>}
                                            {booking.status == 20 && <>
                                                <div className={'booking-status status40'}>Rejected</div>
                                            </>}
                                            {booking.status == 50 && <>
                                                <div className={'booking-status status40'}>No show</div>
                                            </>}
                                            {booking.status == 40 && <>
                                                <div className={'booking-status status' + booking.status}>Cancelled</div>
                                            </>}
                                        </div>
                                    </div>
                                    <div className="right">
                                        <ArrowForwardIosIcon onClick={() => this.props.history.push('/booking-list/' + booking.id)} fontSize="large" />
                                    </div>
                                </div>
                            )
                        })}
                    </>}
                </div>
            </>
        )
    }
}

export default withRouter(BookingList);