import React from 'react';
import Button from '@material-ui/core/Button';
import Loader from '@semper/Loader';
import { apiRequest } from '@services/Request';

class ConfirmEntrance extends React.Component{

    redirectToHomepage = () => {
        window.location.href = '/' //todo fix it
    }

    componentDidMount() {
        const { auth } = this.props;
    }

    constructor(props){
        super(props);
        this.redirectToHomepage();

    }

    render() {
        const { referral_was_accepted, discount_entrance_fees } = this.props;

        if (
            true
        ) {
            return <Loader color={'#F7F7F7'} />
        }
        return(
            <div className="confirm-step">
                <div className="text-content">
                    <p><b>We have well received your request. <br/> Your account is being checked</b></p>
                    <br/><br/><br/>
                    <p className="thin-text">Please wait, <br/> You will be informed when your account will be confirmed.</p>
                </div>
            </div>
        )
    }
}


export default ConfirmEntrance;